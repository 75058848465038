import { Directive, Input, OnInit, ViewContainerRef, TemplateRef, ElementRef } from '@angular/core';

/**
 * 单元格渲染器
 */

@Directive({
  selector: '[x-render]'
})
export class XRenderDirective implements OnInit {
  @Input('x-render') XRrender = null;
  constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>, private el: ElementRef) { }

  ngOnInit() {
    const { row, column, render, rowIterator, columnIterator } = this.XRrender;
    switch (true) {
      case typeof render == 'string': {
        const embeddedViewRef = this.viewContainer.createEmbeddedView(this.templateRef);
        if (embeddedViewRef.rootNodes[0].parentNode) {
          embeddedViewRef.rootNodes[0].parentNode.innerHTML = row ? (row[render] || '') : render;
        }
        break;
      }
      case typeof render == 'function': {
        const embeddedViewRef = this.viewContainer.createEmbeddedView(this.templateRef);
        if (embeddedViewRef.rootNodes[0].parentNode) {
          embeddedViewRef.rootNodes[0].parentNode.innerHTML = render(row, column, rowIterator, columnIterator);
        }
        break;
      }
      case render instanceof TemplateRef: {
        this.viewContainer.createEmbeddedView(render, { $implicit: row, ...this.XRrender });
        break;
      }
    }
  }

}
