import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from '@delon/theme';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';

import { _HttpClient } from '@delon/theme';
import { CommonService, baseUrl } from '@core';

@Component({
  selector: 'header-user',
  template: `
    <div
      class="alain-default__nav-item d-flex align-items-center px-sm"
      nz-dropdown
      nzPlacement="bottomLeft"
      [nzDropdownMenu]="userMenu"
      nzTrigger="click"
      [nzOverlayStyle]="{ width: '120px' }"
      nzOverlayClassName="header-user"
    >
      <nz-avatar nzIcon="user" nzSize="small" class="mr-sm"></nz-avatar>
      {{ settings.user.name }}
    </div>
    <nz-dropdown-menu #userMenu="nzDropdownMenu">
      <div nz-menu class="width-sm">
        <!--
        <div nz-menu-item routerLink="/pro/account/center">
          <i nz-icon nzType="user" class="mr-sm"></i>
          {{ 'menu.account.center' | translate }}
        </div>
        <div nz-menu-item routerLink="/pro/account/settings">
          <i nz-icon nzType="setting" class="mr-sm"></i>
          {{ 'menu.account.settings' | translate }}
        </div>
        <div nz-menu-item routerLink="/exception/trigger">
          <i nz-icon nzType="close-circle" class="mr-sm"></i>
          {{ 'menu.account.trigger' | translate }}
        </div>
        <li nz-menu-divider></li>
        -->
        <div nz-menu-item routerLink="/personinfo"><i nz-icon nzType="form" nzTheme="outline" class="mr-sm"></i>
        修改密码
        </div>
        <div nz-menu-item (click)="logout()">
          <i nz-icon nzType="logout" class="mr-sm"></i>
          {{ 'menu.account.logout' | translate }}
        </div>
      </div>
    </nz-dropdown-menu>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [`
    :host {
      display: block;
      min-width: 120px;
    }
  `]
})
export class HeaderUserComponent {
  constructor(
    public settings: SettingsService,
    private router: Router,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private http: _HttpClient
  ) {
    let UserInfo = JSON.parse(window.localStorage.getItem('UserInfo') || '{}');
    this.settings.setUser({
      "name": UserInfo.realName || '用户名称',
      "avatar": "./assets/tmp/img/avatar.jpg",
      "email": "cipchk@qq.com"
    })
  }

  logout() {
    let UserInfo = JSON.parse(window.localStorage.getItem('UserInfo') || '{}');
    this.http.post(`${baseUrl}/users/loginOut`, { id: UserInfo.user_id }).subscribe(res => {
      if (res.code == 0) {
        this.tokenService.clear();
        this.router.navigateByUrl(this.tokenService.login_url!);
      }
    });
  }
}
