import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';

import { rows } from '../rows';

@Component({
  selector: 'app-test1',
  templateUrl: './test1.component.html',
  styleUrls: ['./test1.component.scss']
})
export class Test1Component implements OnInit {
  // tableConfig = {
  //   rows: rows,
  //   columns: [{
  //     title: 'id',
  //     field: 'id'
  //   }, {
  //     title: 'create_date',
  //     field: 'create_date'
  //   }, {
  //     title: 'create_time',
  //     field: 'create_time'
  //   }, {
  //     title: 'customer_name',
  //     field: 'customer_name'
  //   }, {
  //     title: 'customer_phone',
  //     field: 'customer_phone'
  //   }, {
  //     title: 'content',
  //     field: 'content'
  //   }, {
  //     title: 'result',
  //     field: 'result'
  //   }, {
  //     title: 'author',
  //     field: 'author'
  //   }, {
  //     title: 'ext1',
  //     field: this.ext1Temp
  //   }, {
  //     title: 'ext2',
  //     field: (row) => {
  //       return row;
  //     }
  //   }]
  // };

  constructor() {

  }
  @ViewChild('ext1Temp', { static: true }) ext1Temp: TemplateRef<any>;
  tableConfig: any = {};

  b = 89;


  tbodyHeight = null;

  ngOnInit() {
    this.tableConfig = {
      rows,
      // rows: [],
      // columns: {} && [
      //   [
      //   // {
      //   //   title: 'id',
      //   //   width: 100,
      //   // },
      //   // {
      //   //   title: 'create_date',
      //   // }, {
      //   //   title: 'create_time',
      //   // },
      //   {
      //     title: 'id & 日期',
      //     thAttr: {
      //       'colspan': 3
      //     }
      //   }, {
      //     title: 'customer_name',
      //   }, {
      //     title: 'customer_phone',
      //   }, {
      //     title: 'content',
      //   }, {
      //     title: 'result',
      //   }, {
      //     title: 'author',
      //   }, {
      //     title: 'ext1',
      //     width: 300
      //   }, {
      //     title: (row, column) => '<span style="color: red;">ext2</span>',
      //   }], 
      //   [{
      //     title: 'id',
      //     field: 'id',
      //     width: 100, //最后一行有效
      //     tdClass: {
      //       'c1': true,
      //       'c2': true
      //     },
      //     tdStyle: {
      //       'font-size': '16px',
      //       'color': 'red'
      //     },
      //     thClass: () => ({ 'c1': true, 'c2': true }),
      //     thStyle: {
      //       'font-size': '16px',
      //       'color': 'red'
      //     },
      //     thAttr: {
      //       'a': 1,
      //     },
      //     tdAttr: {
      //       'b': 2
      //     },
      //     // sort: 'asc'
      //   }, {
      //     title: '日期',
      //     // field: 'create_date',
      //     field: (rowData) => (rowData.id == 441 || rowData.id == 440) ? (rowData.create_date + ' ' + rowData.create_time) : (rowData.create_date),
      //     sort: null,
      //     tdAttr: (rowData) => ({ 'colspan': (rowData.id == 441 || rowData.id == 440) ? 2 : 1 })
      //   }, {
      //     title: 'create_time',
      //     field: 'create_time',
      //     tdNone: (rowData)=> rowData.id == 441 || rowData.id == 440
      //   }, {
      //     title: '客户姓名客户姓名',
      //     field: 'customer_name',
      //     // sort: 'desc'
      //   }, {
      //     title: 'customer_phone',
      //     field: 'customer_phone'
      //   }, {
      //     title: 'content',
      //     field: 'content'
      //   }, {
      //     title: 'result',
      //     field: 'result'
      //   }, {
      //     title: 'author',
      //     field: 'author'
      //   }, {
      //     title: 'ext1',
      //     field: this.ext1Temp,
      //     width: 300
      //   }, {
      //     title: (row, column) => '<span style="color: red;">ext2</span>',
      //     field: (row) => {
      //       // console.log(row);
      //       // return JSON.stringify(row);
      //       return '<span style="color: red;">' + row.customer_name + '</span>'
      //     }
      //   }]]

      // columns: [
      //   [
      //     { title: '列1', field: 'id', rowspan: '3' },
      //     { title: '类别1', colspan: '6' },
      //     { title: '类别2', colspan: '3' },
      //   ],
      //   [
      //     { title: '子类别1', colspan: '3' },
      //     { title: '列5', field: 'id', rowspan: '2' },
      //     { title: '列6', field: 'id', rowspan: '2' },
      //     { title: '列7', field: 'id', rowspan: '2' },
      //     { title: '列8', field: 'id', rowspan: '2' },
      //     { title: '列9', field: 'id', rowspan: '2' },
      //     { title: '列10', field: 'id', rowspan: '2' },
      //   ],
      //   [
      //     { title: '列2', field: 'id' },
      //     { title: '列3', field: 'id' },
      //     { title: '列4', field: 'id' },
      //   ]
      // ]

      // defaultColumn: {
      //   sort: null
      // },

      columns: [
        [
          { title: 'id', field: 'id', width: 100, fixed: 'left', thAttr: { rowspan: '3' }, tdAttr: (rowData) => ({ rowspan: rowData.id == 439 ? 3 : 1 }) },
          { title: '类别1', thAttr: { colspan: '6' } },
          { title: '类别2', thAttr: { colspan: '3' } },
        ],
        [
          { title: '子类别1', field: 'id', thAttr: { colspan: '3' } },
          { title: 'create_date', field: 'create_date', thAttr: { rowspan: '2' } },
          { title: 'create_time', field: 'create_time', sort: 'desc', thAttr: { rowspan: '2' } },
          { title: 'author', field: 'author', thAttr: { rowspan: '2' } },
          { title: 'customer_name', field: 'customer_name', thAttr: { rowspan: '2' } },
          { title: 'customer_phone', field: 'customer_phone', thAttr: { rowspan: '2' } },
          { title: 'result', field: 'result', thAttr: { rowspan: '2' }, fixed: 'right' },
        ],
        [
          { title: 'content', field: 'content', sort: null, width: 300, tdAttr: (rowData) => ({ colspan: (rowData.id == 441 || rowData.id == 440) ? 2 : 1 }) },
          { title: '列3', field: 'id', sort: null, },
          { title: '列4', field: 'id', sort: null, tdAttr: (rowData) => ({ colspan: (rowData.id == 441 || rowData.id == 440) ? 2 : 1 }) },
        ]
      ]

      // columns: [
      //   [
      //     { title: 'id', field: 'id', width: 100, fixed: 'left', tdAttr: (rowData) => ({ rowspan: rowData.id == 439 ? 3 : 1 }) },
      //     { title: 'create_date', field: 'create_date', fixed: 'left', },
      //     { title: 'create_time', field: 'create_time', fixed: 'left', },
      //     { title: 'author', field: 'author', },
      //     { title: 'customer_name', field: 'customer_name', },
      //     { title: 'customer_phone', field: 'customer_phone', },
      //     { title: 'result', field: 'result', },
      //     { title: 'content', field: 'content', width: 300, tdAttr: (rowData) => ({ colspan: (rowData.id == 441 || rowData.id == 440) ? 2 : 1 }) },
      //     { title: '列3', field: 'id', fixed: 'right', },
      //     { title: '列4', field: 'id', fixed: 'right', },
      //   ]
      // ],

      // columns: [
      //   [
      //     { field: 'id', width: 100, fixed: 'left', tdAttr: (rowData) => ({ rowspan: rowData.id == 439 ? 3 : 1 }) },
      //     { field: 'create_date', fixed: 'left', },
      //     { field: 'create_time', fixed: 'left', },
      //     { field: 'author', },
      //     { field: 'customer_name', },
      //     { field: 'customer_phone', },
      //     { field: 'result', },
      //     { field: 'content', width: 300, tdAttr: (rowData) => ({ colspan: (rowData.id == 441 || rowData.id == 440) ? 2 : 1 }) },
      //     { field: 'id', fixed: 'right', },
      //     { field: 'id', fixed: 'right', },
      //   ]
      // ],


    };

    // 11

  }
  sendCallback = (params) => {
    params.a = 1;
    params.b = this.b;
    return params;
  }

  successCallback = (res) => {
    console.log(res);
    return {
      total: res.total,
      _rows: {
        rows: res.data.rows
      },
      a: 123
    };
  }

  dataPath = (res) => {
    return res._rows.rows;
  }

  test(row) {
    // this.tableConfig.rows = [];

    this.tableConfig.columns[0].splice(0, 1);
    this.tableConfig.columns[0].push({ title: '列5', field: 'id', fixed: 'right', });
    console.log(JSON.stringify(this.tableConfig.columns, null, 4));
    this.tableConfig.columns = [...this.tableConfig.columns];

    // this.tbodyHeight = 300;

    // this.tableConfig._columns[0].splice(0, 1);
    // console.log(JSON.stringify(this.tableConfig._columns, null, 4));
    // // this.tableConfig.columns = [...this.tableConfig.columns];

  }

  tableHeight() {
    return (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 250 + 'px';
  }

}
