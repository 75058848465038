import { Component, OnInit } from '@angular/core';
import { SettingsService, _HttpClient } from '@delon/theme';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http'

import { NzMessageService, NzModalService } from 'ng-zorro-antd';

import { environment, baseUrl as _baseUrl } from '@env/environment';
import { baseUrl } from '@core';
import { UniqueAlterEgoValidator } from '../../../shared/alter-ego.directive';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.less']
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup;
  getVerificationLoading = true;
  intervalCount = 0;

  resetForm: FormGroup;
  showResetForm = false;

  loading = false;
  userInfo = {
    mobile: null
  };
  isVisible = false;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public http: HttpClient,
    public msg: NzMessageService,
    private alterEgoValidator: UniqueAlterEgoValidator
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      userName: [null, [Validators.required]],
      // userName: new FormControl(null,
      //   {
      //     asyncValidators: [this.alterEgoValidator.validate.bind(this.alterEgoValidator)],
      //     updateOn: 'blur'
      //   }),
      verification: [null, Validators.required],
      token: [null]
    });

    this.resetForm = this.fb.group({
      password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      re_password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(16), ResetPasswordComponent.passwordEquar]],
    });
  }
  //用户名是否存在
  userExist(form) {
    let username = form.get('userName').value;
    // this.getUserName(username);
    this.http.get<any>(`${_baseUrl}/platform/v1/users/findMobileByUserName?userName=${username}`, { headers: { autoShowErrorMsg: '' } }).subscribe(res => {
      if (res.code == 0) {
        this.userInfo = res.data || {};
        this.getVerificationLoading = false;
      } else {
        form.controls.userName.setErrors({ confirm: true, error: true });
        this.getVerificationLoading = true;
      }
    })
  }
  //密码是否一致
  static passwordEquar(control: FormControl) {
    if (!control || !control.parent) {
      return null;
    }
    if (control.value !== control.parent.get('password')!.value) {
      return { equar: true };
    }
    return null;
  }
  // getUserName(value) {
  //   return this.http.get<any>(`${baseUrl}/platform/v1/users/findMobileByUserName?userName=${value}`, { headers: { autoShowErrorMsg: '' } }).toPromise().then((res: any) => {

  //     if (res.code == 1) {
  //       this.obj = { confirm: true, error: true };
  //     } else {
  //       this.obj = {};
  //     }
  //     console.log('1' + this.obj);
  //   });
  // }
  getVerification() {
    let userName = this.form.value.userName;
    if (!userName) {
      this.msg.info('请输入手机号!');
      return;
    }
    if (this.userInfo.mobile) {
      this.isVisible = true;
      this.getVerificationLoading = true;
      this.http.get<any>(`${_baseUrl}/cooperation/v1/message/sms?smsType=commonCode&mobile=${this.userInfo.mobile}`, { headers: { token: '' } }).subscribe(res => {
        if (res.code == 0) {
          this.msg.success('验证码已发送, 请查收!');
          this.intervalCount = 60;
          let intervalId = window.setInterval(() => {
            --this.intervalCount;
            if (this.intervalCount <= 0) {
              this.getVerificationLoading = false;
              window.clearInterval(intervalId);
            }
          }, 1000);
        } else {
          this.getVerificationLoading = false;
        }
      });
    }

  }

  submit() {
    Object.values(this.form.controls).forEach(formControl => {
      formControl.markAsDirty();
      formControl.updateValueAndValidity();
    });

    if (!this.form.valid) {
      return;
    }

    const data = {
      ...this.form.value,
    };

    const headers = {
      token: '',  // 标识该请求不需要Token
    };

    this.loading = true;
    this.http.get(`${_baseUrl}/cooperation/v1/message/sms/check?smsType=commonCode&mobile=` + this.userInfo.mobile + `&code=` + data.verification, { headers }).subscribe((res: any) => {
      this.loading = false;
      if (res.code == 0) {
        this.form.patchValue({ token: res.data || '' });
        this.showResetForm = true;
        this.msg.success('验证成功, 请重新设置密码!');
      }
    });
  }

  /**
   * 重置密码 提交
   */
  resetSubmit() {
    Object.values(this.resetForm.controls).forEach(formControl => {
      formControl.markAsDirty();
      formControl.updateValueAndValidity();
    });

    if (!this.resetForm.valid) {
      return;
    }

    let formData = this.resetForm.value;

    if (formData.password != formData.re_password) {
      this.msg.info('两次密码输入 不一致!');
      return;
    }

    const data = {
      password: formData.password,
      //mobile: this.form.value.userName,
      mobile: this.userInfo.mobile
    };

    const headers = {
      token: this.form.value.token,  // 标识该请求不需要Token
    };

    this.loading = true;
    this.http.get(`${baseUrl}/users/forgetPassword`, { params: data, headers }).subscribe((res: any) => {
      this.loading = false;
      if (res.code == 0) {
        this.msg.success('密码重置成功');
        setTimeout(() => {
          this.router.navigate(['/passport/login'])
        }, 1000)
      }
    });
  }


}
