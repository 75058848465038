

export class IHttpResponse<T> {
  code: number;
  requestId: string;
  data: T;
  msg?: string;
}






