import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { NzMessageService, NzModalService, NzModalRef } from 'ng-zorro-antd';

import * as moment from 'moment';

import { _HttpClient } from '@delon/theme';
import { baseUrl as _baseUrl } from '@env/environment';
import { CommonService, baseUrl } from '@core';
import { HttpParams } from '@angular/common/http';
import { CodeManageService, CodeManageData, setMealData, saveData } from '@core/codemanage.service';

@Component({
  selector: 'app-codeedit',
  templateUrl: './codeedit.component.html',
  styleUrls: ['./codeedit.component.less'],
})
export class CodeeditComponent implements OnInit {
  baseUrl = baseUrl;
  //是否是查看
  @Input() isView = false;
  //账号数据
  @Input() accountData: any = null;
  disabled = false;
  //详情数据
  detailsData: any = {};

  form = <FormGroup>null;
  loading = false;
  // 套餐
  packageList = [];
  // 所属运用中心
  opList = [];
  //企业分类
  comcat = [];
  // 当前运营中心 可注册企业数量
  remainingCompanyNum = 0;
  // 当前运营中心 可使用的用户数量
  remainingUserNum = 0;
  fileList = [];

  constructor(
    public modal: NzModalRef,
    private modalService: NzModalService,
    private fb: FormBuilder,
    private message: NzMessageService,
    private http: HttpClient,
    public commonService: CommonService,
    private codeManageService: CodeManageService,
  ) {}

  ngOnInit() {
    this.loading = true;
    this.loadOpList();
    this.initEditDialogForm();
  }

  /**
   * 初始化表单
   */
  initEditDialogForm() {
    this.form = this.fb.group({
      activationName: [null, [Validators.required]],
      operationCenterCode: [null, [Validators.required]],
      accountType: [null, [Validators.required]],
      expiration: [null, [Validators.required]],
      expirations: [null, [Validators.required]],
      activateEndTime: [null, [Validators.required]],
      mealId: [null, [Validators.required]],
      promotionChannel: [null, [Validators.required]],
      userQuantity: [null, [Validators.required]],
      store: [null, [Validators.required]],
      permanent: [null],
      permanentTime: [null],
      unlimited: [null],
    });
    if (this.accountData) {
      /**
       * [查看] & [编辑] 填充数据
       */
      let formData = { ...this.detailsData };
      // 到期日期
      formData.expireTime && (formData.expireTime = new Date(formData.expireTime));
      // 到期日期(永久)
      formData.permanent != null && (formData.permanent = !formData.permanent);
      //用户数量(不限)
      formData.unlimited != null && (formData.unlimited = !formData.unlimited);
      //套餐
      formData.setMealId && (formData.setMealId = formData.setMealId.split(','));

      this.loading = false;
      this.form.patchValue(formData);

      //加载该运营中心的套餐
      setTimeout(() => {
        if (formData.operationCenterCode) {
          this.opChange(false, formData.operationCenterCode, formData.setMealId, formData.userNum);
        }
      }, 500);
      if (this.isView) {
        // [查看] 禁用表单
        this.form.disable();
      } else {
        // [编辑] 禁用基本信息
        this.form.controls.fullName.disable();
        this.form.controls.account.disable();
        this.form.controls.industry.disable();
        this.form.controls.longitude.disable();
        this.form.controls.latitude.disable();
      }
    } else {
      // [添加] 设置默认值
      this.loading = false;
      this.form.patchValue({ expirations: 'D' });
    }

    return Promise.resolve(true);
  }

  /**
   * 加载 所属运营中心
   */
  loadOpList() {
    return this.http
      .get<CodeManageData>(`${baseUrl}/operationCenter/list`)
      .toPromise()
      .then(res => {
        if (res.code == 0) {
          this.opList = this.commonService.formatNodes(res.data || [], 'code');
        }
      });
  }

  /**
   * 运营中心改变 -> 加载套餐
   * @param isForm 是否从表单触发
   * @param operationCenterCode
   * @param setMealId
   * @param userNum
   */
  opChange(isForm, operationCenterCode, mealId = [], userQuantity = null) {
    // 重置关联数据
    this.remainingCompanyNum = 0;
    this.remainingUserNum = 0;
    this.packageList = [];

    this.form.controls['mealId'].patchValue(null);
    this.form.controls['mealId'].disable();
    this.form.controls['userQuantity'].patchValue(null);
    this.form.controls['userQuantity'].disable();

    if (isForm) {
      this.form.controls['unlimited'].patchValue(false);
      this.form.controls['unlimited'].disable();
      this.checkChange(false, 'userQuantity');
      // 鑫安利中
      if (operationCenterCode == '0') {
        this.form.controls['unlimited'].enable();
      }
    }

    if (operationCenterCode) {
      let node = this.commonService.getNodeByKey(this.opList, operationCenterCode);
      let params = {
        id: node.id,
        entAccountNum: node.entAccountNum,
        entUserNum: node.entUserNum,
      };

      this.http
        .get<setMealData>(baseUrl + '/crmcompanyextend/get/setMealInfo', { params })
        .subscribe(res => {
          if (res.code == 0) {
            this.remainingCompanyNum = res.data.remainingCompanyNum || 0;
            this.remainingUserNum = res.data.remainingUserNum || 0;
            //如果是编辑操作, 则加上当前数据
            if (this.accountData) {
              ++this.remainingCompanyNum;
              if (operationCenterCode == this.detailsData.operationCenterCode) {
                this.remainingUserNum =
                  this.remainingUserNum + (this.detailsData.userQuantity == 100000 ? 0 : this.detailsData.userQuantity);
              }
            }
            if ((this.remainingCompanyNum <= 0 || this.remainingUserNum <= 0) && operationCenterCode != '0') {
              if (this.remainingCompanyNum <= 0) {
                this.message.info('该组织机构可开通 生成激活码数量 已达上限!');
              }

              if (this.remainingUserNum <= 0) {
                this.message.info('该组织机构可开通 企业用户数量 已达上限!');
              }

              /**
               * 禁用当前和所有下级
               */
              this.form.controls['operationCenterCode'].patchValue(null);
              //先重置
              this.commonService.tree2Array(this.opList).forEach(node => (node.disabled = false));
              [node, ...this.commonService.tree2Array(node.children)].forEach(node => (node.disabled = true));
            } else {
              //设置套餐数据
              this.packageList = (res.data.setMeals || []).map(v => ({
                label: v.name,
                value: String(v.id),
              }));
              this.form.controls['mealId'].patchValue(mealId);
              //设置用户数据
              this.form.controls['userQuantity'].patchValue(userQuantity);
              if (!this.isView) {
                this.form.controls['mealId'].enable();
                this.form.controls['userQuantity'].enable();
              }
              this.form.controls['unlimited'].patchValue(this.form.controls['unlimited'].value);
            }
          }
        });
    }
  }

  /**
   * 复选框状态改变事件
   * @param checked
   * @param field
   */
  checkChange(checked, field) {
    if (checked) {
      this.form.controls[field].disable();
      this.form.controls[field].patchValue(null);
      if (field == 'expiration') {
        this.form.controls['expirations'].disable();
      }
    } else {
      this.form.controls[field].enable();
      if (field == 'expiration') {
        this.form.controls['expirations'].enable();
      }
    }
  }

  /**
   * 提交
   */
  ok() {
    this.commonService.formValid(this.form);

    if (!this.form.valid) {
      return;
    }

    let data = {
      ...this.form.value,
    };

    if (Number(data.userQuantity) > Number(this.remainingUserNum) && data.operationCenterCode !== '0') {
      this.message.error('用户数量 不能大于 该组织机构剩余用户数量!');
      return;
    }
    if (Number(data.store) > Number(this.remainingCompanyNum) && data.operationCenterCode !== '0') {
      this.message.error('激活码数量 不能大于 该组织机构剩余企业账号数量!');
      return;
    }
    if (
      Number(this.remainingUserNum) < Number(data.store) * Number(data.userQuantity) &&
      data.operationCenterCode !== '0'
    ) {
      this.message.error('激活码数量或用户数量输入错误请确认!');
      return;
    }

    //所属运用中心name
    data.operationCenterName = (this.commonService.getNodeByKey(this.opList, data.operationCenterCode) || {}).name;

    // 账号有效期 永久
    if (data.permanent) {
      data.expiration = '100Y';
    } else {
      data.expiration = data.expiration + data.expirations;
    }
    // 激活有效期至 永久
    if (data.permanentTime) {
      data.activateEndTime = moment()
        .add(100, 'years')
        .format('YYYY-MM-DD');
    } else {
      data.activateEndTime = moment(data.activateEndTime).format('YYYY-MM-DD');
    }
    // 用户数量不限
    if (data.unlimited) {
      data.userQuantity = 100000;
    }

    // 去除空格
    data.activationName = data.activationName.trim();
    //套餐
    data.mealName = '';
    data.mealId.forEach(item => {
      data.mealName += this.packageList.filter(checkItems => checkItems.value == item)[0].label + ',';
    });
    // 去掉最后的逗号
    data.mealName = data.mealName.substring(0, data.mealName.lastIndexOf(','));
    data.mealId = data.mealId.join(',');
    //添加
    data.store = Number(data.store);
    data.userQuantity = Number(data.userQuantity);
    const datas = new HttpParams({
      fromObject: data,
    });
    this.loading = true;
    this.http.post<saveData>(`${baseUrl}/activation-codes`, datas).subscribe(res => {
      this.loading = false;
      if (res.code == 0) {
        this.message.success('操作成功!');
        this.modal.destroy(true);
      }
    });
  }
}
