import { Component, OnInit, Injector, ChangeDetectorRef } from '@angular/core';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { baseUrl } from '@core';
import { BussinessEditComponent } from './edit/edit.component';
import { BaseCheckableTable } from '../tablebase/base.checkable.table.component';
import { element } from 'protractor';

export class IHttpResponse<T> {
  code: number;
  requestId: string;
  data: T;
  msg?: string;
}
@Component({
  selector: 'app-businessremind',
  templateUrl: './businessremind.component.html',
  styleUrls: ['./businessremind.component.less'],
})
export class BusinessremindComponent extends BaseCheckableTable implements OnInit {
  rows = [];
  total = 0;
  pageIndex = 0;
  pageSize = 50;
  switchValue = false;
  allstatus = 0;
  loading = false;
  bussiness = [];
  treeLoading = false;
  allDisabledBussiness = false;

  roleRadio: number;
  searchParams: any = {};
  allStatusloading = false;
  checkOptionsOne = [
    { label: '分公司负责人', value: '0', checked: false },
    { label: '商务提醒人', value: '1', checked: false },
    { label: '合同签订人', value: '2', checked: false },
  ];
  // 是否显示已禁用业务和项目类型
  isBan: any = 0;
  constructor(
    public msg: NzMessageService,
    private http: _HttpClient,
    protected injector: Injector,
    private modal: ModalHelper,
    private mod: NzModalService,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.getAllStatis();
    this.getList();
  }
  log(list, projectTypeCode, serviceTypeCode, data): void {
    const accountTypeArray = (list || [])
      .filter(item => item.checked)
      .map(v => v.value)
      .join(',');
    const params = {
      remindPeople: accountTypeArray,
      projectPeriod: data.projectPeriod,
      projectTypeCode: projectTypeCode,
      serviceTypeCode: serviceTypeCode,
    };
    this.save(params, 'check');
  }
  getList() {
    const searchParams = {
      isBan: this.isBan,
    };
    this.treeLoading = true;
    this.http.get<any>(`${baseUrl}/property-detail/business-list`, searchParams).subscribe(res => {
      this.treeLoading = false;
      this.bussiness = res.data || [];
      this.roleRadio = this.bussiness[0].code;
      this.search(true);
    });
  }

  roleChange() {
    this.search();
  }

  openallSet() {
    if (this.checkedIds.length == 0) {
      this.msg.error('请选择要批量设置的数据！');
      return;
    }
    this.modal
      .create(
        BussinessEditComponent,
        { roleRadio: this.roleRadio, checkedIds: this.checkedIds },
        { size: 800, modalOptions: { nzMaskClosable: false } },
      )
      .subscribe(res => {
        this.search(true);
      });
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    const searchParams = {
      serviceTypeCode: this.roleRadio,
      isBan: this.isBan,
    };

    Object.entries(searchParams).forEach(([key, value]) => {
      if (value === null) {
        delete searchParams[key];
      }
    });

    this.loading = true;
    this.http.get<IHttpResponse<null>>(`${baseUrl}/business-remind-setting`, searchParams).subscribe(res => {
      this.loading = false;
      if (res.code === 0) {
        this.rows = res.data || [];
        this.rows.forEach(item => {
          if (item.projectPeriod == null) {
            item.projectPeriod = '';
          }
          if (item.beforeRemindDate == null) {
            item.beforeRemindDate = 0;
          }
          item.checkOptionsOne = this.checkOptionsOne.map(element => {
            return {
              ...element,
            };
          });
          if (item.remindPeople) {
            item.checkOptionsOne.map(items => (items.checked = item.remindPeople.includes(items.value)));
          }
        });
        // this.checkOptionsOne.map(item => (item.checked = this.i.accountTypeArray.includes(item.value)));
        // this.total = res.total || 0;
      } else {
        this.msg.error(res.msg);
      }
    });
  }
  /**
   * 禁用启用开关
   */
  switchStatus(value, projectTypeCode, serviceTypeCode, data, type = 'sms') {
    value = Number(value);
    let params = {};
    switch (type) {
      case 'sms':
        params = {
          projectPeriod: data.projectPeriod,
          startSms: value,
        };
        break;
      case 'app':
        params = {
          projectPeriod: data.projectPeriod,
          startMsg: value,
        };
        break;
      case 'period':
        params = {
          projectPeriod: value,
        };
        break;
      case 'date':
        params = {
          projectPeriod: data.projectPeriod,
          beforeRemindDate: value,
        };
        break;
    }
    params = {
      ...params,

      projectTypeCode: projectTypeCode,
      serviceTypeCode: serviceTypeCode,
    };

    this.save(params, type);
  }
  /**
   * 保存单接口
   * @param params
   */
  save(params, type) {
    this.http.post<IHttpResponse<null>>(`${baseUrl}/business-remind-setting`, params).subscribe(res => {
      if (res.code === 0) {
        this.msg.success(res.msg);
        if (type == 'period') {
          this.search(true);
        }
        // this.search(true);
      } else {
        this.msg.error(res.msg);
      }
    });
  }
  /**
   * 获取提醒设置全部状态
   */
  getAllStatis() {
    this.http.get<IHttpResponse<null>>(`${baseUrl}/business-remind-setting/remind-status`).subscribe(res => {
      if (res.code === 0) {
        this.allstatus = res.data;
      } else {
        this.msg.error(res.msg);
      }
    });
  }
  /**
   * 禁用启用全部开关
   */
  switchAllStatus() {
    // if (!this.allStatusloading) {
    this.allStatusloading = true;
    this.mod.confirm({
      nzTitle: '确认开启/关闭所有提醒设置？',
      nzOnOk: () => {
        let url = `${baseUrl}/business-remind-setting/start-all`;
        if (this.allstatus) {
          url = `${baseUrl}/business-remind-setting/stop-all`;
        }
        this.http.get<IHttpResponse<null>>(`${url}`).subscribe(res => {
          this.allStatusloading = false;
          if (res.code === 0) {
            // this.allstatus = Number(!this.allstatus);
            this.msg.success(res.msg);
            this.search(true);
          } else {
            this.msg.error(res.msg);
          }
        });
      },
      nzOnCancel: () => {
        this.allStatusloading = false;
      },
    });
  }
  /**
   * 显示已禁用业务与项目类型 切换事件
   */
  allDisabledBussinessChange(checked) {
    if (checked) {
      this.isBan = '';
    } else {
      this.isBan = 0;
    }
    this.getList();
    this.search(true);
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.rows
      .filter(item => !item.disabled)
      .every(item => this.mapOfCheckedId[item.projectTypeCode]);

    this.isIndeterminate =
      this.rows.filter(item => !item.disabled).some(item => this.mapOfCheckedId[item.projectTypeCode]) &&
      !this.isAllDisplayDataChecked;

    this.numberOfChecked = this.rows.filter(item => this.mapOfCheckedId[item.projectTypeCode]).length;

    this.checkedIds = Object.entries(this.mapOfCheckedId)
      .filter(([id, checked]) => checked)
      .map(([projectTypeCode]) => projectTypeCode);

    this.rows.forEach(row => {
      const currentIndex = this.checkedRows.findIndex(v => v.projectTypeCode === row.projectTypeCode);
      if (this.mapOfCheckedId[row.projectTypeCode]) {
        currentIndex === -1 && this.checkedRows.push(row);
      } else {
        currentIndex !== -1 && this.checkedRows.splice(currentIndex, 1);
      }
    });

    this.checkedRows = [...this.checkedRows];
  }

  checkAll(value: boolean): void {
    this.rows.filter(item => !item.disabled).forEach(item => (this.mapOfCheckedId[item.projectTypeCode] = value));
    this.refreshStatus();
  }
}
