import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { HttpClient } from '@angular/common/http';

import { _HttpClient } from '@delon/theme';
import { baseUrl as _baseUrl } from '@env/environment';
import { CommonService, baseUrl } from '@core';
import * as moment from 'moment';
import { CodeeditComponent } from './codeedit/codeedit.component';
import { CodeaddComponent } from './codeadd/codeadd.component';
import { HttpParams } from '@angular/common/http';
import { CodeManageService, CodeManageData, packageData } from '@core/codemanage.service';
@Component({
  selector: 'app-codemanage',
  templateUrl: './codemanage.component.html',
  styleUrls: ['./codemanage.component.less'],
})
export class CodemanageComponent implements OnInit {
  baseUrl = baseUrl;
  checkedIds: any = [];
  // 所属运营中心
  opList: any = [];
  // 套餐
  packageList: any = [];
  isVisible = false;
  // 重新激活
  manageEdit: any = {
    activateEndTime: new Date(),
    id: '',
    status: 0,
  };
  // 登录用户
  userInfo = JSON.parse(window.localStorage.getItem('UserInfo') || '{}');
  // 账户状态
  statusList: any = [
    { label: '已激活', value: '1', color: '#000' },
    { label: '未激活', value: '0', color: '#000' },
    { label: '已失效', value: '2', color: '#000' },
    { label: '停用', value: '3', color: '#000' },
    { label: '已售', value: '4', color: '#000' },
  ];

  /**
   * table
   */
  table = {
    searchParams: { daySelect: 'D', operationCenterCode: this.userInfo.operationCenterCode } as any,
    loading: false,
    columns: [
      [
        { title: '序号', field: (row, column, rowIterator) => rowIterator.index + 1 },
        { title: '激活码名称', field: 'activationName' },
        { title: '激活码', field: 'activationCode' },
        { title: '所属组织机构', field: 'operationCenterName' },
        {
          title: '账号有效期',
          field: row => (row.expiration == '100年' ? '<span style="font-size: 20px;">∞</span>' : row.expiration),
        },
        { title: '激活有效期至', field: 'activateEndTime' },
        { title: '套餐', field: 'mealName' },
        {
          title: '用户数量',
          field: row => (row.userQuantity == 100000 ? '<span style="font-size: 20px;">∞</span>' : row.userQuantity),
        },
        { title: '推广渠道', field: 'promotionChannel' },
        {
          title: '状态',
          field: row => {
            const status = this.statusList.find(v => v.value == row.status) || {};
            return `<span style="color: ${status.color};">${status.label || ''}</span>`;
          },
        },
        { title: '激活码生成时间', field: 'createTime' },
        { title: '激活码生成人', field: 'creatorName' },
        { title: '领取人', field: 'receiver' },
        { title: '领用人手机号', field: 'receiverPhone' },
        { title: '激活账号名称', field: 'accountName' },
        { title: '激活账号', field: 'account' },
        { title: '激活时间', field: 'activationTime' },
        { title: '合同编号', field: 'contractNumber' },
        { title: '操作', field: null, fixed: 'right' },
      ],
    ],
    sendCallback: params => {
      params = {
        ...params,
        ...this.table.searchParams,
      };
      if (params.expiration1 && params.daySelect !== 'D') {
        if (params.daySelect == 'M') {
          params.expiration1 = params.expiration1 * 30;
        }
        if (params.daySelect == 'Y') {
          params.expiration1 = params.expiration1 * 365;
        }
      }
      if (params.expiration2 && params.daySelect !== 'D') {
        if (params.daySelect == 'M') {
          params.expiration2 = params.expiration2 * 30;
        }
        if (params.daySelect == 'Y') {
          params.expiration2 = params.expiration2 * 365;
        }
      }

      params.activateEndTime1 && (params.activateEndTime1 = moment(params.activateEndTime1).format('YYYY-MM-DD'));
      params.activateEndTime2 && (params.activateEndTime2 = moment(params.activateEndTime2).format('YYYY-MM-DD'));
      return params;
    },
  };
  @ViewChild('XTable', { static: true }) XTable = null;
  @ViewChild('btnTemp', { static: true }) btnTemp = null;

  constructor(
    private modalService: NzModalService,
    private fb: FormBuilder,
    private message: NzMessageService,
    private http: HttpClient,
    public commonService: CommonService,
    private codeManageService: CodeManageService,
  ) {
    this.loadOpList();
  }

  ngOnInit() {
    this.table.columns[0][this.table.columns[0].length - 1].field = this.btnTemp;
    this.table.columns = [...this.table.columns];
    this.load_packageList();
  }
  /**
   * 加载 所属运营中心
   */
  loadOpList() {
    this.http.get<CodeManageData>(`${baseUrl}/operationCenter/list`).subscribe(res => {
      if (res.code == 0) {
        this.opList = this.commonService.formatNodes(res.data || [], 'code');
      }
    });
  }
  load_packageList() {
    this.http.get<packageData>(baseUrl + '/setMeal/list').subscribe(res => {
      if (res.code == 0) {
        this.packageList = (res.data || []).map(v => ({
          label: v.name,
          value: String(v.id),
        }));
      }
    });
  }

  /**
   * 打开 编辑 对话框
   */
  onOpenEditDialog(accountData = null, isView = false) {
    const modal = this.modalService.create({
      nzTitle: isView ? '查看' : accountData ? '编辑' : '添加',
      nzWidth: accountData ? '700' : '700',
      nzContent: CodeeditComponent,
      nzMaskClosable: false,
      nzComponentParams: {
        accountData,
        isView,
      },
      nzFooter: null,
    });

    modal.afterClose.subscribe(isOk => {
      if (isOk) {
        this.XTable.reload();
      }
    });
  }

  /**
   * 打开 编辑 对话框
   */
  onOpenAddDialog(data = null) {
    const idata = {
      ids: [],
      receiverPhone: '',
      receiver: '',
      contractNumber: '',
      isview: false,
    };
    if (data) {
      idata.ids.push(data.id);
      idata.receiverPhone = data.receiverPhone;
      idata.receiver = data.receiver;
      idata.contractNumber = data.contractNumber;
      idata.isview = true;
    } else {
      if (!this.XTable.checkedRows.length) {
        this.message.error('请勾选要操作的激活码!');
        return;
      }
      idata.ids = this.XTable.checkedRows.map(v => v.id);
    }
    const modal = this.modalService.create({
      nzTitle: '设置领用人',
      nzWidth: '600',
      nzContent: CodeaddComponent,
      nzMaskClosable: false,
      nzComponentParams: {
        idata,
      },
      nzFooter: null,
    });

    modal.afterClose.subscribe(isOk => {
      if (isOk) {
        this.XTable.reload();
      }
    });
  }

  /**
   * 停用 / 启用
   */
  onStatusChange(data = null, status) {
    let ids = [];
    if (data) {
      ids.push(data.id);
    } else {
      if (!this.XTable.checkedRows.length) {
        this.message.error('请勾选要操作的激活码!');
        return;
      }
      if (this.XTable.checkedRows.some(item => item.status !== 0)) {
        this.message.error('只能停用未激活的激活码!');
        return;
      }
      ids = this.XTable.checkedRows.map(v => v.id);
    }
    const par = {
      status,
      ids,
    };
    const datas = new HttpParams({
      fromObject: par,
    });
    this.http.put<any>(`${baseUrl}/activation-codes`, datas).subscribe(res => {
      if (res.code == 0) {
        this.message.success('操作成功!');
        this.XTable.reload();
      }
    });
  }

  change() {
    this.checkedIds = this.XTable.checkedRows.map(v => v.companyId);
  }

  // 复制功能
  copyDetail(data = null) {
    if (!data) {
      if (!this.XTable.checkedRows.length) {
        this.message.error('请勾选要复制的激活码!');
        return;
      }
      data = this.XTable.checkedRows.map(v => v.activationCode).join(',\n');
    }
    const textarea = document.createElement('textarea');
    textarea.value = data;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('Copy');
    document.body.removeChild(textarea);
    this.message.success('复制成功!');
  }
  onOPenChange(data): void {
    this.manageEdit.id = data.id;
    this.isVisible = true;
  }

  handleOk(): void {
    if (!this.manageEdit.activateEndTime || !this.manageEdit.activateEndTime == null) {
      this.message.error('请选择激活有效期!');
      return;
    }
    const par = {
      status: this.manageEdit.status,
      ids: [this.manageEdit.id],
      activateEndTime: moment(this.manageEdit.activateEndTime).format('YYYY-MM-DD'),
    };
    const datas = new HttpParams({
      fromObject: par,
    });
    this.http.put<any>(`${baseUrl}/activation-codes`, datas).subscribe(res => {
      if (res.code == 0) {
        this.message.success('操作成功!');
        this.XTable.reload();
      }
    });
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }
}
