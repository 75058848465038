import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';

@Component({
  selector: 'tbody[x-tbody]',
  templateUrl: './x-tbody.component.html',
  styleUrls: ['./x-tbody.component.scss']
})
export class XTBodyComponent implements OnInit {
  @ViewChild('template', { static: true }) template: TemplateRef<any>;

  constructor(public elementRef: ElementRef) { }

  ngOnInit() {
  }

}