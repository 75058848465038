import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { HttpClient } from '@angular/common/http'

import { _HttpClient, ModalHelper } from '@delon/theme';
import { baseUrl as _baseUrl } from '@env/environment';
import { CommonService, baseUrl } from '@core';
import * as moment from 'moment';
@Component({
  selector: 'app-standard-manage',
  templateUrl: './standard-manage.component.html',
  styleUrls: ['./standard-manage.component.less']
})
export class StandardManageComponent implements OnInit {
  baseUrl = baseUrl;
  industryList = [];
  preViewList = [];
  
  preViews = {
    display: false,
    loading: false,
  };

  /**
   * table
   */
  table = {
    searchParams: {} as any,
    loading: false,
    columns: [[
      { title: '序号', field: (row, column, rowIterator) => rowIterator.index + 1 },
      { title: '项目名称', field: 'projectName' },
      { title: '试卷适用行业', field: 'industryName' },
      { title: '客户名称', field: 'customerName' },
      { title: '客户所在行业', field: 'customerIndustry' },
      { title: '客户地址', field: 'customerAddress' },
      { title: '创建人', field: 'addUserName' },
      { title: '创建时间', field: 'addTime' },
      {
        title: '状态',
        field: (row) => `<span style="color: ${row.status == '1' ? 'green' : 'red'};">${row.status == '1' ? '已完成' : '未完成'}</span>`
      },
      { title: '操作', field: null, fixed: 'right' },
    ]],
    sendCallback: (params) => {
      params = {
        ...params,
        ...this.table.searchParams
      };
      if (params.province) {
        params.province = this.commonService.getAddressByField(this.provinceList, 'code', params.province).name;
      }
      return params;
    }
  };
  @ViewChild('XTable', { static: true }) XTable = null;
  @ViewChild('btnTemp', { static: true }) btnTemp = null;

  //省市区
  provinceList = [];
  cityList = [];

  constructor(private modalService: NzModalService, private http: HttpClient, private message: NzMessageService,
    public commonService: CommonService,
    private mod: ModalHelper,) {
    //加载省
    this.commonService.getAddress('province', '0').subscribe(list => this.provinceList = list);
  }

  ngOnInit() {
    this.table.columns[0][this.table.columns[0].length - 1].field = this.btnTemp;
    this.table.columns = [...this.table.columns];
    this.getIndusryset()
  }

  /**
   * 地址选择
   * @param next = { dataField: 数据源, type: 类型 }
   * @param code
   */
  addressChange(next, code) {
    this.commonService.getAddress(next.type, code).subscribe(list => this[next.dataField] = list);
  }

  //获取行业列表数据
  getIndusryset() {
    this.http.get<any>(`${baseUrl}/bzh/industry`,).subscribe(res => {
      if (res.code == 0) {
        this.industryList = res.data;
      }
    });
  }

  /**
   * 预览
   */
  preView(data) {
    this.preViews.display = true;
    this.preViews.loading = true;
    this.preViewList = data.paperInfo.firstElements;
    console.log(data)
  }

  /**
   * 下载
   */
  download(data) {
    this.http.get<any>(`${baseUrl}/bzh/paper/export?id=${data.id}`,).subscribe(res => {
      if (res.code == 0) {
        window.open(this.commonService.OssSignatureUrl(res.data));
        this.message.success('导出成功');
      }else {
        this.message.error('导出失败');
      }
    });
  }


  /**
   * 关闭添加窗口
   */
  handleCancel() {
    this.preViews.display = false;
  }
  handleOk() {
    this.preViews.display = false;
  }

}
