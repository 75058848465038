import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { _HttpClient } from '@delon/theme';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { isMobile } from '@delon/util';
import { CommonService, baseUrl } from '@core';

@Component({
  selector: 'app-service-engineer',
  templateUrl: './service-engineer.component.html',
})
export class ServiceEngineerComponent implements OnInit {
  record: any = {};
  i: any = {
    fileList: [],
  };
  form = null as FormGroup;
  loading = false;
  checkedCompanyList = [];
  userList = [];
  serviceEngineerId = '';


  constructor(
    private modal: NzModalRef,
    public http: _HttpClient,
    private fb: FormBuilder,
    private message: NzMessageService,
    private commonService: CommonService,
  ) {
    this.initEditDialogForm()
  }

  ngOnInit(): void {
  }

  close() {
    this.modal.destroy();
  }

  initEditDialogForm() {
    this.form = this.fb.group({
      serviceEngineerMobile: [null, [Validators.required, this.mobile]],
      serviceEngineerName: [null, [Validators.required]],
      weiXin: [null],
    });
  }

  /**
   * 文件上传
   */
  beforeUpload = (file): boolean => {
    let OSS = this.commonService.OSS;
    let path = this.commonService.publicPath + this.commonService.getFileType(file);
    OSS.put(path, file).then((res) => {
      const fileFullPath = this.commonService.OssNoSignatureUrl(path);
      this.i.fileList = [
        {
          status: 'done',
          name: file.name,
          size: file.size,
          url: fileFullPath,
          urlPath: fileFullPath
        }
      ];
    }).catch(() => {
      this.message.error('文件上传失败!');
    });
    return false;
  }

  onSearch(value: string): void {
    const params = {mobile: value};
    this.http.get<any>(`${baseUrl}/ahj/member/mobile-like`, params).subscribe(res => {
      if (res.code === 0) {
        this.userList = res.data;
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  mobile(control: AbstractControl) {
    const value = control.value;
    return isMobile(value) ? null : { mobile: `手机号码格式不正确`};
  }

  valueSelect() {
    const mobile = this.form.get("serviceEngineerMobile").value;
    const checkUsers = this.userList.filter(user => user.mobile === mobile);
    if (checkUsers.length > 0) {
      const checkUser = checkUsers[0];
      this.serviceEngineerId = checkUser.id;
      this.form.get("serviceEngineerName").setValue(checkUser.name);
    }
    this.userList = [];
  }

  /**
   * 表单提交
   */
  submit() {
    this.commonService.formValid(this.form);
    if (!this.form.valid) {
      return;
    }
    const data = {
      ...this.form.value,
      serviceEngineerId: this.serviceEngineerId,
      companyList: this.checkedCompanyList,
    };
    if (this.checkedCompanyList.length < 1) {
      this.message.error('请选择公司');
      return;
    }
    data.serviceEngineerImageURL = '';
    data.serviceEngineerImageName = '';
    if (this.i.fileList.length > 0) {
      data.serviceEngineerImageURL =  this.i.fileList[0].urlPath;
      data.serviceEngineerImageName =  this.i.fileList[0].name;
    }
    this.http.put<any>(`${baseUrl}/company-service`, data).subscribe(res => {
      this.loading = false;
      if (res.code === 0) {
        this.checkedCompanyList = [];
        this.loading = false;
        this.message.success('操作成功');
        this.modal.destroy("success");
      }
    });
  }
}
