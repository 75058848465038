import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { XTableComponent } from './x-table/x-table.component';
import { XTheadComponent } from './x-thead/x-thead.component';
import { XTBodyComponent } from './x-tbody/x-tbody.component';
import { XRenderDirective } from './x-render.directive';


import { Test1Component } from './test1/test1.component';
import { Test2Component } from './test2/test2.component';
import { Test3Component } from './test3/test3.component';
import { XThComponent } from './x-th/x-th.component';
import { XTdComponent } from './x-td/x-td.component';
import { AttrDirective } from './attr.directive';

const routes: Routes = [
  { path: 'test1', component: Test1Component },
  { path: 'test2', component: Test2Component },
  { path: 'test3', component: Test3Component },
];

@NgModule({
  declarations: [XTableComponent, Test1Component, Test2Component, Test3Component, XRenderDirective, XTheadComponent, XTBodyComponent, XThComponent, XTdComponent, AttrDirective],
  exports: [XTableComponent],
  imports: [
    CommonModule,
    // HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
  ]
})
export class XTableModule { }
