import { AfterViewInit, Component, OnInit, ViewChild, Input } from '@angular/core';
import { NzFormatEmitEvent, NzTreeComponent, NzTreeNodeOptions, NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme'
import { CommonService, baseUrl } from '@core';
import { format } from 'date-fns';
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class ContractEditComponent implements OnInit {

  @Input() id:string;
 

  //表单临时数据
  t: any = {
    handleStatus:1,
    handlePeople:JSON.parse(window.localStorage.getItem('UserInfo') || '{}').realName,
    handleTime:new Date()
  };

  constructor(private ref: NzModalRef,
    public msg: NzMessageService,
    public http: _HttpClient,
    private mod: ModalHelper, public commonService: CommonService) {
  }

  ngOnInit() {
    this.t.id = this.id;
  }
  /**
    * 处理合同
    */
  save() {
    let params = {
      ...this.t,
    }
    if(params.handleTime){
      params.handleTime = format(params.handleTime, 'YYYY-MM-DD HH:mm:ss');
    }
   
    this.http.put<any>(`${baseUrl}/business-remind/handle`, params).subscribe(res => {
      if (res.code == 0) {
        this.msg.success(res.msg || '处理成功');
        this.close(res);
      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });


  }

  close(res = null) {
    this.ref.destroy(res);
  }

}
