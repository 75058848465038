import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';

import { _HttpClient } from '@delon/theme';
import { CommonService, baseUrl } from '@core';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.less'],
})
export class UserComponent implements OnInit {
  baseUrl = baseUrl;
  jobList = [];
  isJob = false;// 是否显示人员岗位
  /**
   * tree
   */
  opLoading = false;
  opNodes = [];
  opList = [];
  // 当前选中的节点
  selectNode: any = {};
  searchValue = '';

  /**
   * table
   */
  table = {
    searchParams: {} as any,
    loading: false,
    columns: [
      [
        { title: '序号', field: (row, column, rowIterator) => rowIterator.index + 1 },
        { title: '用户名', field: 'userName' },
        { title: '姓名', field: 'realName' },
        { title: '联系方式', field: 'mobile' },
        { title: '所属组织机构', field: 'operationCenterName' },
        { title: '人员岗位', field: 'jobName' },
        { title: '角色', width: 350, field: null },
        { title: '操作', field: null },
      ],
    ],
    sendCallback: params => ({ ...params, ...this.table.searchParams, codeKey: this.selectNode.key }),
  };
  @ViewChild('XTable', { static: false }) XTable = null;
  @ViewChild('btnTemp', { static: true }) btnTemp = null;
  @ViewChild('roleTemp', { static: true }) roleTemp = null;

  /**
   * 编辑对话框
   */
  editDialog = {
    display: false,
    loading: false,
    data: {} as any,
    form: null as FormGroup,
    // 角色列表
    roleList: [],
    roleCheckboxList_loading: false,
  };
  // 菜单跳转方式
  memberPositionList: any = [
    { label: '总经理', value: '1' },
    { label: '综合管理', value: '2' },
    { label: '安全业务代表', value: '3' },
    { label: '保险业务代表', value: '4' },
    { label: '防护用品业务代表', value: '5' },
  ];
  constructor(
    private modalService: NzModalService,
    private fb: FormBuilder,
    private message: NzMessageService,
    private http: _HttpClient,
    public commonService: CommonService,
  ) {
    this.initEditDialogForm();
    // this.loadRoleList();
    // this.loadOrganizationList();
  }

  ngOnInit() {
    this.loadOpNodes();
    this.getjobList();
    this.table.columns[0][6].field = this.roleTemp;
    this.table.columns[0][this.table.columns[0].length - 1].field = this.btnTemp;
    this.table.columns = [...this.table.columns];
  }

  initEditDialogForm() {
    this.editDialog.form = this.fb.group({
      id: [null],
      userName: [null, [Validators.required, Validators.pattern(/^[A-Za-z0-9]+$/)]],
      realName: [null, [Validators.required]],
      mobile: [null, [Validators.required]],
      operationCenterCode: [null, [Validators.required]],
      jobCode: [null],
      roleCheckboxList: [null, [Validators.required]],
    });
  }
  /**
   *获取人员岗位列表
  */
  getjobList(type = 3) {
    this.http.get<any>(`${baseUrl}/operationCenter/attribute?type=${type}`).subscribe(res => {
      if (res.code == 0) {
        this.jobList = res.data || [];
      }
    });
  }
  // /**
  //  * 加载角色列表
  //  */
  // loadRoleList() {
  //   this.http.get<any>(`${baseUrl}/role/list`).subscribe(res => {
  //     if (res.code == 0) {
  //       this.roleList = res.data || [];
  //     }
  //   });
  // }

  /**
   * 加载 组织机构
   */
  loadOpNodes() {
    this.opLoading = true;
    this.http.get<any>(`${baseUrl}/operationCenter/list`).subscribe(res => {
      this.opLoading = false;
      if (res.code == 0) {
        this.opNodes = this.commonService.formatNodes(res.data || [], 'code');
        this.opList = this.commonService.tree2Array(this.opNodes);
        // 默认选中第一个节点
        if (this.opNodes.length) {
          this.selectNode = this.opNodes[0];
        }
      }
    });
  }

  /**
   * 节点选中事件 => 加载对应的表格数据
   */
  onSelectNode(node) {
    if (node.key != this.selectNode.key) {
      this.selectNode = node;
      this.XTable.reload(true);
    }
    this.selectNode = {};
    setTimeout(() => {
      this.selectNode = node;
    }, 0);
  }

  /**
   * 打开 编辑 对话框
   */
  onOpenEditDialog(data = null, isView = false) {
    this.editDialog.display = true;
    this.editDialog.data = data;
    this.editDialog.form.reset();

    if (data) {
      /**
       * 修改
       */
      const _data = {
        ...data,
      };

      // 设置组织机构
      if (_data.operationCenterId) {
        _data.operationCenterCode = (this.opList.find(v => v.id == _data.operationCenterId) || {}).code;
        _data.attributeValue = (this.opList.find(v => v.id == _data.operationCenterId) || {}).attributeValue;
      }
      if (_data.attributeValue == '事业部') {
        this.getjobList(4);
      }
      if (_data.attributeValue == '服务体验中心') {
        this.getjobList(3);
      }
      if (_data.attributeValue == '服务体验中心' || _data.attributeValue == '事业部') {
        this.isJob = true;
        _data.jobCode = _data.jobCode + '';
        this.editDialog.form.get('jobCode')!.setValidators(Validators.required);
      } else {
        this.isJob = false;
        _data.jobCode = '';
        this.editDialog.form.get('jobCode')!.clearValidators();
      }

      this.editDialog.form.patchValue(_data);

      // 加载该组织机构的角色
      setTimeout(() => {
        if (_data.operationCenterCode) {
          const roleIdList = (data.userRoleDtos || []).map(role => role.roleId);
          this.opChange(_data.operationCenterCode, roleIdList, false);
        }
      }, 500);
    } else {

      const attributeValue = (this.opList.find(v => v.code == this.selectNode.key) || {}).attributeValue;
      if (attributeValue == '事业部') {
        this.getjobList(4);
      }
      if (attributeValue == '服务体验中心') {
        this.getjobList(3);
      }
      if (attributeValue == '服务体验中心' || attributeValue == '事业部') {
        this.isJob = true;
        this.editDialog.form.get('jobCode')!.setValidators(Validators.required);
      } else {
        this.isJob = false;
        this.editDialog.form.get('jobCode')!.clearValidators();
      }

      /**
       * 添加
       */
      this.editDialog.form.patchValue({
        // 设置默认的组织机构
        operationCenterCode: this.selectNode.key,
      });
      // 加载该组织机构的角色
      this.opChange(this.selectNode.key, [], false);
    }
  }

  /**
   * 对话框 提交
   */
  onSubmitEditDialog() {
    this.commonService.formValid(this.editDialog.form);

    if (!this.editDialog.form.valid) {
      return;
    }

    const node = this.commonService.getNodeByKey(this.opNodes, this.editDialog.form.value.operationCenterCode);
    const data = {
      ...this.editDialog.form.value,
      operationCenterId: node.id,
      operationCenterName: node.name,
    };
    delete data.operationCenterCode;

    data.roleIds = data.roleCheckboxList
      .filter(v => v.checked)
      .map(v => v.value)
      .join(',');
    delete data.roleCheckboxList;
    if (data.roleIds.length == 0) {
      this.message.info('请勾选角色!');
      return;
    }

    if (this.editDialog.data) {
      this.editDialog.loading = true;
      this.http.put<any>(`${baseUrl}/users/updateById`, data).subscribe(res => {
        this.editDialog.loading = false;
        if (res.code == 0) {
          this.editDialog.display = false;
          this.message.success('操作成功');
          this.XTable.reload();
        }
      });
    } else {
      this.editDialog.loading = true;
      this.http.post<any>(`${baseUrl}/users/save`, data).subscribe(res => {
        this.editDialog.loading = false;
        if (res.code == 0) {
          this.editDialog.display = false;
          this.message.success('操作成功');
          this.XTable.reload();
        }
      });
    }
  }

  /**
   * 删除
   */
  onDelete(data) {
    const params = {
      delName: data.userName,
    };
    this.http.delete(`${baseUrl}/users/${data.id}`, params).subscribe(res => {
      if (res.code == 0) {
        this.message.success('操作成功');
        this.XTable.reload();
      }
    });
  }

  /**
   * 组织机构改变 -> 加载角色
   * @param operationCenterCode
   * @param checkedIdList 要自动勾选的id列表
   */
  opChange(operationCenterCode, checkedIdList = [], isForm) {
    console.log(operationCenterCode, isForm)
    if (isForm) {
      this.editDialog.form.controls.jobCode.patchValue(null);
    } else {
      this.editDialog.form.patchValue({ jobCode: this.editDialog.data.jobCode + '' });
    }
    if (operationCenterCode) {
      this.editDialog.roleCheckboxList_loading = true;
      const node = this.opList.find(v => v.code === operationCenterCode) || {};
      // const isXNLZ = node.title === '鑫安利中';
      let isXNLZ = 1;
      if (node.title === '平台') {
        isXNLZ = 1;
      } else if (node.title === '安全专家在线') {
        isXNLZ = 3;
      } else {
        isXNLZ = 2;
      }

      if (node.attributeValue == '事业部') {
        this.getjobList(4);
      }
      if (node.attributeValue == '服务体验中心') {
        this.getjobList(3);
      }

      if (node.attributeValue == '服务体验中心' || node.attributeValue == '事业部') {
        this.isJob = true;
        this.editDialog.form.get('jobCode')!.setValidators(Validators.required);
      } else {
        this.isJob = false;
        this.editDialog.form.patchValue({ jobCode: '' });
        this.editDialog.form.get('jobCode')!.clearValidators();
      }
      this.http
        .get<any>(`${baseUrl}/role/list`, { type: isXNLZ })
        .subscribe(res => {
          this.editDialog.roleCheckboxList_loading = false;
          if (res.code === 0) {
            this.editDialog.form.controls.roleCheckboxList.patchValue(
              (res.data || []).map(v => ({
                label: v.name,
                value: v.id,
                checked: !!(checkedIdList || []).find(roleId => roleId === v.id),
              })),
            );
          }
        });

    } else {
      this.editDialog.form.controls.roleCheckboxList.patchValue([]);
    }
  }
}
