import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-x-td',
  templateUrl: './x-td.component.html',
  styleUrls: ['./x-td.component.scss']
})
export class XTdComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
