import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';

import { _HttpClient } from '@delon/theme';
import { CommonService, baseUrl } from '@core';

import { UserSelectFromRoleTypeComponent } from '@shared/user-select-from-role-type/user-select-from-role-type.component';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.less'],
})
export class RoleComponent implements OnInit {
  baseUrl = baseUrl;

  table = {
    searchParams: {} as any,
    loading: false,
    columns: [
      [
        { title: '序号', width: 50, field: (row, column, rowIterator) => rowIterator.index + 1 },
        { title: '角色', field: 'name' },
        {
          title: '类型',
          field: row =>
            row.type == 1
              ? '<span style="color: red;">鑫安利中角色</span>'
              : row.type == 2
              ? '<span style="color: blue;">下属组织机构角色</span>'
              : '<span style="color: blue;">安全专家在线角色</span>',
        },
        { title: '描述', field: 'description' },
        { title: '操作', width: 300, field: null },
      ],
    ],
    sendCallback: params => ({ ...params, ...this.table.searchParams }),
  };
  @ViewChild('XTable', { static: false }) XTable = null;
  @ViewChild('btnTemp', { static: true }) btnTemp = null;

  /**
   * 编辑 对话框
   */
  editDialog = {
    display: false,
    loading: false,
    data: <any>{},
    formData: <any>{},
  };

  /**
   * 角色权限 编辑 对话框
   */
  authEditDialog = {
    display: false,
    loading: false,
    data: <any>{},
    treeLoading: false,
    //权限树节点
    authNode: [],
    //勾选的节点
    checkedKeys: [],
  };

  /**
   * 角色成员 对话框
   */
  memberDialog = {
    display: false,
    roleData: null,
    table: {
      isInited: false,
      searchParams: {} as any,
      columns: [
        [
          { title: '序号', field: (row, column, rowIterator) => rowIterator.index + 1 },
          { title: '姓名', field: 'realName' },
          { title: '所属角色', field: 'roleName' },
          { title: '操作', width: 50, field: null },
        ],
      ],
      sendCallback: params => ({
        ...params,
        ...this.memberDialog.table.searchParams,
        roleId: this.memberDialog.roleData.id,
      }),
    },
  };
  @ViewChild('MemberXTable', { static: false }) MemberXTable = null;
  @ViewChild('memberXTablebtnTemp', { static: true }) memberXTablebtnTemp = null;

  // /**
  //  * 角色成员添加 对话框
  //  */
  // memberAddDialog = {
  //   display: false,
  //   loading: false,
  //   memberList: []
  // };

  constructor(
    private modalService: NzModalService,
    private fb: FormBuilder,
    private message: NzMessageService,
    private http: _HttpClient,
    public commonService: CommonService,
  ) {
    this.loadAuthNodes();
  }

  ngOnInit() {
    this.table.columns[0][this.table.columns[0].length - 1].field = this.btnTemp;
    this.table.columns = [...this.table.columns];

    this.memberDialog.table.columns[0][this.memberDialog.table.columns[0].length - 1].field = this.memberXTablebtnTemp;
    this.memberDialog.table.columns = [...this.memberDialog.table.columns];
  }

  /**
   * 加载权限节点
   */
  loadAuthNodes() {
    this.http.get(`${baseUrl}/sysMenu/listByApplicationType`, { applicationType: '2' }).subscribe(res => {
      if (res.code == 0) {
        let nodes = res.data || [];
        nodes = nodes.filter(node => (node.menuVos || []).length);
        nodes.forEach(node => {
          node.name = node.applicationName;
          node.id = 'applicationId_' + node.applicationId;
          node.children = node.menuVos || [];
        });
        this.authEditDialog.authNode = this.commonService.formatNodes(nodes, 'id', 'name');
      }
    });
  }

  /**
   * 打开 编辑 对话框
   */
  onOpenEditDialog(data = null) {
    this.editDialog.display = true;
    this.editDialog.data = data;
    this.editDialog.formData = {};

    if (data) {
      this.editDialog.formData = { ...data };
    } else {
      this.editDialog.formData.type = '1';
    }
  }

  /**
   * 对话框 提交
   */
  onSubmitEditDialog() {
    if (!this.editDialog.formData.name) {
      this.message.info('请输入角色名称!');
      return;
    }

    let data = {
      ...this.editDialog.formData,
    };

    if (this.editDialog.data) {
      this.editDialog.loading = true;
      this.http.put<any>(`${baseUrl}/role/updateById`, data).subscribe(res => {
        this.editDialog.loading = false;
        if (res.code == 0) {
          this.editDialog.display = false;
          this.message.success('操作成功');
          this.XTable.reload();
        }
      });
    } else {
      this.editDialog.loading = true;
      this.http.post<any>(`${baseUrl}/role/save`, data).subscribe(res => {
        this.editDialog.loading = false;
        if (res.code == 0) {
          this.editDialog.display = false;
          this.message.success('操作成功');
          this.XTable.reload();
        }
      });
    }
  }

  /**
   * 打开 设置权限 对话框
   */
  onOpenAuthDialog(data = null) {
    this.authEditDialog.display = true;
    this.authEditDialog.data = data;
    //加载这个角色的权限
    this.authEditDialog.treeLoading = true;
    this.http.get(`${baseUrl}/roleMenu/list`, { roleId: data.id }).subscribe(res => {
      this.authEditDialog.treeLoading = false;
      if (res.code == 0) {
        this.authEditDialog.checkedKeys = (res.data || []).map(v => v.menuId);
        this.authEditDialog.checkedKeys = this.commonService.filterIsHalfChecked(
          this.authEditDialog.authNode,
          this.authEditDialog.checkedKeys,
        );
      }
    });
  }

  /**
   * 设置权限 对话框 提交
   */
  onSubmitAuthDialog(treeComponent) {
    let checkedNodeList = this.commonService.getCheckedNodeList(treeComponent, true);
    // 过滤掉顶级节点
    checkedNodeList = checkedNodeList.filter(node => node.parentNode);

    let data = {
      id: this.authEditDialog.data.id,
      menuIdList: checkedNodeList.map(node => node.key),
      roleId: this.authEditDialog.data.id,
    };

    this.authEditDialog.loading = true;
    this.http.post<any>(`${baseUrl}/roleMenu/save`, data).subscribe(res => {
      this.authEditDialog.loading = false;
      if (res.code == 0) {
        this.authEditDialog.display = false;
        this.message.success('操作成功');
      }
    });
  }

  /**
   * 删除
   */
  onDelete(data) {
    let params = {
      delName: data.name,
    };
    this.http.delete(`${baseUrl}/role/${data.id}`, params).subscribe(res => {
      if (res.code == 0) {
        this.message.success('操作成功');
        this.XTable.reload();
      }
    });
  }

  /**
   * 打开 角色成员 对话框
   * @param data
   */
  onOpenMemberDialog(data) {
    this.memberDialog.display = true;
    this.memberDialog.roleData = data;
  }

  // /**
  //  * 角色成员添加 对话框 保存
  //  */
  // memberAddDialogOk() {
  //   if (this.memberAddDialog.memberList.length == 0) {
  //     this.message.info('请选择成员!');
  //     return;
  //   }

  //   let data = {
  //     roleId: this.memberDialog.roleData.id,
  //     memberIds: this.memberAddDialog.memberList.map(v => v.id)
  //   };

  //   this.memberAddDialog.loading = true;
  //   this.http.post(`/post`, data).subscribe(res => {
  //     this.memberAddDialog.loading = false;
  //     if (res.code == 0) {
  //       this.message.success('操作成功');
  //       this.memberAddDialog.display = false;
  //       this.MemberXTable.reload();
  //     }
  //   });
  // }

  /**
   * 删除成员
   */
  onMemberDel(data) {
    // if (!this.MemberXTable.checkedRows.length) {
    //   this.message.error('请勾选要删除的成员!');
    //   return;
    // }

    // let data = {
    //   roleId: this.memberDialog.roleData.id,
    //   ids: this.MemberXTable.checkedRows.map(v => v.id),
    // };

    this.http.delete(`${baseUrl}/userRole/${data.id}`).subscribe(res => {
      if (res.code == 0) {
        this.message.success('操作成功!');
        this.MemberXTable.reload();
      }
    });
  }

  /**
   * 打开成员选择 对话框
   */
  openMemberSelectDialog() {
    // const modal = this.modalService.create({
    //   nzTitle: '选择成员',
    //   nzWidth: '800',
    //   nzContent: UserSelectDialogComponent,
    //   nzMaskClosable: false,
    //   nzComponentParams: {
    //     checkedRows: [...this.memberAddDialog.memberList]
    //   },
    //   nzOnOk: (dialog) => {
    //     this.memberAddDialog.memberList = dialog.checkedRows
    //   }
    // });

    const modal = this.modalService.create({
      nzTitle: '选择成员',
      nzWidth: '800',
      nzContent: UserSelectFromRoleTypeComponent,
      nzMaskClosable: false,
      nzComponentParams: {
        chkMultiple: false,
        roleType: this.memberDialog.roleData.type,
      },
      nzOnOk: dialog => {
        let row = dialog.checkedRows[0];
        if (row) {
          let data = {
            roleId: this.memberDialog.roleData.id,
            userId: row.id,
            memo: '无',
          };
          return this.http
            .post<any>(`${baseUrl}/userRole/save`, data)
            .toPromise()
            .then(res => {
              if (res.code == 0) {
                this.authEditDialog.display = false;
                this.message.success('操作成功');
                this.MemberXTable.reload();
                return true;
              }
              return false;
            });
        } else {
          this.message.info('请选择成员!');
          return false;
        }
      },
    });
  }
}
