import { fromEvent } from 'rxjs';

import {
  AfterViewInit, ChangeDetectorRef, Component, EventEmitter, NgZone, OnInit, Output, Input
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { baseUrl, domain } from '@core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { LazyService } from '@delon/util';

// import { AddPhotoComponent } from './add-photo/add-photo.component';
// import { DistrictTreeMapComponent } from './district-tree-map/district-tree-map.component';

declare var AMap: any; //高德地图
declare var $: any;

@Component({
  selector: 'app-amap',
  templateUrl: './amap.component.html',
  styleUrls: ['./amap.component.less'],
  styles: [
    `
      [nz-radio] {
        display: block;
      }
    `,
  ],
})
export class AmapComponent implements OnInit {
  @Input() msg;
  @Output() addItem = new EventEmitter();

  // TODO  添加页面 划区域设置名称功能 修改区域名称以及删除功能  ----切换楼层功能
  // 弹窗上传点击确定 上传参数是 ： pickedRec.getPath()  和 图片 和 楼层标记 floor
  map: any = {};
  amapParams: any = {
    paths: '',
    bounds: {},
    loadPolygon: [],
    imageLayer: {},
    pickedRec: {},
    floorId: '',
    belongFloor: '',
    textlocation: '',
    textname: '',
    districtId: '',
    currentPlogy: '',
    exdata: {
      id: '',
      districtId: '',
    },
    factory: null,
    mouseTool: {},
    floores: [{ value: 'F1' }, { value: 'F2' }, { value: 'F3' }],
    hasFactory: null,
  };
  auto: any = {};

  outHeight = '0px';

  floorValue: string = 'Z1';
  selectCheck: any = null;

  quyuMenu: any;
  factoryMenu: any;

  quyuTarget: any; //接收区域参数
  loadPolygon: any = []; // 厂区polygon

  floorList: any = [
    {
      text: 'F2',
      value: 'F2',
    },
    {
      text: 'F1',
      value: 'F1',
    },
    {
      text: 'B1',
      value: 'B1',
    },
    {
      text: 'B2',
      value: 'B2',
    },
  ];

  zoom: number = 14;

  constructor(
    private lazy: LazyService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private http: _HttpClient,
    private modal: ModalHelper,
  ) { }

  ngOnInit() {
    this.outHeight = window.innerHeight - 48 - 49 + 'px';
    fromEvent(window, 'resize').subscribe($event => {
      this.outHeight = window.innerHeight - 48 - 49 + 'px';
    });

    this.getFloor();

    this.lazy
      .loadScript(
        'https://webapi.amap.com/maps?v=1.4.14&key=9b6aa8c00d6ef8ea8fd14d082df78eb4&plugin=AMap.MouseTool,AMap.ControlBar,AMap.RectangleEditor,AMap.Autocomplete',
      )
      .then(() => {
        this.lazy
          .loadScript('http://libs.baidu.com/jquery/2.0.0/jquery.min.js')
          .then(() => {
            this.init(this.floorValue, '', null);
          });
      });
  }
  init(floorValue, lnglat, nodeId) {
    let $that = this;
    $that.loadPolygon = []; // 为处理切换楼层 重现渲染，厂区ID未渲染问题
    $that.selectCheck = null;
    $that.map = new AMap.Map('container', {
      center: [113.811831, 34.866433],
      zoom: $that.zoom,
      expandZoomRange: true,
      zooms: [3, 20],
      mapStyle: 'amap://styles/macaron',
      resizeEnable: true,
    });

    //声明鼠标绘制工具
    $that.amapParams.mouseTool = new AMap.MouseTool(this.map);

    $that.map.on('zoomchange', function () {
      $that.zoom = $that.map.getZoom();
    });

    //创建菜单
    this.quyuMenu = new AMap.ContextMenu();
    this.factoryMenu = new AMap.ContextMenu();
    $that.quyuMenu.addItem(
      '选择区域',
      function () {
        if ($that.amapParams.floorId == '') {
        } else {
          $that.modal
            .create(
              // DistrictTreeMapComponent,
              { amapParams: $that.amapParams, currentPlogy: $that.quyuTarget },
              {
                size: 420,
                modalOptions: {
                  nzMaskClosable: false,
                },
              },
            )
            .subscribe(() => {
              $that.init($that.floorValue, $that.quyuTarget.lnglat, null);
              // 触发tree 再出更新
              $that.addItem.emit();
            });
        }
      },
      0,
    );
    $that.quyuMenu.addItem(
      '删除',
      function () {
        $that.map.remove($that.quyuTarget.target);
        $that.amapParams.factory = undefined;
      },
      1,
    );

    $that.factoryMenu.addItem(
      '上传厂区楼层图',
      function () {
        // 先绘制厂区
        if (typeof $that.amapParams.factory == 'undefined') {
          return;
        }
        $that.modal
          .create(
            // AddPhotoComponent,
            { amapParams: $that.amapParams },
            {
              size: 520,
              modalOptions: {
                nzMaskClosable: false,
              },
            },
          )
          .subscribe(() => {
            $that.init($that.floorValue, $that.quyuTarget.lnglat, null);
          });
      },
      2,
    );
    $that.factoryMenu.addItem(
      '删除',
      function () {
        $that.map.remove($that.quyuTarget.target);
        $that.amapParams.factory = undefined;
      },
      3,
    );

    //search
    AMap.plugin(
      ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale'],
      function () {
        var autoOptions = {
          city: '郑州', //城市，默认全国
          input: 'keyword', //使用联想输入的input的id
        };
        var autocomplete = new AMap.Autocomplete(autoOptions);

        AMap.event.addListener(autocomplete, 'select', function (e) {
          let placeSearch = new AMap.PlaceSearch({
            city: '郑州',
            map: $that.map,
          });
          //TODO 针对选中的poi实现自己的功能
          placeSearch.setCity(e.poi.adcode);
          placeSearch.search(e.poi.name, () => {
            // 其实这个地方是我最主要讲的，请往下看
            // placeSearch.render.markerList.clear() // 这个为清除搜索结果的点，不想清除注释即可
          });
        });
        //比例尺
        var scale = new AMap.Scale();
        $that.map.addControl(scale);
      },
    );

    //画区域
    $that.amapParams.mouseTool.on('draw', function (es) {
      $that.selectCheck = null;
      $that.amapParams.factory = es.obj;
      $that.amapParams.factory.on('rightclick', function (e) {
        for (var i = 0; i < $that.loadPolygon.length; i++) {
          //遍历polygon
          var isPointInRing = AMap.GeometryUtil.isRingInRing(
            e.target.getPath(),
            $that.loadPolygon[i].getPath(),
          );

          if (isPointInRing) {
            //如果当前面中存在marker的坐标点
            if ($that.amapParams.factory) {
              // 如果同时绘制多个，setExtData无效，需判断factory
              $that.amapParams.factory.setExtData(
                $that.loadPolygon[i].getExtData(),
              );
            }
          }
        }
        $that.getVal(e);
        e.target.getOptions().strokeStyle == 'solid'
          ? $that.quyuMenu.open($that.map, e.lnglat)
          : $that.factoryMenu.open($that.map, e.lnglat);
      });
      $that.amapParams.mouseTool.close(); //关闭
      $that.map.setDefaultCursor('pointer');
    });

    //请求楼层
    $that.amapParams.belongFloor = floorValue;
    $that.http
      .get(`${domain}/basics/v1/floors?floorName=${floorValue}&companyId=${this.msg}`, { headers: { token: '' } })
      .subscribe((response: any) => {
        var floorBase = response.data;
        $that.amapParams.hasFactory = response.data;
        if (typeof floorBase == 'undefined' || floorBase == null) {
          // 加载地图右键绘制厂区功能
          $that.amapParams.floorId = '';
        } else {
          var loadPolygon = $that.loadPolygon;
          for (let i = 0; i < floorBase.length; i++) {
            $that.amapParams.floorId = floorBase[i].id;
            $that.amapParams.paths = JSON.parse(floorBase[i].bounds);
            var lng = [];
            var lat = [];

            for (var p = 0, len = $that.amapParams.paths.length; p < len; p++) {
              lng.push($that.amapParams.paths[p][0]);
              lat.push($that.amapParams.paths[p][1]);
            }

            lng.sort(function (a, b) {
              return a - b;
            });
            lat.sort(function (a, b) {
              return a - b;
            });

            $that.amapParams.bounds = new AMap.Bounds(
              [lng[0], lat[0]],
              [lng[lng.length - 1], lat[lat.length - 1]],
            );
            if (lnglat !== '') {
              $that.map.setCenter(lnglat);
            } else {
              $that.map.setCenter($that.amapParams.bounds.getCenter());
            }
            $that.amapParams.imageLayer = new AMap.ImageLayer({
              url: floorBase[i].imgUrl,
              zIndex: 100,
              bounds: $that.amapParams.bounds,
              map: $that.map,
              zooms: [3, 20],
              visible: true,
            });
            var floorObj = {
              img: $that.amapParams.imageLayer,
              id: floorBase[i].id,
            };
            loadPolygon[i] = new AMap.Polygon({
              strokeColor: 'rgba(0,0,0,0)',
              strokeOpacity: 0.2,
              strokeWeight: 0.8,
              strokeDasharray: [30, 10],
              // strokeStyle还支持 solid
              strokeStyle: 'dashed',
              fillOpacity: 0,
              path: $that.amapParams.paths,

              map: $that.map,
            });
            loadPolygon[i].setExtData(floorObj);

            var recMenu = new AMap.ContextMenu();
            recMenu.addItem(
              '删除',
              function () {
                $that.http
                  .delete(
                    `${domain}/basics/v1/floors/${$that.quyuTarget.target.getExtData().id
                    }?floorName=${floorValue}`,
                  )
                  .subscribe((response: any) => {
                    if (response.code == 0) {
                      $that.map.remove($that.quyuTarget.target);
                      $that.map.remove(
                        $that.quyuTarget.target.getExtData().img,
                      );
                      $that.selectCheck = null;
                    } else {
                    }
                  });
              },
              0,
            );
            loadPolygon[i].on('rightclick', function (e) {
              $that.getVal(e);
              recMenu.open($that.map, e.lnglat);
            });
          }
          // 获取覆盖物信息
          $that.http
            .get(
              `${domain}/basics/v1/district/details/overlays?floorName=${$that.amapParams.belongFloor}&companyId=${this.msg}`, { headers: { token: '' } },
            )
            .subscribe((response: any) => {
              var on = response.data;
              if (on.count <= 0) {
                // 无覆盖物信息
              } else {
                // 加载覆盖物信息 同时渲染颜色--添加右键删除功能
                var overlayList = on.overlayList;
                // TODO  加载覆盖物信息 同时渲染颜色--添加右键删除功能
                for (var i = 0; i < overlayList.length; i++) {
                  var lay = overlayList[i];
                  if (lay.type == 'polygon' || lay.type == 'point') {
                    $that.loadOverlay(
                      lay.overlay,
                      lay.id,
                      lay.districtId,
                      '#b8f3a5',
                      lay.name,
                      nodeId,
                    );
                  } else if (lay.type == 'circle') {
                    $that.loadOverlayCircle(
                      lay.overlay,
                      lay.id,
                      lay.districtId,
                      '#b8f3a5',
                      nodeId,
                    );
                  } else {
                    // console.info("不支持的覆盖物类型！" + lay.type);
                  }
                }
              }
            });
        }
      });
  }

  setData(obj) {
    obj.setExtData();
  }

  selectDraw() {
    let $that = this;
    if (
      $that.amapParams.hasFactory == null &&
      this.selectCheck !== 'selectFactory' &&
      this.selectCheck !== 'cancel'
    ) {
      this.selectCheck = 'selectFactory';
      return;
    }
    this.draw(this.selectCheck);
    if (this.selectCheck == 'selectFactory') {
      $that.amapParams.mouseTool.rectangle({
        strokeColor: 'black',
        strokeOpacity: 0.5,
        strokeWeight: 0.5,
        strokeDasharray: [30, 10],
        strokeStyle: 'dashed',
        fillOpacity: 0,
      });
    }
    $that.map.setDefaultCursor('crosshair');
  }
  loadOverlayCircle(overlayString, id, districtId, color, nodeId) {
    var over = JSON.parse(overlayString);

    var circle = new AMap.Circle({
      center: new AMap.LngLat(over.center.lng, over.center.lat),
      radius: over.radius, //半径
      strokeColor: 'black',
      strokeOpacity: 0,
      strokeWeight: 0,
      strokeStyle: 'dashed',
      strokeDasharray: [10, 10],
      // 线样式还支持 'dashed'
      fillColor: color,
      zIndex: 50,
    });

    if (districtId == nodeId) {
      circle.setOptions({
        fillColor: '#d0e17d',
        bubble: true,
        strokeColor: '#FF4500',
        strokeWeight: 4,
      });
      this.map.setCenter(over.center);
    }
    circle.setExtData({ id: id, districtId: districtId });
    circle.setMap(this.map);
    var $that = this; // this指针
    var recMenu = new AMap.ContextMenu();
    recMenu.addItem('删除', function () {
      var extdata = $that.quyuTarget.getExtData();
      if (extdata.id == undefined || extdata.id == '') {
        return;
      } else {
        $that.http
          .delete(`${domain}/basics/v1/district/details/${extdata.districtId}`)
          .subscribe((response: any) => {
            if (response.code == 0) {
              $that.init($that.floorValue, $that.quyuTarget.getCenter(), null);
              $that.selectCheck = null;
              // 触发tree 再出更新
              $that.addItem.emit();
            } else {
            }
          });
      }
    });
    circle.on('rightclick', function (e) {
      var target = e.target;
      $that.getVal(target);
      recMenu.open($that.map, e.lnglat);
    });
  }
  loadOverlay(overlayString, id, districtId, color, name, nodeId) {
    let $that = this;
    var gge = new AMap.GeoJSON();
    var ovobj = JSON.parse(overlayString);
    gge.importData(ovobj);
    var over = gge.getOverlays()[0].getOverlays()[0];
    over.setExtData({ id: id, districtId: districtId });
    if (
      over.CLASS_NAME == 'AMap.Polygon' ||
      over.CLASS_NAME == 'AMap.Rectangle'
    ) {
      var prentNodeDistrictId = '0001';
      if (districtId == prentNodeDistrictId) {
        over.setOptions({
          fillColor: color,
          bubble: true,
          strokeColor: '#FF4500',
          strokeWeight: 4,
        });
      } else {
        over.setOptions({
          fillColor: color,
          strokeWeight: 0.4,
          bubble: true,
          strokeColor: '#666666',
          strokeOpacity: 0.3,
        });

        if (districtId == nodeId) {
          over.setOptions({
            fillColor: '#d0e17d',
            bubble: true,
            strokeColor: '#FF4500',
            strokeWeight: 4,
          });
          $that.map.setCenter(over.getPath()[0][0]);
        } else {
          over.setOptions({
            fillColor: color,
            strokeWeight: 0.4,
            bubble: true,
            strokeColor: '#666666',
            strokeOpacity: 0.3,
          });
        }
      }
      var infoWindow;
      infoWindow = new AMap.InfoWindow({
        isCustom: true, //使用自定义窗体
        //content:infoWindowcontent,
        autoMove: true,
        anchor: 'middle-right',
      });
    } else if (over.CLASS_NAME == 'AMap.Marker') {
      var textMaker = new AMap.Text({
        text: name,
        position: over.getPosition(),
        draggable: false,
        extData: over.getExtData(),
        direction: 'center',
      });
      over = textMaker;
    } else if (over.CLASS_NAME == 'AMap.Circle') {
      over.setOptions({
        fillColor: color,
        bubble: true,
        strokeColor: '#FF4500',
        strokeWeight: 4,
      });
    }
    this.map.add(over);

    var recMenu = new AMap.ContextMenu();
    recMenu.addItem('删除', function () {
      let objPath = {};
      if ($that.quyuTarget.CLASS_NAME == 'AMap.Text') {
        objPath = $that.quyuTarget.getPosition();
      } else if ($that.quyuTarget.CLASS_NAME == 'AMap.Polygon') {
        objPath = $that.quyuTarget.getPath()[0][0];
      }
      var extdata = $that.quyuTarget.getExtData();
      if (extdata.id == undefined || extdata.id == '') {
        return;
      } else {
        $that.http
          .delete(`${domain}/basics/v1/district/details/${extdata.districtId}`)
          .subscribe((response: any) => {
            if (response.code == 0) {
              $that.init($that.floorValue, objPath, null);
              $that.selectCheck = null;
              // 触发tree 再出更新
              $that.addItem.emit();
            } else {
            }
          });
      }
    });
    over.on('rightclick', function (e) {
      var target = e.target;
      $that.getVal(target);
      recMenu.open($that.map, e.lnglat);
    });
  }

  // 中间值传递
  getVal(e) {
    this.quyuTarget = e;
  }

  //切换楼层
  changeFloor() {
    this.init(this.floorValue, '', null);
  }

  getFloor() {
    this.http
      .get(`${domain}/basics/v1/companys/floor?companyId=${this.msg}`, { headers: { token: '' } })
      .subscribe((res: any) => {
        if (res.code == 0) {
          this.floorList = res.data || this.floorList;
        } else {
        }
      });
  }

  //添加楼层
  addFloor(type: string) {
    var startFloor = this.floorList[0].value;
    var endFloor = this.floorList[this.floorList.length - 1].value;
    var old_index;
    if (type == 'up') {
      //向上加楼层
      old_index = parseInt(startFloor.slice(1, startFloor[startFloor.length]));
      this.floorList = [
        {
          text: `F${old_index + 1}`,
          value: `F${old_index + 1}`,
        },
        ...this.floorList,
      ];
    } else {
      //向下加楼层

      old_index = parseInt(endFloor.slice(1, endFloor[endFloor.length]));
      this.floorList = [
        ...this.floorList,
        {
          text: `B${old_index + 1}`,
          value: `B${old_index + 1}`,
        },
      ];
    }
    this.http
      .put(`${domain}/basics/v1/companys/floor?companyId=${this.msg}`, { headers: { token: '' } }, {
        floors: this.floorList,
        floorName: old_index,
      })
      .subscribe((res: any) => {
        if (res.code == 0) {
          this.floorValue = 'Z1';
          this.init('Z1', '', null);
        } else {
        }
      });
  }
  //删除楼层
  reduceFloor(type: string) {
    var startFloor = this.floorList[0].value;
    var index;
    var endFloor = this.floorList[this.floorList.length - 1].value;
    if (type == 'up') {
      //向上减楼层
      index =
        'F' + parseInt(startFloor.slice(1, startFloor[startFloor.length]));
      if (parseInt(startFloor.slice(1, startFloor[startFloor.length])) == 1) {
        return;
      }
      this.floorList.splice(0, 1);
    } else {
      //向下减楼层
      if (parseInt(endFloor.slice(1, endFloor[endFloor.length])) == 1) {
        return;
      }
      index = 'B' + parseInt(endFloor.slice(1, endFloor[endFloor.length]));
      this.floorList.splice(this.floorList.length - 1, 1);
    }

    this.http
      .put(`${domain}/basics/v1/companys/floor?companyId=${this.msg}`, { headers: { token: '' } }, {
        floors: this.floorList,
        floorName: index,
      })
      .subscribe((res: any) => {
        if (res.code == 0) {
          this.floorValue = 'Z1';
          this.init('Z1', '', null);
        } else {
        }
      });
  }

  //绘画类型
  draw(type = null) {
    let $that = this;
    switch (type) {
      case 'marker': {
        $that.amapParams.mouseTool.marker({
          //同Marker的Option设置
          extData: $that.amapParams.exdata,
          zIndex: 9,
          text: '我是文字标记',
        });
        break;
      }
      case 'polyline': {
        $that.amapParams.mouseTool.polyline({
          strokeColor: '#80d8ff',
          //同Polyline的Option设置
          extData: $that.amapParams.exdata,
        });
        break;
      }
      case 'polygon': {
        $that.amapParams.mouseTool.polygon({
          fillColor: '#00b0ff',
          strokeColor: '#80d8ff',
          //同Polygon的Option设置
          extData: $that.amapParams.exdata,
        });
        break;
      }
      case 'rectangle': {
        $that.amapParams.mouseTool.rectangle({
          fillColor: '#00b0ff',
          strokeColor: '#80d8ff',
          //同Polygon的Option设置
          extData: $that.amapParams.exdata,
        });
        break;
      }
      case 'circle': {
        $that.amapParams.mouseTool.circle({
          fillColor: '#00b0ff',
          strokeColor: '#80d8ff',
          //同Circle的Option设置
          extData: $that.amapParams.exdata,
        });
        break;
      }
    }
  }
}
