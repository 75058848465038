import {
  Component,
  ViewChild,
  ComponentFactoryResolver,
  ViewContainerRef,
  AfterViewInit,
  OnInit,
  OnDestroy,
  ElementRef,
  Renderer2,
  Inject,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  Router,
  NavigationEnd,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
  NavigationError,
  NavigationCancel,
} from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import { updateHostClass } from '@delon/util';
import { _HttpClient, SettingsService } from '@delon/theme';
import { NzModalService } from 'ng-zorro-antd';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from '@core';

@Component({
  selector: 'layout-default',
  templateUrl: './default.component.html',
})
export class LayoutDefaultComponent implements OnInit, AfterViewInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @ViewChild('settingHost', { read: ViewContainerRef, static: true })
  private settingHost: ViewContainerRef;
  isFetching = false;

  constructor(
    router: Router,
    _message: NzMessageService,
    private resolver: ComponentFactoryResolver,
    private http: _HttpClient,
    private settings: SettingsService,
    private el: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private doc: any,
    public commonService: CommonService,
    private modalService: NzModalService,
  ) {
    // scroll to top in change page
    router.events.pipe(takeUntil(this.unsubscribe$)).subscribe(evt => {
      if (!this.isFetching && evt instanceof RouteConfigLoadStart) {
        this.isFetching = true;
      }
      if (evt instanceof NavigationError || evt instanceof NavigationCancel) {
        this.isFetching = false;
        if (evt instanceof NavigationError) {
          // _message.error(`无法加载${evt.url}路由`, { nzDuration: 1000 * 3 });
          this.openUpdateCheckDialog();
        }
        return;
      }
      if (!(evt instanceof NavigationEnd || evt instanceof RouteConfigLoadEnd)) {
        return;
      }
      if (this.isFetching) {
        setTimeout(() => {
          this.isFetching = false;
        }, 100);
      }
    });

    this.commonService.setOSSInterval();
  }

  private setClass() {
    const { el, doc, renderer, settings } = this;
    const layout = settings.layout;
    updateHostClass(el.nativeElement, renderer, {
      ['alain-default']: true,
      [`alain-default__fixed`]: layout.fixed,
      [`alain-default__collapsed`]: layout.collapsed,
    });

    doc.body.classList[layout.colorWeak ? 'add' : 'remove']('color-weak');
  }

  ngAfterViewInit(): void {
    // // Setting componet for only developer
    // if (true) {
    //   setTimeout(() => {
    //     const settingFactory = this.resolver.resolveComponentFactory(SettingDrawerComponent);
    //     this.settingHost.createComponent(settingFactory);
    //   }, 22);
    // }
  }

  ngOnInit() {
    const { settings, unsubscribe$ } = this;
    settings.notify.pipe(takeUntil(unsubscribe$)).subscribe(() => this.setClass());
    this.setClass();

    this.updateCheck();
  }

  ngOnDestroy() {
    const { unsubscribe$ } = this;
    unsubscribe$.next();
    unsubscribe$.complete();
  }
  openUpdateCheckDialog() {
    this.modalService.confirm({
      nzTitle: '发现新版本, 现在是否刷新页面？',
      // nzContent: '<b>Some descriptions</b>',
      // nzZIndex: 999999,
      nzOnOk: () => {
        window.location.reload();
      },
    });
  }
  /**
   * 更新检查
   */
  updateCheck() {
    let intervalTime = 5 * 1000;
    let runtimeUrl = document.querySelector('script[src*=runtime]').getAttribute('src');

    if (runtimeUrl.endsWith('runtime.js')) return;

    setTimeout(() => {
      let getRuntime = () => {
        this.http.get(`${runtimeUrl}?_=${+new Date()}`, {}, { responseType: 'text' }).subscribe(
          runtimeScript => {
            setTimeout(getRuntime, intervalTime);
          },
          error => {
            if (error.status == 404) {
              // if (window.confirm('发现新版本, 现在是否刷新页面？')) {
              //   window.location.reload()
              // }
              this.openUpdateCheckDialog();
            } else {
              setTimeout(getRuntime, intervalTime);
            }
          },
        );
      };
      getRuntime();
    }, intervalTime);
  }
}
