/**
 * 新手引导
 */
// const introJs = window['introJs']();


export class IntroService {
    constructor() { }

    // introSetOptions(options) {
    //     introJs.setOptions({
    //         skipLabel: '跳过',
    //         doneLabel: '完成',
    //         prevLabel: '上一步',
    //         nextLabel: '下一步',
    //         showBullets: false,
    //         showStepNumbers: false,
    //         exitOnOverlayClick: false,
    //         steps: options
    //     })
    // }

    // introJsStart(options) {
    //     this.introSetOptions(options);
    //     console.log('执行~');
    //     setTimeout(() => { introJs.start() }, 2000)
    // }
}
