import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { SettingsService } from '@delon/theme';
import { CommonService, baseUrl } from '@core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  searchToggleStatus: boolean;
  baseUrl = baseUrl;

  constructor(public settings: SettingsService, private http: HttpClient) {}

  toggleCollapsedSidebar() {
    this.settings.setLayout('collapsed', !this.settings.layout.collapsed);
  }

  ngOnInit() {
    this.getIsShow();
  }
  getIsShow() {
    let code = JSON.parse(window.localStorage.UserInfo || '{}').operationCenterCode;
    this.http.get<any>(`${baseUrl}/operationCenter/code/${code}`).subscribe(res => {
      if (res.code == 0) {
        if (res.data.isOpenXay !== '1') {
          document.getElementById('hidden-li').style.display = 'none';
        }
      }
    });
  }
  searchToggleChange() {
    this.searchToggleStatus = !this.searchToggleStatus;
  }
}
