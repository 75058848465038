import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { CommonService, baseUrl } from '@core';
import { EditComponent } from './edit/edit.component'
@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.less']
})
export class NoticeComponent implements OnInit {

  constructor(
    private modalService: NzModalService,
    private fb: FormBuilder,
    private msg: NzMessageService,
    private http: _HttpClient,
    public commonService: CommonService,
    private modal: ModalHelper,
  ) { }
  baseUrl = baseUrl;

  table = {
    searchParams: {} as any,
    loading: false,
    columns: [[
      { title: '序号', width: 50, field: (row, column, rowIterator) => rowIterator.index + 1 },
      { title: '标题', field: 'title' },
      {
        title: '状态', field: (row) => {
          if (row.status == 0) {
            return '<span>草稿</span>'
          } else if (row.status == 1) {
            return '<span>已发布</span>'
          } else if (row.status == 3) {
            return '<span>延迟发布</span>'
          }
        }
      },
      { title: '添加人', field: 'createUserName' },
      { title: '创建时间', width: 300, field: 'createTime' },
      { title: '发布时间', width: 300, field: 'publishTime' },
      { title: '操作', width: 300, field: null }
    ]],
    sendCallback: (params) => ({ ...params, ...this.table.searchParams })
  };
  @ViewChild('XTable', { static: false }) XTable = null;
  @ViewChild('btnTemp', { static: true }) btnTemp = null;
  ngOnInit() {
    this.table.columns[0][this.table.columns[0].length - 1].field = this.btnTemp;
    this.table.columns = [...this.table.columns];
  }
  // 发布/
  onOpenEditDialog(i: any = {}, type: any = '') {
    this.modal.create(EditComponent, { i, type }, {
      size: 800, modalOptions: {
        nzMaskClosable: false,
      },
    }
    ).subscribe((res) => {
      this.XTable.reload();
    });
  }

  // 删除公告
  deleteNotice(row: any) {
    let params = {
      delName: row.title,
    }
    this.http.delete<any>(`${baseUrl}/notice/${row.id}`, params)
      .subscribe((res) => {
        if (res.code == 0) {
          this.msg.success(res.msg);
          this.XTable.reload();
        }
      });
  }

  //撤回公告
  withdraw(row: any) {
    let params = {
      id: row.id,
      title: row.title,
    }
    // this.http.put<any>(`${baseUrl}/notice/recall`, { id: row.id })
    this.http.put<any>(`${baseUrl}/notice/recall`, params)
      .subscribe((res) => {
        if (res.code == 0) {
          this.msg.success(res.msg);
          this.XTable.reload();
        }
      });
  }

  // 发布已保存
  lineSend(row: any) {
    let params = {
      id: row.id,
      title: row.title,
    }
    this.http.put<any>(`${baseUrl}/notice/publish`, params)
      .subscribe((res) => {
        if (res.code == 0) {
          this.msg.success(res.msg);
          this.XTable.reload();
        }
      });
  }


}
