import { NgModule } from '@angular/core';

import { SharedModule } from '@shared';
import { RouteRoutingModule } from './routes-routing.module';

// passport pages
import { UserLoginComponent } from './passport/login/login.component';
import { UserRegisterComponent } from './passport/register/register.component';
import { ResetPasswordComponent } from './passport/reset-password/reset-password.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { OperationComponent } from './operation/operation.component';
import { RoleComponent } from './role/role.component';
import { UserComponent } from './user/user.component';
import { ComboComponent } from './combo/combo.component';
import { MenuComponent } from './menu/menu.component';
import { ActivationComponent } from './activation/activation.component';
import { ModuleeditComponent } from './menu/edit/edit.component';
import { AddmenuComponent } from './menu/addmenu/addmenu.component';
import { NoticeComponent } from './notice/notice.component';
import { EditComponent } from './notice/edit/edit.component';
import { PersoninfoComponent } from './personinfo/personinfo.component';
import { TrialComponent } from './product/trial/trial.component';
import { TrialEditComponent } from './product/trial/edit/edit.component';
import { PartnersComponent } from './product/partners/partners.component';
import { PartnersEditComponent } from './product/partners/edit/edit.component';
import { DologComponent } from './dolog/dolog.component';
import { CodemanageComponent } from './codemanage/codemanage.component';
import { CodeeditComponent } from './codemanage/codeedit/codeedit.component';
import { CodeaddComponent } from './codemanage/codeadd/codeadd.component';
import { CompanyComponent } from './customer/company/company.component';
import { DataLsdJgV2Component } from './data-lsd-jg-v2/data-lsd-jg.component';
import { DataLsdV2Component } from './data-lsd-v2/data-lsd-v2.component';
import { ServiceEngineerComponent } from './customer/company/service-engineer/service-engineer.component';
import { StandardPaperComponent } from './standard-paper/standard-paper.component';
import { IndustrysetComponent } from './standard-paper/industryset/industryset.component';
import { StandardManageComponent } from './standard-paper/standard-manage/standard-manage.component';
import { BusinessremindComponent } from './businessremind/businessremind.component';
import { BusinesscontractComponent } from './businesscontract/businesscontract.component';
import { BussinessEditComponent } from './businessremind/edit/edit.component';
import { ContractEditComponent } from './businesscontract/edit/edit.component';
import { ApplicationComponent } from './application/application.component';
import { GPositionComponent } from './application/g-position/g-position.component';
import { AmapComponent } from './application/g-position/amap/amap.component';
import { PosterComponent } from './poster/poster.component';
import { AhjuserComponent } from './ahjuser/ahjuser.component';
import { XayReferrerComponent } from './ahjuser/xay-referrer/xay-referrer.component';
import { AhjReferrerComponent } from './ahjuser/ahj-referrer/ahj-referrer.component';
import { SupplierjoinComponent } from './supplierjoin/supplierjoin.component';
const COMPONENTS = [
  // passport pages
  UserLoginComponent,
  UserRegisterComponent,
  ResetPasswordComponent,

  DashboardComponent,
  RoleComponent,
  UserComponent,
  ComboComponent,
  MenuComponent,
  ActivationComponent,
  PartnersEditComponent,
  DologComponent,
  DataLsdJgV2Component,
  DataLsdV2Component,
  ServiceEngineerComponent,
  StandardPaperComponent,
  StandardManageComponent,
  AhjuserComponent
];
const COMPONENTS_NOROUNT = [
  ModuleeditComponent,
  AddmenuComponent,
  EditComponent,
  TrialEditComponent,
  PartnersEditComponent,
  CodeeditComponent,
  CodeaddComponent,
  ServiceEngineerComponent,
  BussinessEditComponent,
  ContractEditComponent,
  XayReferrerComponent,
  AhjReferrerComponent
];

@NgModule({
  imports: [SharedModule, RouteRoutingModule],
  declarations: [
    ...COMPONENTS,
    ...COMPONENTS_NOROUNT,
    NoticeComponent,
    PersoninfoComponent,
    TrialComponent,
    TrialEditComponent,
    PartnersComponent,
    CodemanageComponent,
    CodeeditComponent,
    CodeaddComponent,
    StandardPaperComponent,
    IndustrysetComponent,
    StandardManageComponent,
    BusinessremindComponent,
    BusinesscontractComponent,
    ApplicationComponent,
    GPositionComponent,
    AmapComponent,
    PosterComponent,
    SupplierjoinComponent,
  ],
  entryComponents: COMPONENTS_NOROUNT,
})
export class RoutesModule { }
