import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { HttpClient } from '@angular/common/http'
import { NzFormatEmitEvent, NzTreeComponent, NzTreeNodeOptions, NzMessageService, NzModalRef, NzModalService } from 'ng-zorro-antd';
import { baseUrl, domain } from '@core';
import { IntroService } from '@core/intro.service';
import { ActivatedRoute } from '@angular/router';
import { SettingsService } from '@delon/theme';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-g-position',
  templateUrl: './g-position.component.html',
  styleUrls: ['./g-position.component.less']
})
export class GPositionComponent extends IntroService implements OnInit {

  confirmModal: NzModalRef; // For testing by now
  // 账号数据
  @Input() positionData: any = null;
  @ViewChild('AmapComponent', { static: true }) amap;
  introOptions = [{
    element: '#step1',
    intro: '第一步：点击添加节点，根据本公司实际区域划分添加区域信息。',
    position: 'bottom'
  },
  {
    element: '#step2',
    intro: '第二步：输入企业名称定位企业位置',
    position: 'bottom'
  },
  {
    element: '#step3',
    intro: '第三步：点击绘制厂区，在定位处绘制厂区轮廓图，上传厂区平面图，点击鼠标右键绑定厂区。',
    position: 'bottom'
  },
  {
    element: '#step4',
    intro: '第四步：选择画圆/多边形/画矩形工具在平面图上绘制各区域在厂区平面图上的位置，再点击右键逐个绑定区域。',
    position: 'bottom'
  },
  ]
  isHaveData: string;
  Visible: boolean = false;
  nodename: string;
  parentnodename: string;
  node: string;
  parentCode: string;
  nodeCode: string;
  isadd = true;
  constructor(
    public settings: SettingsService,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private modal: ModalHelper,
    private modalService: NzModalService,
    private route: ActivatedRoute) {
    super();
  }


  /**
   * tree
   */
  treeLoading = false;
  // tree 数据源
  nodes = [];
  // 当前选中的节点
  selectNode: any = {};
  searchParams: any = {};

  /**
   * 加载 tree 数据
   */
  loadTreeData() {
    let headers = new HttpHeaders({
      'Authorization': 'Basic eXVrb246eXVrb24',
      'token': '',
    });
    this.treeLoading = true;
    console.log(this.positionData, 2223333)
    this.http.get<any>(`${domain}/basics/v1/districts/tree?companyId=${this.positionData}`, { headers }).subscribe(res => {
      this.treeLoading = false;
      if (res.code === 0) {
        this.nodes = res.data || [];

        // 标识叶子节点
        const recursion = (arr) => {
          arr.forEach(node => {
            if (Array.isArray(node.children) && node.children.length > 0) {
              node.expanded = true;
              if (node.binding) {
                node.icon = 'anticon anticon-check';
              } else {
                delete node.icon
              }

              recursion(node.children);

            } else {
              node.isLeaf = true;
              if (node.binding) {
                node.icon = 'anticon anticon-check';
              } else {
                delete node.icon
              }
              this.selectNode.key == null && (this.selectNode = node);
            }
            // 前端数据转换
            node.title = node.name;
            node.key = node.code;
          });
        };

        recursion(this.nodes);

      }
    });
  }

  /**
     * 节点选中事件 => 加载对应的表格数据
     */
  onSelectNode(node) {
    console.log(this.nodes);
    if (node.nodes && (node.nodes.length > 0)) {
      this.amap.init(this.amap.floorValue, '', node.nodes[0].origin.id)
    }
    if (node.key !== this.selectNode.key) {
      this.selectNode = node;
    }
    this.selectNode = {};
    setTimeout(() => {
      this.selectNode = node;
    }, 0);
  }

  // 删除节点
  // remove() {
  //   if (typeof (this.selectNode.keys) == 'undefined' || this.selectNode.keys.length == 0) {
  //     this.msg.error('当前未选中节点！');
  //   } else {
  //     this.http.delete(`${baseUrl}/basics/v1/districts/${this.selectNode.keys[0]}`).subscribe(res => {
  //       if (res.code == 0) {
  //         this.msg.success(res.msg);
  //         this.loadTreeData();
  //       } else {
  //         this.msg.error(res.msg || '加载失败');
  //       }
  //     });
  //   }
  // }
  // // 删除所有节点区域
  // removeAreaAll() {
  //   this.http.delete(`${baseUrl}/basics/v1/district/details`).subscribe(res => {
  //     if (res.code == 0) {
  //       this.msg.success(res.msg);
  //       this.loadTreeData();
  //       this.amap.init(this.amap.floorValue, '', null)
  //     } else {
  //       this.msg.error(res.msg || '加载失败');
  //     }
  //   });
  // }
  // 弹出删除提示框
  // showDelConfirm(): void {
  //   this.confirmModal = this.modalService.confirm({
  //     nzTitle: '提示',
  //     nzContent: '删除后无法恢复，是否确认删除全部区域？',
  //     nzOnOk: () => {
  //       this.removeAreaAll();
  //     }

  //   });
  // }
  // 添加节点
  // addNode() {

  //   this.modal.create(AddNodeComponent,{} , {
  //     size: 600,
  //     modalOptions: {
  //       nzMaskClosable: false,
  //     },
  // }
  //   ).subscribe(() => {
  //     this.loadTreeData();
  //   });

  //   if(this.nodes.length === 0){
  //     this.Visible = true;
  //     this.isadd = true;
  //     this.parentCode  = '0';
  //     this.parentnodename = '';
  //   }else{
  //     if(this.selectNode.keys && (this.selectNode.keys.length !== 0)){
  //       this.Visible = true;
  //       this.isadd = true;
  //       this.parentCode  = this.selectNode.node.origin.code;
  //       this.parentnodename = this.selectNode.node.origin.name;
  //       this.nodename  = '';
  //     }else{
  //       this.msg.error('请先选择上级节点');
  //     }
  //   }


  // }
  // // 编辑节点
  // editNode(node:any={}){
  //   this.node  = node.node.origin.id;
  //   this.nodeCode  = node.node.origin.code;
  //   this.nodename  = node.node.origin.name;
  //   this.parentCode  = node.node.origin.parentCode;
  //   this.parentnodename  = node.node.parentNode.origin.name;
  //   this.http
  //   .get<any>(
  //     `${baseUrl}/hazard/v1/risk/home/check/locationuse`,{locationCode:this.nodeCode} )
  //   .subscribe((res:any) => {
  //     if (res.code === 0) {
  //       if(res.data === 0){
  //         this.Visible = true;
  //         this.isadd = false;
  //       }else{
  //         this.msg.error('此节点下已有风险点，不可编辑');
  //         return;
  //       }

  //     } else {
  //       this.msg.error(res.msg);
  //       return;
  //     }
  //   });


  // }
  // // 保存编辑的节点
  // nodeSave(type=true){
  //   if(!this.nodename){
  //     this.msg.error('节点名称不能为空！');
  //     return;
  //   }
  //   if(type){
  //     this.http.post<any>(`${baseUrl}/basics/v1/districts`, {name:this.nodename,parentCode:this.parentCode})
  //       .subscribe((res) => {
  //         if (res.code === 0 && res.data) {
  //           this.Visible = false;
  //           this.msg.success(res.msg);
  //           this.loadTreeData();
  //         } else {
  //           this.msg.error(res.msg || '操作失败');
  //         }
  //       });
  //   }else{
  //     this.http
  //     .put<any>(
  //       `${baseUrl}/basics/v1/districts`,{id:this.node,name:this.nodename,parentCode:this.parentCode,company:this.settings.user.company_id} )
  //     .subscribe((res:any) => {
  //       if (res.code === 0) {
  //         this.Visible = false;
  //         this.msg.success(res.msg);
  //         this.loadTreeData();
  //       } else {
  //         this.msg.error(res.msg);
  //         return;
  //       }
  //     });
  //   }
  // }
  ngOnInit() {
    this.loadTreeData();
    this.route.queryParams.subscribe(data => {
      this.isHaveData = data.isHaveData;
    });
    if (this.isHaveData === '0') {
      // this.introJsStart(this.introOptions);
    }
  }
}