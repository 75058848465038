import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';

import { _HttpClient } from '@delon/theme';
import { CommonService, baseUrl } from '@core';

/**
 * 根据角色类型选择用户
 */

@Component({
  selector: 'app-user-select-from-role-type',
  templateUrl: './user-select-from-role-type.component.html',
  styleUrls: ['./user-select-from-role-type.component.less'],
})
export class UserSelectFromRoleTypeComponent implements OnInit {
  baseUrl = baseUrl;

  // 选中的行
  @Input() checkedRows = [];
  // 多选?
  @Input() chkMultiple = true;
  // 角色类型
  @Input() roleType = null;

  /**
   * table
   */
  table = {
    searchParams: {} as any,
    loading: false,
    columns: [
      [
        { title: '序号', field: (row, column, rowIterator) => rowIterator.index + 1 },
        { title: '用户名', field: 'userName' },
        { title: '姓名', field: 'realName' },
        { title: '所属组织机构', field: 'operationCenterName' },
      ],
    ],
    sendCallback: params => {
      let obj = { ...params, ...this.table.searchParams };
      if (this.roleType != null) {
        obj['type'] = this.roleType;
      }
      return obj;
    },
    checked: row => !!this.checkedRows.find(v => v.id == row.id),
  };
  @ViewChild('XTable', { static: false }) XTable = null;

  constructor(
    private modalService: NzModalService,
    private fb: FormBuilder,
    private message: NzMessageService,
    private http: _HttpClient,
    public commonService: CommonService,
  ) {}

  ngOnInit() {}

  /**
   * table checkbox 选择事件
   */
  checkboxChange({ checked, rows }) {
    if (checked) {
      this.checkedRows.push(...rows.filter(v => !this.checkedRows.find(checkedRow => checkedRow.id == v.id)));
    } else {
      rows.forEach(v =>
        this.checkedRows.splice(
          this.checkedRows.findIndex(checkedRow => checkedRow.id == v.id),
          1,
        ),
      );
    }
  }
}
