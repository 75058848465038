import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { DomSanitizer } from '@angular/platform-browser';

import { _HttpClient } from '@delon/theme';
import { baseUrl, CommonService } from '@core';

import { ActivatedRoute, Router } from '@angular/router';

import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { format } from 'date-fns';

declare var window: Window & { jQuery: any; echarts: any };

const $ = window.jQuery;
import '../../../assets/lib/jquery.SuperSlide.2.1.3.js';

const echarts = window.echarts;

export interface RateInterface {
  code: string | number;
  msg: string;
  data: [];
}
export interface RateDataInterface {
  name: string;
  yearZhengGaiLv: string;
}
export class IHttpResponse<T> {
  code: number;
  requestId: string;
  data: T;
  msg?: string;
}
export interface ServiceDataResponse {
  completedCount: number;

  customerOnlineTotal: number;

  customerTotal: number;

  expiredCount: number;

  monthAddCustomerCount: number;

  monthCustomerOnlineCount: number;

  notPayAllCount: number;

  servicedCount: number;

  servingCompanyCount: number;

  servingPlanCount: number;

  yearPlanCount: number;

  yearTurnover: number;
}
@Component({
  selector: 'app-data-lsd-jg',
  templateUrl: './data-lsd-jg.component.html',
  styleUrls: ['./data-lsd-jg.component.scss'],
})
export class DataLsdJgV2Component implements OnInit {
  // 查询参数
  params: any = {};
  searchParams: any = {};
  loading = false;
  loadingd = false;
  isReload = false;
  currentCode = '410000';

  // 安全一张图
  currentAreaName = '';
  currentAreaCode = '';
  // 监管中心名称
  supervisionCenterName = '';
  matchingType = 0;
  mapEchartsOption = null;

  IntervalId = null;

  authToken = '';

  isVisible = false;
  modalTitle = '年度隐患整改率';
  rateIsVisible = false;
  rateData;
  rateNum: any = [];
  type = 1;
  dealtData = [];
  serviceData: ServiceDataResponse;
  now = format(new Date(), 'YYYY-MM-DD HH:mm');
  week = '';
  autoplay = true;

  // 本年所有业务类型分布
  option_1 = null;
  // 本年已完成业务数量类型占比
  option_2 = null;
  // 客户风险等级分布
  option_3 = null;
  // 本年待办业务数量占比
  option_4 = null;

  optionColumnar = null;

  businessType = [
    {
      label: '安全业务',
      value: '1',
    },
    {
      label: '环境业务',
      value: '2',
    },
    {
      label: '职业健康业务',
      value: '3',
    },
    {
      label: '消防业务',
      value: '4',
    },
  ];
  bsTypes = [];
  projectTypes = [];
  btype = null;
  /**---------------业务类型-------- */
  serviceTypeCode = null;
  projectTypeCode = null;
  startResult = null;
  endResult = null;

  // 是否全屏
  isFullscreen = false;
  // 地图是否全屏
  mapisFullscreen = false;
  constructor(
    public sanitizer: DomSanitizer,
    private message: NzMessageService,
    private http: _HttpClient,
    private eleMentRef: ElementRef,
    public commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {}

  ngOnInit() {
    this.week = this.transform(this.now);
    this.load();

    this.getScreenName();

    this.getOperationsData();

    this.getStatistics();
    this.getStatisticsComplete();
    this.getRiskLevel();
    this.getStatisticsTodo();

    this.getFinanceStatistics();

    // this.loaddealt(7, '');
    this.getbsTypes();
    this.loadservice();
    this.getCurDay();
  }
  // 获取当前时间 判断是星期几
  transform(value: any): any {
    if (value !== undefined) {
      const weekArray = new Array('周日', '周一', '周二', '周三', '周四', '周五', '周六');
      const myDate = new Date(value);
      const week = weekArray[myDate.getDay()];
      return week;
    }
  }
  load() {
    const params = {
      ...this.searchParams,
    };
    if (params.customerStar == null) {
      params.customerStar = '';
    }
    if (params.industry == null) {
      params.industry = '';
    }
    if (params.companyName == null) {
      params.companyName = '';
    }
    this.http.get<any>(`${baseUrl}/customerScreen/map`, params).subscribe(res => {
      // this.loading = false;
      if (res.code === 0) {
        this.currentCode = res.data.bindCode;

        if (res.data.bindCode == '') {
          res.data.bindCode = '100000';
        }

        const jsonUrl = `https://prdxaloss.oss-cn-beijing.aliyuncs.com/geojson/${res.data.bindCode}.json`;
        this.http
          .get(
            jsonUrl,
            {},
            {
              headers: {
                autoShowErrorMsg: '', // 不弹出错误提示
              },
            },
          )
          .subscribe((elmt: any) => {
            if (elmt.code === 100) {
              this.http.get('https://prdxaloss.oss-cn-beijing.aliyuncs.com/geojson/100000.json').subscribe(item => {
                echarts.registerMap('henan', item);
                this.load();
              });
              return;
            }
            echarts.registerMap('henan', elmt);
            this.loadMapData(res.data); // 安全一张图
          });
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  /**
   * 本周待办业务
   */
  loaddealt(days, businessType) {
    this.loadingd = true;
    this.dealtData = [];
    this.http
      .get<any>(`${baseUrl}/customerScreen/backlog`, { days, businessType: businessType || '' })
      .subscribe(res => {
        this.loadingd = false;
        if (res.code === 0) {
          this.dealtData = res.data || [];
          this.dealtData.forEach((item, index) => {
            if (index % 2 === 0) {
              item.bgc = 'dealover';
            }
            item.eventStartTime = item.eventStartTime;
            item.eventEndTime = format(item.eventEndTime, 'YYYY-MM-DD');
            if (item.companyLevel == null) {
              item.companyLevelImg = '';
            } else {
              item.companyLevelImg = `./assets/images/star_${item.companyLevel}.png`;
            }
          });

          const h = document.documentElement.offsetHeight || document.body.offsetHeight;

          let rows = 5;
          if (h <= 810 && h > 750) {
            rows = 4;
          } else if (h <= 750 && h > 650) {
            rows = 3;
          } else if (h <= 650) {
            rows = 2;
          }

          if (this.type == 1) {
            setTimeout(() => {
              let autoplay = true;
              if ($('._slideTxtBox .bd li').length && $('._slideTxtBox .bd li').length < rows) {
                autoplay = false;
              }
              /* 使用js分组，每rows个li放到一个ul里面 */
              $('._slideTxtBox .bd li').each(function(i) {
                $('._slideTxtBox .bd li')
                  .slice(i * rows, i * rows + rows)
                  .wrapAll('<ul></ul>');
              });
              $('.slideTxtBox').html($('._slideTxtBox').html());
              /* 调用SuperSlide，每次滚动一个ul，相当于每次滚动8个li */
              $('.slideTxtBox').slide({
                titCell: '.hd ul',
                mainCell: '.bd .picList',
                autoPage: true,
                effect: 'topLoop',
                autoPlay: autoplay,
                interTime: 5000,
              });
            }, 100);
          } else {
            setTimeout(() => {
              let autoplay = true;
              if ($('._slideTxtBox1 .bd li').length && $('._slideTxtBox1 .bd li').length < rows) {
                autoplay = false;
              }
              /* 使用js分组，每6个li放到一个ul里面 */
              $('._slideTxtBox1 .bd li').each(function(i) {
                $('._slideTxtBox1 .bd li')
                  .slice(i * rows, i * rows + rows)
                  .wrapAll('<ul></ul>');
              });
              $('.slideTxtBox1').html($('._slideTxtBox1').html());
              /* 调用SuperSlide，每次滚动一个ul，相当于每次滚动8个li */
              $('.slideTxtBox1').slide({
                titCell: '.hd ul',
                mainCell: '.bd .picList1',
                autoPage: true,
                effect: 'topLoop',
                autoPlay: autoplay,
                interTime: 5000,
              });
            }, 100);
          }
        }
      });
  }
  /**
   * 加载业务提醒
   */
  loadservice() {
    this.loadingd = true;
    this.dealtData = [];
    const params = {
      serviceTypeCode: this.serviceTypeCode ? this.serviceTypeCode : '',
      projectTypeCode: this.projectTypeCode ? this.projectTypeCode : '',
    };
    this.http.get<any>(`${baseUrl}/customerScreen/business-remind`, params).subscribe(res => {
      this.loadingd = false;
      if (res.code === 0) {
        this.dealtData = res.data || [];
        this.dealtData.forEach((item, index) => {
          if (index % 2 === 0) {
            item.bgc = 'dealover';
          }
          item.eventStartTime = item.eventStartTime;
          item.eventEndTime = format(item.eventEndTime, 'YYYY-MM-DD');
          if (item.companyLevel == null) {
            item.companyLevelImg = '';
          } else {
            item.companyLevelImg = `./assets/images/star_${item.companyLevel}.png`;
          }
        });

        const h = document.documentElement.offsetHeight || document.body.offsetHeight;

        let rows = 5;
        if (h <= 810 && h > 750) {
          rows = 4;
        } else if (h <= 750 && h > 650) {
          rows = 3;
        } else if (h <= 650) {
          rows = 2;
        }
        setTimeout(() => {
          let autoplay = true;
          if ($('._slideTxtBox .bd li').length && $('._slideTxtBox .bd li').length < rows) {
            autoplay = false;
          }
          /* 使用js分组，每rows个li放到一个ul里面 */
          $('._slideTxtBox .bd li').each(function(i) {
            $('._slideTxtBox .bd li')
              .slice(i * rows, i * rows + rows)
              .wrapAll('<ul></ul>');
          });
          $('.slideTxtBox').html($('._slideTxtBox').html());
          /* 调用SuperSlide，每次滚动一个ul，相当于每次滚动8个li */
          $('.slideTxtBox').slide({
            titCell: '.hd ul',
            mainCell: '.bd .picList',
            autoPage: true,
            effect: 'topLoop',
            autoPlay: autoplay,
            interTime: 5000,
          });
        }, 100);
      }
    });
  }

  changeBusinessType(days, type) {
    this.loaddealt(days, type);
  }
  /**
   * 切换业务服务筛选类型
   * @param type
   */
  changeServiceType(code) {
    this.serviceTypeCode = code;
    this.projectTypeCode = null;
    this.getProjectTypes(code);
    this.loadservice();
  }
  /**
   * 切换项目筛选类型
   * @param type
   */
  changeProjectType(code) {
    this.projectTypeCode = code;
    this.loadservice();
  }
  /**
   * 加载地图数据
   */
  loadMapData(data) {
    this.mapEchartsOption = {
      tooltip: {
        confine: true,
        enterable: true,
      },
      visualMap: [
        {
          type: 'piecewise',
          seriesIndex: 0,
          bottom: '15%',
          pieces: [
            { gte: 62, color: '#c20037', label: '重大风险区域' },
            { gte: 34, lt: 62, color: '#ff8a00', label: '较大风险区域' },
            { gte: 15, lt: 34, color: '#fff000', label: '一般风险区域' },
            { lt: 15, color: '#0095ff', label: '低风险区域' },
          ],
          inverse: true,
          textStyle: {
            color: '#d4fff9',
          },
        },
        {
          type: 'piecewise',
          seriesIndex: 1,
          left: 'auto',
          right: 0,
          bottom: '15%',
          pieces: [
            { gte: 62, color: '#c20037', label: '重大风险企业' },
            { gte: 34, lt: 62, color: '#ff8a00', label: '较大风险企业' },
            { gte: 15, lt: 34, color: '#fff000', label: '一般风险企业' },
            { lt: 15, color: '#0095ff', label: '低风险企业' },
          ],
          inverse: true,
          textStyle: {
            color: '#d4fff9',
          },
        },
      ],
      geo: {
        type: 'map',
        map: 'henan',
        roam: true,
        label: { show: true },
        emphasis: {
          label: {
            color: '#000',
          },
          itemStyle: {
            areaColor: '#d4fff9',
          },
        },
      },
      series: [
        {
          type: 'map',
          map: 'henan',
          roam: true,
          label: { show: true },
          geoIndex: 0,
          tooltip: {
            formatter: data => {
              return `
            <span>
              <div>${data.name}</div>
            </span>`;
            },
          },
          data: [
            ...(data.areaCodeCompanyMapVO || []).map(v => ({
              name: v.areaName,
              value: v.areaIndex || 0,
              code: v.areaCode,
            })),
          ],
        },
        {
          type: 'scatter',
          coordinateSystem: 'geo',
          itemStyle: {
            shadowBlur: 8,
            shadowColor: 'rgba(0,0,0,0.2)',
          },
          tooltip: {
            position: 'right',
            enterable: true,
            formatter: e => {
              const data = e.data.ext || {};

              const formatSafetyIndex = safetyIndex => {
                let safetyName = '';
                if (safetyIndex < 15) {
                  safetyName = '低风险';
                } else if (safetyIndex >= 15 && safetyIndex < 34) {
                  safetyName = '一般风险';
                } else if (safetyIndex >= 34 && safetyIndex < 62) {
                  safetyName = '较大风险';
                } else if (safetyIndex >= 62) {
                  safetyName = '重大风险';
                }
                return safetyName;
              };

              const url = `/#/customer/company/detail/${this.currentAreaCode}/${data.companyId}/${data.fullName}`;

              return `
                <div>
                  <div><strong>${e.name}</strong></div>
                  <div>${data.industry ? `所属行业: ${data.industry}` : ''}</div>
                  <div> ${data.enterpriseSize ? `企业规模: ${data.enterpriseSize}` : ''} </div>
                  <div> ${data.customerStar ? `客户星级: ${data.customerStar}` : ''} </div>
                  <div> 企业综合风险等级: ${formatSafetyIndex(data.safetyIndex)}</div>
                  <div> ${data.serviceSituation ? `服务情况: ${data.serviceSituation}` : ''} </div>
                  <div> ${data.searchNum ? `隐患排查服务次数: ${data.searchNum}` : ''} </div>
                  <div> ${data.appraisalNum ? `安全评价服务次数: ${data.appraisalNum}` : ''} </div>
                  <div> ${data.safetyStandardsNum ? `安全标准服务次数: ${data.safetyStandardsNum}` : ''} </div>
                  <div> ${data.occupationalDiseaseNum ? `职业病服务次数: ${data.occupationalDiseaseNum}` : ''} </div>
                  <div> ${data.fireControlNum ? `消防评价服务次数: ${data.fireControlNum}` : ''} </div>
                  <div> ${data.environmentNum ? `环境评价服务次数: ${data.environmentNum}` : ''} </div>
                  <div> ${data.emergencyDrillNum ? `应急演练服务次数: ${data.emergencyDrillNum}` : ''} </div>
                  <div> ${data.trainNum ? `教育培训服务次数: ${data.trainNum}` : ''} </div>
                  <div><a href="${url}" target="_blank">查看企业详情</a></div>
                </div>
                  `;
            },
          },
          data: [
            ...(data.companyServiceCountVOList.slice(0, 2900) || []).map(v => ({
              name: v.fullName,
              value: [v.longitude, v.latitude, v.safetyIndex],
              ext: v,
            })),
          ],
        },
      ],
    };
  }
  mapInitEchart(myChart) {
    // 散点点击事件
    myChart.on('click', e => {
      switch (e.seriesType) {
        // 企业小圆点
        case 'scatter': {
          if (e.data.ext.online) {
            const suiji = Number((Math.random() * 100).toFixed(0));
            window.open(`${location.origin}/#/data-lsd-v2?companyId=${e.data.ext.companyId}&v=${suiji}`);
          }
          break;
        }
        // 地图区域
        case 'map': {
          break;
        }
      }
    });
  }

  changeDeal(type, days) {
    this.type = type;

    if (type == 1) {
      this.loadservice();
    } else {
      // 加载30天代办业务服务提醒
      this.loaddealt(days, '');
    }
    this.btype = null;
  }
  /**
   * 加载业务类型
   */
  getbsTypes() {
    this.http.get<any>(`${baseUrl}/property-detail/business-list?isBan=0`).subscribe(res => {
      if (res.code === 0) {
        this.bsTypes = res.data || [];
        this.searchParams.serviceTypeCode = this.bsTypes[0].code;
        if (this.bsTypes.length > 0) {
          this.getProjectTypes(this.bsTypes[0].code);
        }
      }
    });
  }
  /**
   * 加载项目类型
   * @param code
   */
  getProjectTypes(code) {
    let params = {
      parentCode: code,
    };
    this.http.get<any>(`${baseUrl}/property-detail/project-list`, params).subscribe(res => {
      if (res.code === 0) {
        this.projectTypes = res.data.data || [];
      }
    });
  }
  logout() {
    this.http.post(`${baseUrl}/users/loginOut`, { id: 1 }).subscribe(res => {
      this.tokenService.clear();
      window.localStorage.removeItem('UserInfo');
      this.router.navigateByUrl(this.tokenService.login_url);
      this.commonService.loginBeforeUrl = window.location.hash.slice(1);
      this.message.success('退出成功！');
    });
  }

  getOperationsData() {
    this.http.get<any>(`${baseUrl}/customerScreen/operations/data`).subscribe(res => {
      if (res.code === 0) {
        this.serviceData = res.data || {};
      }
    });
  }

  // 本年财务数据统计
  getFinanceStatistics() {
    this.http.get<any>(`${baseUrl}/customerScreen/financialStatistics`).subscribe(res => {
      if (res.code === 0) {
        this.loadOptionColumnar(res.data);
      }
    });
  }
  /**
   * 本年财务数据统计
   */
  loadOptionColumnar(data) {
    const dataAxis = data.xdata;
    const datay = data.ydata;

    const yMax = 500;
    const dataShadow = [];

    for (let i = 0; i < data.length; i++) {
      dataShadow.push(yMax);
    }

    this.optionColumnar = {
      tooltip: {
        formatter: '合同额:{c} 万元',
      },
      xAxis: {
        data: dataAxis,
        axisLabel: {
          textStyle: {
            color: '#fff',
          },
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        z: 10,
      },
      yAxis: {
        name: '万元',
        axisLine: {
          show: false,
          lineStyle: {
            color: '#ffffff',
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          textStyle: {
            color: '#ffffff',
          },
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,0.1)',
          },
        },
      },
      dataZoom: [
        {
          type: 'inside',
        },
      ],
      series: [
        {
          // For shadow
          type: 'bar',
          itemStyle: {
            color: 'rgba(0,0,0,0.05)',
          },
          barGap: '-100%',
          barCategoryGap: '40%',
          data: dataShadow,
          animation: false,
        },
        {
          type: 'bar',
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#83bff6' },
              { offset: 0.5, color: '#188df0' },
              { offset: 1, color: '#188df0' },
            ]),
          },
          emphasis: {
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#2378f7' },
                { offset: 0.7, color: '#2378f7' },
                { offset: 1, color: '#83bff6' },
              ]),
            },
          },
          data: datay,
        },
      ],
    };
  }

  // 本年所有业务类型统计
  getStatistics() {
    this.http.get<any>(`${baseUrl}/customerScreen/statistical`).subscribe(res => {
      if (res.code === 0) {
        this.loadOptionStatistics('option_1', res.data);
      }
    });
  }

  // 本年所有业务类型统计
  getRiskLevel() {
    this.http.get<any>(`${baseUrl}/customerScreen/riskLevel`).subscribe(res => {
      if (res.code === 0) {
        // 客户风险等级分布
        const riskStatisticsVO = res.data;
        const risks = [
          {
            num: riskStatisticsVO.major,
            businessTypeName: '重大风险',
          },

          {
            num: riskStatisticsVO.larger,
            businessTypeName: '较大风险',
          },
          {
            num: riskStatisticsVO.general,
            businessTypeName: '一般风险',
          },
          {
            num: riskStatisticsVO.low,
            businessTypeName: '低风险',
          },
        ];
        this.loadOptionStatistics('option_3', risks);
      }
    });
  }

  // 本年已完成业务数量类型占比
  getStatisticsComplete() {
    this.http.get<any>(`${baseUrl}/customerScreen/statistical/complete`).subscribe(res => {
      if (res.code === 0) {
        this.loadOptionStatistics('option_2', res.data);
      }
    });
  }

  // 本年待办业务数量占比
  getStatisticsTodo() {
    this.http.get<any>(`${baseUrl}/customerScreen/statistical/todo`).subscribe(res => {
      if (res.code === 0) {
        this.loadOptionStatistics('option_4', res.data);
      }
    });
  }

  loadOptionStatistics(option, data) {
    this[option] = {
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)',
        confine: true,
      },
      color: Object.values(this.commonService.color),
      series: [
        {
          name: '访问来源',
          type: 'pie',
          radius: ['30%', '50%'],
          data: data.map(item => {
            return {
              value: item.num,
              name: item.businessTypeName,
            };
          }),
          labelLine: {
            lineStyle: {
              // color: 'rgba(255, 255, 255, 0.3)',
            },
            smooth: 0.2,
            length: 0.001,
          },
          label: {
            position: 'outer',
            alignTo: 'edge',
            formatter: '{b}\n{c}',
          },
        },
      ],
    };
  }

  // 本年待办业务数量占比
  getScreenName() {
    this.http.get<any>(`${baseUrl}/customerScreen/ocInfo`).subscribe(res => {
      if (res.code === 0) {
        this.currentAreaName = res.data.screenName;
        this.currentAreaCode = res.data.code;
      }
    });
  }

  jumpTo(params) {
    this.router.navigateByUrl(`/customer/sign?${params}`);
  }

  // 本月第一天和最后一天日期
  getCurDay() {
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    const day = new Date(year, month, 0);
    this.startResult = year + '-' + (month < 10 ? '0' + month : month) + '-01';
    this.endResult = year + '-' + (month < 10 ? '0' + month : month) + '-' + day.getDate();
  }
  // 全屏切换
  onSwitchFullscreen() {
    // 进入全屏
    const requestFullScreen = () => {
      const de = document.documentElement as any;
      if (de.requestFullscreen) {
        de.requestFullscreen();
      } else if (de.mozRequestFullScreen) {
        de.mozRequestFullScreen();
      } else if (de.webkitRequestFullScreen) {
        de.webkitRequestFullScreen();
      }
    };
    // 退出全屏
    const exitFullscreen = () => {
      const de = document as any;
      if (de.exitFullscreen) {
        de.exitFullscreen();
      } else if (de.mozCancelFullScreen) {
        de.mozCancelFullScreen();
      } else if (de.webkitCancelFullScreen) {
        de.webkitCancelFullScreen();
      }
    };

    if (this.isFullscreen) {
      exitFullscreen();
    } else {
      requestFullScreen();
    }
    this.isFullscreen = !this.isFullscreen;
  }
  // 地图全屏切换
  mapOnSwitchFullscreen() {
    // 进入全屏
    const requestFullScreen = () => {
      const de = document.documentElement as any;
      if (de.requestFullscreen) {
        de.requestFullscreen();
      } else if (de.mozRequestFullScreen) {
        de.mozRequestFullScreen();
      } else if (de.webkitRequestFullScreen) {
        de.webkitRequestFullScreen();
      }
    };
    // 退出全屏
    const exitFullscreen = () => {
      const de = document as any;
      if (de.exitFullscreen) {
        de.exitFullscreen();
      } else if (de.mozCancelFullScreen) {
        de.mozCancelFullScreen();
      } else if (de.webkitCancelFullScreen) {
        de.webkitCancelFullScreen();
      }
    };

    if (this.mapisFullscreen) {
      exitFullscreen();
    } else {
      requestFullScreen();
    }
    this.mapisFullscreen = !this.mapisFullscreen;
  }
}
