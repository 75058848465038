import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { _HttpClient } from '@delon/theme';
import { NzMessageService } from 'ng-zorro-antd';
import * as moment from 'moment';

@Component({
  selector: 'download-excel',
  templateUrl: './download-excel.component.html',
  styleUrls: ['./download-excel.component.less']
})
export class DownloadExcelComponent implements OnInit {
  //导出地址
  @Input() url = '';
  //文件名(不含扩展名)
  @Input() fileName = '导出文件';
  //查询参数
  @Input() searchParams: any = {};
  //风险类型
  @Input() type = '';
  //权限代码
  @Input() aclcode = true;
  //按钮名称
  @Input() buttonName: string = '';

  loading = false;

  constructor(private message: NzMessageService, private http: _HttpClient) { }

  ngOnInit() {
  }

  /**
   * 导出excel
   */
  downloadExcel() {
    console.log(this.searchParams);
    let searchParams = {};
    if (this.type == '') {
      searchParams = {
        ...this.searchParams.searchParams,
        ids: this.searchParams.ids
      }
    } else {
      searchParams = {
        ...this.searchParams.searchParams,
        ids: this.searchParams.ids,
        type: this.type,
      }
    }

    //删除为null的字段
    Object.entries(searchParams).forEach(([key, value]) => {
      if (value == null) {
        delete searchParams[key];
      }
    });
    this.loading = true;
    console.log(searchParams);
    this.http.get(`${this.url}`, searchParams, { responseType: 'blob', observe: 'response' }).subscribe((res: any) => {
      if (res.body.code == 1) {
        this.loading = false;
        this.message.error('导出不成功!');
        return;
      }

      try {
        const link = document.createElement('a');
        const blob = new Blob([<any>res.body]);
        let fileNmae = `${this.fileName}${moment().format('YYYYMMDDHHmmssSSS')}.xlsx`;
        // try { fileNmae = decodeURIComponent(res.headers.get('Content-Disposition').split('filename=')[1]) } catch (error) { }  //在有的浏览器中取不到响应头
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.setAttribute('download', fileNmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.message.error(error.toString());
      }

      this.loading = false;
    });
  }

}
