import { Injector } from '@angular/core';

export abstract class BaseCheckableTable {
  constructor(protected injector: Injector) {}
  loading = false;
  searchParams: any = {};
  total = 0;
  pageIndex = 1;
  pageSize = 20;

  // 全选
  rows = [];
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  mapOfCheckedId: { [key: string]: boolean } = {};
  numberOfChecked = 0;

  checkedIds: string[] = [];

  checkedRows = [];

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.rows.filter(item => !item.disabled).every(item => this.mapOfCheckedId[item.id]);

    this.isIndeterminate =
      this.rows.filter(item => !item.disabled).some(item => this.mapOfCheckedId[item.id]) &&
      !this.isAllDisplayDataChecked;

    this.numberOfChecked = this.rows.filter(item => this.mapOfCheckedId[item.id]).length;

    this.checkedIds = Object.entries(this.mapOfCheckedId)
      .filter(([id, checked]) => checked)
      .map(([id]) => id);

    this.rows.forEach(row => {
      const currentIndex = this.checkedRows.findIndex(v => v.id === row.id);
      if (this.mapOfCheckedId[row.id]) {
        // tslint:disable-next-line: no-unused-expression
        currentIndex === -1 && this.checkedRows.push(row);
      } else {
        // tslint:disable-next-line: no-unused-expression
        currentIndex !== -1 && this.checkedRows.splice(currentIndex, 1);
      }
    });

    this.checkedRows = [...this.checkedRows];
  }

  checkAll(value: boolean): void {
    this.rows.filter(item => !item.disabled).forEach(item => (this.mapOfCheckedId[item.id] = value));
    this.refreshStatus();
  }
}
