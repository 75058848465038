import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NzMessageService } from 'ng-zorro-antd';
import { CommonService, baseUrl } from '@core';

@Component({
  selector: 'app-poster',
  templateUrl: './poster.component.html',
  styleUrls: ['./poster.component.less'],
})
export class PosterComponent implements OnInit {
  position = 'bottomCenter';
  loading = false;
  businessType;
  keyword;
  editDialog = {
    display: false,
    loading: false,
    data: {} as any,
    form: FormGroup as any,
  };
  LogoList;
  LogoLists;
  activeClass;

  //
  logo = {
    loading: false,
    display: false,
  };
  imgType;
  posterView;
  viewImg = {
    display: false,
    loading: false,
    type: '',
  };
  logoPreview;
  bgPreview;

  previewUrl = '';
  //
  BgimgSelect = '';
  LogoimgSelect = '';
  //上传
  i: any = {
    fileList: [],
  };

  //logo 背景图展示
  viewLogo = '';
  viewBg = '';
  //是否选中 logo 和背景图
  isSelectBg = false;
  isSelectLogo = false;

  //海报列表数据
  posterData;

  //业务类型
  businessList = [
    { businessName: '豫交安注册', id: 1 },
    { businessName: '安全专家在线注册', id: 2 },
  ];
  constructor(
    private fb: FormBuilder,
    private message: NzMessageService,
    private http: HttpClient,
    public commonService: CommonService,
  ) {}

  ngOnInit() {
    this.search();
    this.initEditDialogForm();
    // this.getPosterLogo()
  }
  // 初始化 表单
  initEditDialogForm() {
    this.editDialog.form = this.fb.group({
      posterName: [null, [Validators.required]],
      businessType: [null, [Validators.required]],
      content: [null, [Validators.required]],
      address: [null],
      // logo: [null, [Validators.required]],
      // background: [null, [Validators.required]],
      remark: [null],
      inviteLanguage: [null, [Validators.required]],
    });
  }

  //获取海报列表
  search() {
    this.loading = true;
    this.http
      .get<any>(`${baseUrl}/poster-management?businessType=${this.businessType || ''}&keyword=${this.keyword || ''}`)
      .subscribe(res => {
        this.loading = false;
        if (res.code === 0) {
          this.posterData = res.data;
        }
      });
    this.loading = false;
  }

  //获取海报LOGo 背景图
  getPosterLogo(type = null) {
    this.logo.display = true;
    this.logo.loading = true;
    this.imgType = type;
    this.http.get<any>(`${baseUrl}/poster-management/background/storage?storageType=${type}`).subscribe(res => {
      this.logo.loading = false;
      if (res.code === 0) {
        if (res.data) {
          this.LogoLists = res.data;
          this.LogoList = res.data.map((file, i) => ({
            ...file,
            url: this.commonService.OssSignatureUrl(file.url),
          }));
        }
      }
    });
  }

  //编辑
  onOpenEditDialog(data = null) {
    this.isSelectLogo = false;
    this.isSelectBg = false;
    this.editDialog.display = true;
    if (data) {
      this.loading = true;
      this.editDialog.data = data;
      this.editDialog.form.reset();
      this.editDialog.form.enable();

      this.http.get<any>(`${baseUrl}/poster-management/${data.id}`).subscribe(res => {
        this.loading = false;
        if (res.code === 0) {
          // this.BgimgSelect = this.commonService.OssSignatureUrl(res.background);
          // this.LogoimgSelect = this.commonService.OssSignatureUrl(res.logo);
          this.BgimgSelect = res.data.background;
          this.LogoimgSelect = res.data.logo;
          this.viewLogo = this.commonService.OssSignatureUrl(this.LogoimgSelect);
          this.viewBg = this.commonService.OssSignatureUrl(this.BgimgSelect);
          this.editDialog.form.patchValue(res.data);
        }
      });

      // if (data) {
      //   let _data = {
      //     ...data,
      //   };
      //   this.editDialog.form.patchValue(_data);
      // }
    } else {
      this.editDialog.data = '';
      this.editDialog.form.reset();
      this.editDialog.form.enable();
      this.editDialog.form.patchValue('');
    }
  }

  //预览
  onView(data) {
    this.viewImg.display = true;
    this.viewImg.type = '1';
    if (data) {
      this.previewUrl = this.commonService.OssSignatureUrl(data.previewUrl);
    }
  }

  //创建表单添加
  save(view, id = null) {
    if (view == 2) {
      this.editDialog.display = false;
      this.LogoimgSelect = '';
      this.BgimgSelect = '';
    }
    this.commonService.formValid(this.editDialog.form);
    if (!this.editDialog.form.valid) {
      return;
    }
    let data = {
      ...this.editDialog.form.value,
      logo: this.LogoimgSelect,
      background: this.BgimgSelect,
    };
    if (view == 0) {
      if (this.LogoimgSelect == '') {
        this.isSelectLogo = true;
      } else {
        this.isSelectLogo = false;
      }
      if (this.BgimgSelect == '') {
        this.isSelectBg = true;
      } else {
        this.isSelectBg = false;
      }
      this.viewImg.loading = true;
      this.http.post<any>(`${baseUrl}/poster-management/detail/preview`, data).subscribe(res => {
        this.viewImg.loading = false;
        if (res.code === 0) {
          this.previewUrl = '';
          this.posterView = res.data;
          this.viewImg.display = true;
          this.viewImg.type = '2';
        }
      });
    }
    if (view == 1) {
      if (this.LogoimgSelect == '') {
        this.isSelectLogo = true;
      } else {
        this.isSelectLogo = false;
      }
      if (this.BgimgSelect == '') {
        this.isSelectBg = true;
      } else {
        this.isSelectBg = false;
      }
      if (id != null) {
        this.logo.loading = true;
        let _data = {
          ...data,
          id: id.id,
        };
        this.http.post<any>(`${baseUrl}/poster-management/detail/generate`, _data).subscribe(res => {
          this.logo.loading = false;
          if (res.code === 0) {
            this.search();
            this.message.success('制作成功！');
            this.editDialog.display = false;
          }
        });
      } else {
        this.logo.loading = true;
        this.http.post<any>(`${baseUrl}/poster-management/detail/generate`, data).subscribe(res => {
          this.logo.loading = false;
          if (res.code === 0) {
            this.search();
            this.message.success('制作成功！');
            this.editDialog.display = false;
          }
        });
      }
    }
  }
  /**
   * 文件上传
   */
  beforeUpload = (file): boolean => {
    let OSS = this.commonService.OSS;
    let path = this.commonService.publicPath + this.commonService.getFileType(file);
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        //加载图片获取图片真实宽度和高度
        var image = new Image();
        let obj: any = (e.target as any).result;
        image.src = obj;
        image.onload = () => {
          var width = image.width; //图片的宽
          var height = image.height; //图片的高
          if (width == 1440 && height == 2060) {
            OSS.put(path, file)
              .then(res => {
                // const fileFullPath = this.commonService.OssNoSignatureUrl(path);
                this.i.fileList = [
                  {
                    status: 'done',
                    name: file.name,
                    size: file.size / 1024 / 1024,
                    url: path,
                    urlPath: path,
                  },
                ];
                if (this.i.fileList[0].size < 5) {
                  let data = {
                    storageType: 2,
                    storageUrl: this.i.fileList[0].url || '',
                  };
                  this.http.post<any>(`${baseUrl}/poster-management/background/storage`, data).subscribe(res => {
                    this.logo.loading = false;
                    if (res.code === 0) {
                      this.message.success('上传成功');
                      this.BgimgSelect = data.storageUrl;
                      this.viewBg = this.commonService.OssSignatureUrl(data.storageUrl);
                    } else {
                      this.message.error('上传失败');
                    }
                  });
                } else {
                  this.message.error('上传的图片大小，请小于5M');
                }
              })
              .catch(() => {
                this.message.error('文件上传失败!');
              });
          } else {
            this.message.error('上传的图片尺寸，必须为1440*2060');
          }
        };
      };
    }
    return false;
  };
  logoOk(type) {
    this.logo.display = false;
    if (type == 1) {
      this.LogoimgSelect = this.logoPreview;
      this.viewLogo = this.commonService.OssSignatureUrl(this.logoPreview);
    } else {
      this.BgimgSelect = this.bgPreview;
      this.viewBg = this.commonService.OssSignatureUrl(this.BgimgSelect);
    }
  }

  imgSelect(data, i) {
    this.activeClass = i;
    if (data == 1) {
      this.logoPreview = this.LogoLists[i].url;
    } else {
      this.bgPreview = this.LogoLists[i].url;
    }
  }
  onCancel(clr) {
    this.logo.display = false;
    if (this.editDialog.data) {
      return;
    } else {
      if (clr == 1) {
        if (this.logoPreview) {
          return;
        } else {
          this.LogoimgSelect = '';
        }
      } else if (clr == 2) {
        if (this.bgPreview) {
          return;
        } else {
          this.BgimgSelect = '';
        }
      }
    }
  }
  editCancel() {
    this.editDialog.display = false;
    this.LogoimgSelect = '';
    this.BgimgSelect = '';
  }
}
