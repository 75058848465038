import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';

@Component({
  selector: 'thead[x-thead]',
  templateUrl: './x-thead.component.html',
  styleUrls: ['./x-thead.component.scss']
})
export class XTheadComponent implements OnInit {
  constructor(public elementRef: ElementRef) { }

  ngOnInit() {
    // setTimeout(() => {
    //   console.log(this.elementRef.nativeElement);
    // }, 0);
  }

  ngAfterContentInit() {
    // console.log(this.elementRef.nativeElement);
  }

  ngAfterContentChecked() {
    // setTimeout(() => {
    //   console.log(this.elementRef.nativeElement);
    // }, 0);
  }

  ngAfterViewChecked() {
    // console.log(this.elementRef.nativeElement);
  }

}
