import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { HttpClient } from '@angular/common/http';
import { _HttpClient } from '@delon/theme';
import { baseUrl as _baseUrl } from '@env/environment';
// import { CommonService, baseUrl ,MessageService} from '@core';
import { CommonService, baseUrl, baseUrl3 } from '@core';
import { STComponent, STColumn, STData, STChange, STPage } from '@delon/abc';
import { environment, baseUrl as __baseUrl } from '../../../environments/environment';
import { GPositionComponent } from '../application/g-position/g-position.component';

export interface TreeNodeInterface {
  code: string;
  deptName: string;
  type: number;
  personNum: number;
  expand: boolean;
  principalName: string;
  tel: string;
  children?: TreeNodeInterface[];
}
@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.less'],
})
export class ApplicationComponent implements OnInit {
  baseUrl = baseUrl;
  checked = false;
  rows = [];
  rows_see = [];
  rowsRisk = [];
  searchRiskId;
  total = 0;
  pageIndex = 1;
  pageSize = 20;
  params: any = {
    status: '',
    industry: '',
    province: '',
    city: '',
    district: '',
    town: '',
    keyWords: '',
  };
  riskData: any = {
    total: 0,
    pageIndex: 1,
    pageSize: 20,
  };
  loading = false;
  isLoading = false;
  // @ViewChild('XTable', { static: true }) XTable = null;
  // @ViewChild('btnTemp', { static: true }) btnTemp = null;
  @ViewChild('st', { static: true })
  //部门
  department: any = {
    display: false,
    loading: false,
  };
  personnel: any = {
    display: false,
    loading: true,
  };
  position: any = {
    display: false,
    loading: true,
  };
  risk: any = {
    display: false,
    loading: true,
  };
  license: any = {
    display: false,
    loading: true,
  };

  listOfMapData = [];
  mapOfExpandedData: { [key: string]: TreeNodeInterface[] } = {};
  //展开的节点
  expandIdMap = {}; //{ [id]: true }

  // 省市区
  provinceList = [];
  cityList = [];
  townList = [];
  districtList = [];
  // 全选
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  mapOfCheckedId: { [key: string]: boolean } = {};
  numberOfChecked = 0;

  // 当前选中的节点
  selectNode: any = {};
  searchParams: any = {};
  // checkIds = [];
  searchParamsRisk: any = {};
  equipmentTypes = [];

  // 选中行的 id 列表
  checkedIds = [];

  pages: STPage = {
    total: false, //分页显示多少条数据，字符串型
    show: false, //显示分页
    front: false, //关闭前端分页，true是前端分页，false后端控制分页,
    showSize: true,
  };

  //
  isView = true;

  constructor(
    private modalService: NzModalService,
    private fb: FormBuilder,
    private message: NzMessageService,
    private http: HttpClient,
    public commonService: CommonService, // public msg: MessageService,
  ) {
    // 加载省
    this.commonService.getAddress('province', '0').subscribe(list => (this.provinceList = list));
  }
  editDialog = {
    display: false,
    loading: false,
    data: {} as any,
    // form: null as FormGroup,
    // 是否查看
    isView: false,
    auditStatus: '',
    text: '',
    form: null as FormGroup,
    type: false
  };
  preViews = {
    display: false,
    loading: false,
  };

  fileList = [];
  showUploadList = {
    showPreviewIcon: true,
    showRemoveIcon: true,
    hidePreviewIconInNonImage: true,
  };
  filePath;
  chakan = '查看';
  // 管控层级
  controlHierarchyList = [];
  get controlHierarchyMap() {
    let obj = {};
    this.controlHierarchyList.forEach(item => (obj[item.value] = item.label));
    return obj;
  }

  ngOnInit() {
    this.search(true);
    this.getIsAuto();
    this.initEditDialogForm();
  }
  initEditDialogForm() {
    this.editDialog.form = this.fb.group({
      auditStatus: [null],
      memo: [null],
    });
  }

  //获取管控层级列表
  getList(id) {
    this.http
      .get<any>(`${__baseUrl}/admin/v1/organization/custom?company=${id}`, { headers: { token: '' } })
      .subscribe(res => {
        if (res.code == 0) {
          res.data.forEach(item => {
            let data = { label: item.customTypeName, value: item.controlLevel };
            this.controlHierarchyList.push(data);
          });
        } else {
          this.message.error(res.msg || '加载失败');
        }
      });
  }
  /**
   * 地址选择
   * @param next = { dataField: 数据源, type: 类型 }
   * @param code
   */
  addressChange(next, code, type = false) {
    this.commonService.getAddress(next.type, code).subscribe(list => (this[next.dataField] = list));
  }

  refreshStatus(e: any): void {
    this.isAllDisplayDataChecked = this.rows
      .filter(item => !item.disabled)
      .every(item => this.mapOfCheckedId[item.companyId]);

    this.isIndeterminate =
      this.rows.filter(item => !item.disabled).some(item => this.mapOfCheckedId[item.companyId]) &&
      !this.isAllDisplayDataChecked;

    this.numberOfChecked = this.rows.filter(item => this.mapOfCheckedId[item.companyId]).length;

    this.checkedIds = Object.entries(this.mapOfCheckedId)
      .filter(([id, checked]) => checked)
      .map(([id]) => id);
    console.log(this.checkedIds);
  }

  checkAll(value: boolean): void {
    this.rows.filter(item => !item.disabled).forEach(item => (this.mapOfCheckedId[item.companyId] = value));
    this.refreshStatus('');
  }

  // 人员信息数据

  st: STComponent;
  q: any = {
    pi: 1,
    ps: 10,
    sorter: '',
    q: '',
    total: 0,
  };
  data: any[] = [];
  ploading = false;
  columns: STColumn[] = [
    // { title: '', index: 'userId', type: 'checkbox', width: '50px' },
    { title: '序号', type: 'no', width: '50px' },
    {
      title: '真实姓名',
      index: 'username',
      format: (item: any) => `${item.username}`,
      sorter: (a: any, b: any) => a.username != b.username,
      width: '100px',
      className: 'nohh',
    },
    { title: '手机号', index: 'mobile', width: '150px', className: 'nohh' },
    { title: '部门', index: 'deptName', width: '200px', className: 'nohh' },
    { title: '职位', index: 'postName', width: '300px', className: 'nohh' },
    { title: '岗位', index: 'jobName', className: 'nohh' },
    { title: '角色', index: 'roleName', width: '150px', className: 'nohh' },
  ];

  // 人员信息数据
  getPersonData(reset: boolean = false, id) {
    if (reset) {
      this.q.pi = 1;
    }
    // const searchParams = {
    //   ...this.searchParams,
    //   deptCode: this.selectNode.key,
    //   current: this.q.pi,
    //   size: this.q.ps,
    // };
    this.ploading = true;
    this.http
      .get<any>(`${__baseUrl}/admin/v1/users?company=${id}&current=1&size=99999`, { headers: { token: '' } })
      .subscribe((res: any) => {
        this.ploading = false;
        console.log(res);
        if (res.code == 0) {
          res.data.records.forEach(e => {
            e.frz = false;
            const deptName = [];
            const jobName = [];
            const postName = [];
            const roleName = [];
            const jobId = [];
            const roleId = [];
            // 循环遍历部门职位岗位
            e.jobList.forEach(f => {
              deptName.push(f.deptName);
              jobName.push(f.jobName);
              postName.push(f.postName);
              jobId.push(f.id);
              if (f.principal == e.userId) {
                e.frz = true;
              }
            });
            e.deptName = deptName.join(';');
            e.jobName = jobName.join(';');
            e.jobId = jobId;
            e.postName = postName.join(';');
            // 循环遍历角色
            e.roleList.forEach(r => {
              roleName.push(r.roleName);
              roleId.push(r.roleId);
            });
            e.roleName = roleName.join(';');
            e.roleId = roleId;
          });
          this.data = res.data.records || [];
          this.q.total = res.data.total || 0;
        } else {
          this.message.error(res.msg || '加载失败');
        }
      });
  }

  //设施设备
  getEquipment(id) {
    this.http
      .get<any>(`${baseUrl3}/risk/equipment/types?company=${id}`, { headers: { token: '' } })
      .subscribe((res: any) => {
        if (res.code == 0) {
          this.equipmentTypes = res.data.content || [];
        } else {
          this.message.error(res.msg || '加载失败');
        }
      });
  }
  searchRisk(reset: boolean = false, id) {
    if (reset) {
      this.pageIndex = 1;
    }

    let params = {
      ...this.searchParamsRisk,
      company: id,
      // adminBranchInnerCode: this.selectNode.key,
      page: this.pageIndex,
      limit: this.pageSize,
    };

    params.equipmentTypeId == null && delete params.equipmentTypeId;
    console.log(params);
    this.loading = true;
    this.http
      .get<any>(`${baseUrl3}/risk/equipments`, { params: params, headers: { token: '' } })
      .subscribe((res: any) => {
        this.loading = false;
        if (res.code == 0) {
          this.rowsRisk = res.data.content || [];
          this.total = res.data.totalElements || 0;
        } else {
          this.message.error(res.msg || '加载失败');
        }
      });
  }

  getData(isAllExpend = 0, id) {
    // 加载前记录被展开的节点
    this.expandIdMap = {};
    if (this.listOfMapData.length) {
      (this.mapOfExpandedData[this.listOfMapData[0].code] || []).forEach(node => {
        if (node.expand) {
          this.expandIdMap[node['id']] = true;
        }
      });
    }
    this.loading = true;
    this.http
      .get<any>(`${__baseUrl}/admin/v1/depts?company=${id}`, { headers: { token: '' } })
      .subscribe(res => {
        this.loading = false;
        if (res.code == 0) {
          this.listOfMapData = res.data || [];
          // 初始化单选按钮选中值
          // this.radioValue = this.listOfMapData[0].code;
          this.listOfMapData.forEach(item => {
            this.mapOfExpandedData[item.code] = isAllExpend
              ? this.convertTreeAllToList(item)
              : this.convertTreeToList(item);

            this.mapOfExpandedData[item.code].forEach((i: any) => {
              if (JSON.stringify(i.children) == '[]') {
                i.children = false;
              }
            });
          });

          // 加载后还原加载前的节点
          (this.mapOfExpandedData[this.listOfMapData[0].code] || []).forEach(node => {
            if (this.expandIdMap[node['id']]) {
              node.expand = true;
            }
          });
        } else {
          this.message.error(res.msg || '加载失败');
        }
      });
  }

  collapse(array: TreeNodeInterface[], data: TreeNodeInterface, $event: boolean): void {
    if ($event === false) {
      if (data.children) {
        data.children.forEach(d => {
          const target = array.find(a => a.code === d.code)!;
          target.expand = false;
          this.collapse(array, target, false);
        });
      } else {
        return;
      }
    }
  }
  /**
   *
   * @param root 部分展开
   */
  convertTreeToList(root: object): TreeNodeInterface[] {
    const stack: any[] = [];
    const array: any[] = [];
    const hashMap = {};
    stack.push({ ...root, level: 0, expand: true });
    while (stack.length !== 0) {
      const node = stack.pop();
      this.visitNode(node, hashMap, array);
      if (node.children) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          stack.push({ ...node.children[i], level: node.level + 1, expand: false, parent: node });
        }
      }
    }

    return array;
  }
  /**
   *
   * @param root 全部展开
   */
  convertTreeAllToList(root: object): TreeNodeInterface[] {
    const stack: any[] = [];
    const array: any[] = [];
    const hashMap = {};
    stack.push({ ...root, level: 0, expand: true });
    while (stack.length !== 0) {
      const node = stack.pop();
      this.visitNode(node, hashMap, array);
      if (node.children) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          stack.push({ ...node.children[i], level: node.level + 1, expand: true, parent: node });
        }
      }
    }

    return array;
  }
  visitNode(node: TreeNodeInterface, hashMap: { [code: string]: any }, array: TreeNodeInterface[]): void {
    if (!hashMap[node.code]) {
      hashMap[node.code] = true;
      array.push(node);
    }
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    let Params = {
      ...this.params,
    };
    if (Params.status == null) {
      Params.status = '';
    }
    if (Params.city == null) {
      Params.city = '';
    }
    if (Params.district == null) {
      Params.district = '';
    }
    if (Params.province == null) {
      Params.province = '';
    }
    if (Params.town == null) {
      Params.town = '';
    }
    if (Params.industry == null) {
      Params.industry = '';
    }

    console.log(Params, '11111');
    this.loading = true;
    this.http
      .get<any>(
        `${baseUrl}/sysAudit?status=${Params.status}&industry=${Params.industry}&city=${Params.city}&district=${Params.district}&province=${Params.province}&street=${Params.town}&fullName=${Params.keyWords}`,
      )
      .subscribe((res: any) => {
        // this.http.get<any>(`${baseUrl}/sysAudit`, params).subscribe((res: any) => {
        this.loading = false;
        console.log(res);
        if (res.code == 0) {
          this.rows = res.data || [];
        } else {
          this.message.error('加载失败');
        }
      });
  }

  /**
   * 打开查看、审核对话框
   */
  onOpenExamine(data = null, type) {
    this.editDialog.display = true;
    this.editDialog.data = data;
    this.editDialog.type = type;
    this.editDialog.form.reset();
    // if (!type) {
    //   this.editDialog.data.memo = '';
    //   this.editDialog.data.auditStatus = '';
    // }
    if (data) {
      const _data = {
        ...data,
      };
      this.editDialog.form.patchValue(_data);
    }

  }

  /**
   * 审核对话框提交
   */
  onSubmitEditDialog(kkdata = null) {
    console.log(kkdata, 111111);
    let ids = [];
    if (kkdata) {
      ids.push(kkdata.companyId);
    } else {
      ids = this.checkedIds
    }
    this.commonService.formValid(this.editDialog.form);

    if (!this.editDialog.form.valid) {
      return;
    }

    let data1 = {
      ...this.editDialog.form.value
    };
    if (data1.auditStatus && data1.memo) {
      let searchParams = {
        companyIds: ids,
        ...data1
      };
      this.loading = true;
      console.log(searchParams, 666666);
      this.http.post<any>(`${baseUrl}/sysAudit/audit`, searchParams).subscribe((res: any) => {
        this.loading = false;

        if (res.code == 0) {
          this.message.success(res.msg);
          this.editDialog.display = false;
          this.isView = true;
          this.checkedIds = [];
          this.editDialog.form.patchValue({});
          this.search();
        } else {
          this.message.error(res.msg || '审核失败');
        }
      });
    } else {
      this.message.error('请填写备注，以及审核是否通过');
    }
  }

  /**
   * 批量审核
   */
  openBatchExamine() {
    if (this.checkedIds.length == 0) {
      this.message.error('请选中所要审核企业');
      return;
    }
    this.editDialog.form.reset();
    this.editDialog.data = null;
    this.editDialog.type = false;
    this.editDialog.display = true;
    this.isView = false;
  }

  /**
   * 查看部门信息
   */
  openDepartment(data) {
    this.getList(data.companyId);
    this.getData(0, data.companyId);
    this.department.display = true;
  }

  /**
   * 查看人员信息
   */
  openPersonnel(data) {
    this.getPersonData(true, data.companyId);
    this.personnel.display = true;
  }

  /**
   * 查看地理信息位置
   */
  openPosition(data) {
    // this.position.display = true;
    // console.log(999999999999)
    let dataId = data.companyId;
    const modal = this.modalService.create({
      nzTitle: '地理位置信息',
      nzWidth: '1600',
      nzContent: GPositionComponent,
      nzMaskClosable: false,
      nzComponentParams: {
        positionData: dataId,
      },
      nzFooter: null,
    });
  }

  /**
   * 查看风险点信息
   */
  openRisk(data) {
    this.getEquipment(data.companyId);
    this.searchRisk(true, data.companyId);
    this.searchRiskId = data.companyId;
    this.risk.display = true;
  }

  /**
   * 查看营业执照
   */
  openLicense(data) {
    this.license.display = true;
    console.log(data);
    if (data) {
      data.forEach(item => {
        let arr = item.fileName.split('.');
        item.type = arr.pop();
        item.urlPath = this.commonService.OssSignatureUrl(item.urlPath);
      });
      this.rows_see = data;
    }
  }

  /**
   * 关闭添加窗口
   */
  handleCancel() {
    this.editDialog.display = false;
    this.preViews.display = false;
    this.fileList = [];
    this.isView = true;
    // this.search();
  }
  handleOk() {
    this.preViews.display = false;
    this.isView = true;
  }
  isOpen() {
    this.department.display = false;
    this.personnel.display = false;
    this.position.display = false;
    this.risk.display = false;
    this.license.display = false;
  }
  isClose() {
    this.personnel.display = false;
    this.department.display = false;
    this.position.display = false;
    this.risk.display = false;
    this.license.display = false;
  }
  /**
   * 获取自动审核状态
   * @param checked
   */
  getIsAuto() {
    this.loading = true;
    this.http.get<any>(`${baseUrl}/sysAudit/status`).subscribe((res: any) => {
      this.loading = false;
      if (res.code == 0) {
        this.checked = !!Number(res.data);
      }
    });
  }

  /**
   * 设置系统自动审核
   */
  closeSys(checked) {
    console.log(Number(checked));
    let status = Number(checked);
    this.loading = true;
    this.http.put<any>(`${baseUrl}/sysAudit/set/${status}`, {}).subscribe((res: any) => {
      this.loading = false;
      if (res.code == 0) {
        this.message.success(res.msg);
      } else {
        this.message.error('关闭失败');
      }
    });
  }
}
