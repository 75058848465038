import { Component, OnInit, ViewChild } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';

import { _HttpClient } from '@delon/theme';
import { CommonService, baseUrl } from '@core';

import { delay, tap } from 'rxjs/operators';
/**
 * 后端: 鑫安利-大脸
 */

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less'],
})
export class DashboardComponent implements OnInit {
  loading = false;
  isSpinning = false;

  // 卡片数据
  cardData: any = {};
  // 企业地区统计
  areaEchart = null;
  // 企业所属行业统计
  businessEchart = null;
  // 企业所属运营中心统计
  opEchart = null;
  // 企业来源渠道统计
  sourceEchart = null;

  businessData: any = {};
  channelData: any = {};
  companyData: any = {};
  userData: any = {};

  channelDataLoading = true;
  businessDataLoading = true;
  userDataLoading = true;
  companyDataLoading = true;

  constructor(private http: _HttpClient, public commonService: CommonService, public msg: NzMessageService) {}

  ngOnInit() {
    // this.loadCardList();
    this.loadArea();
    this.loadBusiness();
    this.loadOp();
    this.loadSource();
    this.loadBusinessData();
    this.loadChannelData();
    this.loadCompanyData();
    this.loadUserData();
  }

  /**
   * 加载 业务数据统计
   */
  loadBusinessData() {
    this.http.get<any>(`${baseUrl}/home/business`).subscribe(res => {
      this.businessDataLoading = false;
      if (res.code == 0) {
        this.businessData = res.data || {};
      }
    });
  }

  /**
   * 加载 个人用户统计
   */
  loadUserData() {
    this.http.get<any>(`${baseUrl}/home/userStatistics`).subscribe(res => {
      this.userDataLoading = false;
      if (res.code == 0) {
        this.userData = res.data || {};
      }
    });
  }

  /**
   * 加载 企业客户统计
   */
  loadCompanyData() {
    this.http.get<any>(`${baseUrl}/home/corporateClient`).subscribe(res => {
      this.companyDataLoading = false;
      if (res.code == 0) {
        this.companyData = res.data || {};
      }
    });
  }

  /**
   * 加载 平台渠道用户统计
   */
  loadChannelData() {
    this.http.get<any>(`${baseUrl}/home/channelUser`).subscribe(res => {
      this.channelDataLoading = false;
      if (res.code == 0) {
        this.channelData = res.data || {};
      }
    });
  }

  /**
   * 加载 卡片数据
   */
  // loadCardList() {
  //   this.loading = true;
  //   this.http.get<any>(`${baseUrl}/home/enterprise`).subscribe(res => {
  //     this.loading = false;
  //     if (res.code == 0) {
  //       this.cardData = res.data || {};
  //       this.cardData.sumData = Number(res.data.enterpriseNum) + Number(res.data.apCompanyNum) + 188 + 780;
  //     }
  //   });
  // }

  /**
   * 加载 企业地区统计
   */
  loadArea() {
    this.http.get<any>(`${baseUrl}/home/address`).subscribe(res => {
      if (res.code == 0) {
        this.http.get('./assets/map-data/china.json').subscribe(mpaData => {
          window['echarts'].registerMap('map', mpaData);

          // let data = (res.data || []).filter(v => v.groupName).map(v => {
          //   //去除返回数据的区域单位
          //   if (['省', '市'].includes(v.groupName[v.groupName.length - 1])) {
          //     v.groupName = v.groupName.slice(0, -1);
          //   }
          //   v.name = v.groupName;
          //   v.value = v.num;
          //   return v;
          // });

          const data = [];
          (res.data || []).filter(item => {
            if (item.groupName) {
              const properties = (mpaData.features.find(v => v.id == item.groupName) || {}).properties;
              if (properties) {
                data.push({
                  name: properties.name,
                  value: item.num,
                });
              }
            }
          });
          // let data = (res.data || []).filter(item => item.groupName).map(item => ({
          //   name: (mpaData.features.find(v => v.id == item.groupName) || {}).properties.name,
          //   value: item.num
          // }));

          this.areaEchart = {
            tooltip: {},
            series: [
              {
                type: 'map',
                map: 'map',
                roam: true,
                tooltip: {
                  formatter: data => {
                    return data.value ? `${data.name}: ${data.value}` : '';
                  },
                },
                label: {
                  formatter: obj => {
                    if (obj.data && obj.data.value) {
                      return obj.name + `(${obj.data.value})`;
                    }
                    return '';
                  },
                  show: true,
                  color: 'red',
                },
                emphasis: {
                  label: { formatter: obj => obj.name },
                },
                data,
              },
            ],
          };
        });
      }
    });
  }

  /**
   * 加载 企业所属行业统计
   */
  loadBusiness() {
    this.http.get<any>(`${baseUrl}/home/industry`).subscribe(res => {
      if (res.code == 0) {
        let data = res.data || [];
        data = data.filter(item => item.groupName);
        data.sort((a, b) => b.num - a.num);
        this.businessEchart = {
          grid: {
            left: 0,
            right: 0,
            containLabel: true,
            bottom: 45,
            top: 40,
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985',
              },
            },
          },
          xAxis: {
            type: 'category',
            data: data.map(v => v.groupName),
          },
          yAxis: {
            type: 'value',
            name: '               企业数量',
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          series: [
            {
              data: data.map(v => v.num || 0),
              type: 'bar',
              barMaxWidth: '50',
              label: {
                normal: {
                  show: true,
                  position: 'top',
                },
              },
            },
          ],
        };
      }
    });
  }

  /**
   * 加载 企业所属运营中心统计
   */
  loadOp() {
    this.http.get<any>(`${baseUrl}/home/operation`).subscribe(res => {
      if (res.code == 0) {
        const data = res.data || [];
        data.sort((a, b) => b.num - a.num);
        this.opEchart = {
          grid: {
            left: 0,
            right: 0,
            containLabel: true,
            bottom: 45,
            top: 40,
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985',
              },
            },
          },
          xAxis: {
            type: 'category',
            data: data.map(v => v.groupName),
          },
          yAxis: {
            type: 'value',
            name: '               企业数量',
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          series: [
            {
              data: data.map(v => v.num || 0),
              type: 'bar',
              barMaxWidth: '50',
              label: {
                normal: {
                  show: true,
                  position: 'top',
                },
              },
            },
          ],
        };
      }
    });
  }

  /**
   * 加载 企业来源渠道统计
   */
  loadSource() {
    this.http.get<any>(`${baseUrl}/home/channel`).subscribe(res => {
      if (res.code == 0) {
        const data = res.data || [];
        data.sort((a, b) => b.num - a.num);
        this.sourceEchart = {
          grid: {
            left: 0,
            right: 0,
            containLabel: true,
            bottom: 45,
            top: 40,
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985',
              },
            },
          },
          xAxis: {
            type: 'category',
            data: data.map(v => v.groupName),
          },
          yAxis: {
            type: 'value',
            name: '               企业数量',
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          series: [
            {
              data: data.map(v => v.num || 0),
              type: 'bar',
              barMaxWidth: '50',
              label: {
                normal: {
                  show: true,
                  position: 'top',
                },
              },
            },
          ],
        };
      }
    });
  }
}
