import { AfterViewInit, Component, OnInit, ViewChild, Input } from '@angular/core';
import { NzFormatEmitEvent, NzTreeComponent, NzTreeNodeOptions, NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme'
import { CommonService, baseUrl } from '@core';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class TrialEditComponent implements OnInit {

  //行原始数据
  idata: any = {};

  //表单临时数据
  t: any = {
    status: '0'
  };

  constructor(private ref: NzModalRef,
    public msg: NzMessageService,
    public http: _HttpClient,
    private mod: ModalHelper, public commonService: CommonService) {
  }

  ngOnInit() {
    let data = { ...this.idata }
    this.t.id = data.id;
    this.t.remark = data.remark;
    this.t.handleMode = data.handleMode;
  }
  /**
    * 问题保存
    */
  save() {
    let params = {
      ...this.t,
      status: '1'
    }
    if (this.t.handleMode == null || this.t.handleMode == '') {
      this.msg.error('请选择处理方式');
      return;
    }
    this.http.post<any>(`${baseUrl}/probations/handle`, params).subscribe(res => {
      if (res.code == 0) {
        this.msg.success(res.msg || '处理成功');
        this.close(res);
      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });


  }

  close(res = null) {
    this.ref.destroy(res);
  }

}
