import { Injector } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { NzMessageService } from 'ng-zorro-antd';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IHttpResponse } from './base.interface';
import { domain } from '@core';

export type ApiAddress = 'platform/v1' | 'admin/v1' | 'cooperation/v1' | 'v1/insurance' | 'ahj/v1' | 'insurance/v1' | 'insurance/v2' | 'bi/v1';

export class ApiService {
  protected admin: ApiAddress = 'admin/v1';

  protected cooperation: ApiAddress = 'cooperation/v1';

  protected platform: ApiAddress = `platform/v1`;

  protected ahj: ApiAddress = 'ahj/v1';

  protected insuranceV1: ApiAddress = 'insurance/v1';

  protected insuranceV2: ApiAddress = 'insurance/v2';

  protected bi: ApiAddress = 'bi/v1';

  constructor(protected injector: Injector, protected http: _HttpClient, protected message: NzMessageService) { }

  protected completeUrl(apiAddress: ApiAddress, paths: string[]): string {
    return `${domain}/${apiAddress}/${paths.join('/')}`;
  }

  /**
   * @description Wrapped http get method
   */

  protected get<U>(url: string, params: { [key: string]: any }, options?): Observable<U> {
    return this.http.get<IHttpResponse<U>>(url, params, options).pipe(
      tap((res: any) => {
        // if (res.code !== 0) {
        //   this.message.error(res.msg || '加载失败');
        // }
        return res;
      }),
      map((res) => (res instanceof IHttpResponse ? res.data : res)),
    );
  }
  /**
   * @description Wrapped http get method
   */

  protected getexport<U>(url: string, params: { [key: string]: any }, options?): Observable<U> {
    return this.http.get<IHttpResponse<U>>(url, params, options).pipe(map((res) => (res instanceof IHttpResponse ? res.data : res)));
  }
  /**
   * @description Wrapped http post method
   */

  protected post<U>(url: string, params: { [key: string]: any }): Observable<U> {
    return this.http.post<IHttpResponse<U>>(url, params).pipe(
      tap((res) => {
        if (res.code !== 0) {
          // this.message.error(res.msg || '加载失败');
        } else {
          this.message.success(res.msg || '操作成功');
        }
        return res;
      }),
      map((res) => (res instanceof IHttpResponse ? res.data : res)),
    );
  }

  /**
   * @description Wrapped http delete method
   */
  protected delete<U>(url: string, params: { [key: string]: any }): Observable<U> {
    return this.http.delete(url, params).pipe(
      tap((res) => {
        const { code } = res;

        if (code === 0) {
          this.message.success(res.msg || '操作成功');
        } else {
          // this.message.error(res.msg || '操作失败');
        }
      }),
      map((res) => (res instanceof IHttpResponse ? res.data : res)),
    );
  }

  /**
   * @description Wrapped http put method
   */
  protected put<U>(url: string, params: { [key: string]: any }): Observable<U> {
    return this.http.put<IHttpResponse<U>>(url, params).pipe(
      tap((res) => {
        const { code } = res;
        if (code === 0) {
          this.message.success(res.msg || '操作成功');
        } else {
          // this.message.error(res.msg || '操作失败');
        }
      }),
      map((res) => (res instanceof IHttpResponse ? res.data : res)),
    );
  }
  protected export(apiAddress: ApiAddress, path: string[], fileName = new Date().toDateString()): void {
    this.http.get(this.completeUrl(apiAddress, path), { responseType: 'blob', observe: 'body' }).subscribe((res) => {
      if (res.code !== 0) {
        // this.message.error(res.msg || '加载失败');
      }
      return res;
    });
  }
}
