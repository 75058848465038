
import { Component, OnInit } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { baseUrl, CommonService } from '@core';
import { NzMessageService } from 'ng-zorro-antd';
import { format } from 'date-fns';

@Component({
  selector: 'app-dolog',
  templateUrl: './dolog.component.html',
  styleUrls: ['./dolog.component.less']
})
export class DologComponent implements OnInit {
  baseUrl = baseUrl;
  /**
   * table
   */
  loading = false;
  searchParams: any = {};
  rows = [];
  pageIndex = 1;
  total = 0;
  pageSize = 10;
  tableHeight = '';
  constructor(
    private http: _HttpClient,
    private msg: NzMessageService,
    public commonService: CommonService) {
    this.tableHeight = `${document.body.getBoundingClientRect().height - 195 - 30 - 60}px`;
  }

  ngOnInit() {
    this.search();
  }
  /**
   * 加载数据
   * @param reset 
   */
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    if (this.searchParams.accTime && (this.searchParams.accTime.length != 0)) {
      this.searchParams.startTime = format(this.searchParams.accTime[0], 'YYYY-MM-DD 00:00:00');
      this.searchParams.endTime = format(this.searchParams.accTime[1], 'YYYY-MM-DD 23:59:59');

    } else {
      this.searchParams.startTime = '';
      this.searchParams.endTime = '';
    }
    let searchParams = {
      ...this.searchParams,
      current: this.pageIndex,
      size: this.pageSize
    };
    delete searchParams.accTime;
    this.loading = true;
    this.http.get<any>(`${baseUrl}/logs`, searchParams).subscribe(res => {
      this.loading = false;
      if (res.code == 0) {
        this.rows = res.data.records || [];
        this.total = res.data.total || 0;
      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });
  }

}
