/**
 * 接口地址
 */

import { environment, baseUrl as _baseUrl } from '../../environments/environment';

export let domain = _baseUrl;
export let baseUrl = `${_baseUrl}/platform/v1`;
export let baseUrl2 = `${_baseUrl}/platform/v2`;
export let baseUrl3 = `${_baseUrl}/hazard/v1`;
export let riskUrl = `${_baseUrl}/risk/v1`;
export let BiUrl = `${_baseUrl}/bi/v1`;
export let uploadUrlAliyuncs = `https://oss-cn-beijing.aliyuncs.com?accessKey=LTAIzpfbqAanWh30&secretKey=w91asl9vXFUC0Xsf9cDI2MJ0DvXM41&bucketName=testxaloss`;
export let logUrl = `http://bugly.cn-beijing.log.aliyuncs.com/logstores/web_bug_log/track?APIVersion=0.6.0`;

// 文件上传地址
export let uploadUrl = `${_baseUrl}/basics/v1/file`;
/*模块列表*/
export let moduleUrl = `${baseUrl}/application/list`;
/*模块列表*/
export let moduleByIDUrl = `${baseUrl}/application`;
// 模块添加
export let moduleAddUrl = `${baseUrl}/application/save`;
// 模块编辑
export let moduleEditUrl = `${baseUrl}/application/updateById`;
// 模块删除
export let moduleDelUrl = `${baseUrl}/application`;
// 菜单列表
export let menusUrl = `${baseUrl}/sysMenu/list`;
// 菜单新增
export let menusAddUrl = `${baseUrl}/sysMenu/save`;
// 菜单编辑
export let menusEditUrl = `${baseUrl}/sysMenu/updateById`;
// 菜单删除
export let menusDeleteUrl = `${baseUrl}/sysMenu`;
// 常见问题列表
export let problemList = `${baseUrl}/question/list`;
// 常见问题添加
export let problemAdd = `${baseUrl}/question`;
// 常见问题编辑
export let problemEdit = `${baseUrl}/question`;
// 常见问题删除
export let problemDel = `${baseUrl}/question`;
// 常见问题反馈
export let problemfeedback = `${baseUrl}/question/feedbacks`;
// 资料下载列表
export let sourceList = `${baseUrl}/datum/list`;
// 删除资料
export let sourceDel = `${baseUrl}/datum`;
/**
 * 风险数据库系统
 */
// 企业列表/设置企业授权/删除授权企业
export let riskDoUrl = `${riskUrl}/authorization`;
// 企业列表/设置企业授权/删除授权企业
export let riskExportUrl = `${riskUrl}/authorization/export`;
// 设置数据类型
export let getDataUrl = `${riskUrl}/authorization/type`;
// 设备设施风险点列表
export let getSysDataUrl = `${riskUrl}/info/equipment`;
// 设备设施风险点列表导入
export let sysDataImportUrl = `${riskUrl}/info/equipment/import`;
// 作业活动风险点列表
export let getactivityDataUrl = `${riskUrl}/info/activity`;
// 作业活动风险点列表到导入
export let activityDataImportUrl = `${riskUrl}/info/activity/import`;
// 工艺节点风险点列表
export let getcraftDataUrl = `${riskUrl}/info/craft`;
// 工艺节点风险点列表导入
export let craftDataImportUrl = `${riskUrl}/info/processnode/import`;
// 风险信息库指定风险信息导出
export let riskDataExportUrl = `${riskUrl}/info/export`;
// 风险点所属检查项
export let getSyscheckUrl = `${riskUrl}/info/item`;
// 新增风险点、编辑风险点及检查项，删除通用
export let addSyscheckUrl = `${riskUrl}/info`;
/**
 * 监管中心
 */
// 监管中心管理增删改查
export let contorlCURDUrl = `${baseUrl}/crmsupervisioncenter`;

/*风险数据库审核*/
// 风险库审核风险点列表
export let getcheckDataUrl = `${riskUrl}/audit`;
// 风险库审核检查项操作
export let checkCURDUrl = `${riskUrl}/audit/item`;
// 风险库审核检查项录入
export let checkLoggedUrl = `${riskUrl}/audit/logging`;
// 风险库审核检查项批量录入
export let checkBatchLoggedUrl = `${riskUrl}/audit/batch/logging`;
// 风险库审核检查项批量删除
export let checkBatchDelUrl = `${riskUrl}/audit/batch/item`;
/**
 * 风险信息数据库统计
 */
// 按对象类型统计
export let statisticObjectUrl = `${riskUrl}/statistics/object`;
// 按对象类型统计导出
export let statisticObjectExporttUrl = `${riskUrl}/statistics/object/export`;
// 按数据类型统计
export let statisticDataUrl = `${riskUrl}/statistics/data`;
// 按数据类型统计导出
export let statisticDataExporttUrl = `${riskUrl}/statistics/data/export`;
// 按行业统计
export let statisticIndustryUrl = `${riskUrl}/statistics/industry`;
// 按行业统计导出
export let statisticIndustryExporttUrl = `${riskUrl}/statistics/industry/export`;

/**
 * 直播
 */
// 添加直播计划
export let addschedules = `${baseUrl}/live/addschedule`;
// 直播列表
export let allschedules = `${baseUrl}/live/allschedules`;
// 取消直播
export let cancellive = `${baseUrl}/live/cancellive`;
// 获取录制的视频链接
export let getrecord = `${baseUrl}/live/getrecord`;
// 恢复直播
export let recoverylive = `${baseUrl}/live/recoverylive`;
// 删除直播
export let deletelive = `${baseUrl}/live/delete-schedule`;
// 根据id获取直播计划
export let schedule = `${baseUrl}/live/schedule`;
// 开始直播
export let startlive = `${baseUrl}/live/startlive`;
// 开始录制
export let startrecord = `${baseUrl}/live/startrecord`;
// 停止直播
export let stoplive = `${baseUrl}/live/stoplive`;
// 停止录制
export let stoprecord = `${baseUrl}/live/stoprecord`;
// 修改直播计划
export let updateschedule = `${baseUrl}/live/updateschedule`;
// 直播提醒
export let livereminder = `${baseUrl}/live/remind`;
/**
 * 视频
 */
// 添加视频/视频列表
export let addvideos = `${baseUrl}/live/upload-video`;
// 视频详情
export let getvideos = `${baseUrl}/live/getuploadvideo`;

// 运营中心/所属专家
export let expertUrl = `${baseUrl}/expert`;
// 服务计划
export let servicePlanUrl = `${baseUrl}/servicePlan`;

export let BZbaseUrl = environment.BZbaseUrl;
export let YHbaseUrl = environment.YHbaseUrl;
export let goToUrl = environment.goToUrl;
export class IHttpResponse<T = any> {
  code: number;
  requestId: string;
  data: T;
  msg?: string;
}

export interface ResponseWithPaginator<T = any> {
  current: number;
  pages: number;
  records: T;
  searchCount: boolean;
  size: number;
  total: number;
}

/**
 * 测试
 */
if (window.location.port == '4201') {
  // domain = 'http://123.14.75.50';
  // baseUrl = 'http://123.14.75.50:9001';
  // uploadUrl = 'http://123.14.75.50:9001/basics/file/upload';
  // riskUrl = 'http://123.14.75.50:9001/hazard/v1';
}
