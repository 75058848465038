import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzFormatEmitEvent, NzTreeComponent, NzTreeNodeOptions, NzMessageService, isEmpty } from 'ng-zorro-antd';
import { tap, map } from 'rxjs/operators';
import { baseUrl } from '@env/environment';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { CommonService, moduleUrl, menusUrl, moduleDelUrl, menusDeleteUrl } from '@core';
import { STComponent, STColumn, STData, STChange, STPage } from '@delon/abc';
import { ModuleeditComponent } from './edit/edit.component';
import { AddmenuComponent } from './addmenu/addmenu.component';

export interface TreeNodeInterface {
  code: string;
  name: string;
  key: string;
  title: string;
  type: number;
  jumpWay: string;
  expand: boolean;
  permission: string;
  icon: string;
  path: string;
  children?: TreeNodeInterface[];
}
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less']
})
export class MenuComponent implements OnInit {
  //模块数据
  modules = [];

  // 添加模块元数据
  moduleInfo: any = {
    id: '',
    name: ''
  };
  roleRadio: number = 0;
  companyName: string;
  // 菜单数据
  listOfMapData = [];
  mapOfExpandedData: { [key: string]: TreeNodeInterface[] } = {};
  // 菜单选中行 radio
  radioValue: string = null;
  // 模块类型
  mtype = this.route.snapshot.params.type;
  /**
   * tree
   */
  treeLoading = false;

  // 当前选中的节点
  selectNode: any = {};
  searchParams: any = {};
  loading = false;
  application: string = 'sys';

  // 右侧页面数据

  selectedRows: STData[] = [];

  constructor(
    public msg: NzMessageService,
    private http: _HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef, private modal: ModalHelper, public commonService: CommonService
  ) { }

  ngOnInit() {

    this.getList(this.route.snapshot.params.type);
  }

  collapse(array: TreeNodeInterface[], data: TreeNodeInterface, $event: boolean): void {
    if ($event === false) {
      if (data.children) {
        data.children.forEach(d => {
          const target = array.find(a => a.code === d.code)!;
          target.expand = false;
          this.collapse(array, target, false);
        });
      } else {
        return;
      }
    }
  }

  convertTreeToList(root: object): TreeNodeInterface[] {
    const stack: any[] = [];
    const array: any[] = [];
    const hashMap = {};
    stack.push({ ...root, level: 0, expand: true });

    while (stack.length !== 0) {
      const node = stack.pop();
      this.visitNode(node, hashMap, array);
      if (node.children) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          stack.push({ ...node.children[i], level: node.level + 1, expand: false, parent: node });
        }
      }
    }

    return array;
  }

  visitNode(node: TreeNodeInterface, hashMap: { [key: string]: any }, array: TreeNodeInterface[]): void {
    if (!hashMap[node.code]) {
      hashMap[node.code] = true;
      array.push(node);
    }
  }
  /**
   * 获取模块列表
   */
  getList(type) {
    this.http.get<any>(`${moduleUrl}`, { type }).subscribe(res => {
      if (res.code == 0) {
        this.modules = res.data || [];
        if (this.radioValue) {
          this.roleRadio = this.roleRadio;
        } else {
          //默认选择第一个模块
          if (JSON.stringify(this.modules) != '[]') {
            this.roleRadio = this.modules[0].id;
          }
          // 默认application 是第一个
          this.application = 'sys'
        }

        //this.defRadio(this.modules);
        this.getData(true);
      } else {
        this.msg.error(res.msg);
      }

    })
  }
  // 初始化单选列表
  // defRadio(value: any) {
  //   this.moduleInfo.id = this.roleRadio;
  //   this.moduleInfo.name = this.modules.filter(item => item.id == this.roleRadio)[0].name;
  // }

  roleChange() {
    this.searchParams.condition = this.modules.filter(item => item.id == this.roleRadio)[0].name;
    this.application = this.modules.filter(item => item.id == this.roleRadio)[0].logogram;
    // this.defRadio(this.modules);
    this.getData();
  }
  /**
   * 打开编辑模块模态框 
  */
  openEdit(type: any = '') {
    this.modal.create(ModuleeditComponent,
      { mtype: this.route.snapshot.params.type, roleRadio: this.roleRadio, type },
      { modalOptions: { nzMaskClosable: false } }
    ).subscribe(() => {
      this.getList(this.route.snapshot.params.type);
    });
  }
  /**
   * 模块删除
   */
  delete() {
    let params = {
      delName: this.modules.find(item => item.id == this.roleRadio).name,
    }
    this.http.delete(`${moduleDelUrl}/${this.roleRadio}`, params).subscribe((res) => {
      if (res.code == 0) {
        this.msg.success(res.msg);
        this.getList(this.route.snapshot.params.type);
      }

    });
  }
  /*
  *打开编辑菜单模态框 
  */
  openAddMenu(idata: any = {}, type) {
    const parmas = {
      idata,
      mtype: this.route.snapshot.params.type,
      type,
      listData: this.listOfMapData,
      roleRadio: this.roleRadio,
      application: this.application
    }
    this.modal.create(AddmenuComponent, { parmas },
      {
        size: 'lg',
        modalOptions: {
          nzMaskClosable: false,
        },
      }
    ).subscribe(res => {
      this.radioValue = res.applicationId;
      this.loading = true;
      this.getList(this.route.snapshot.params.type);
    });
  }


  /**
   * 获取菜单数据
   * @param reset 
   */
  getData(reset: boolean = false) {
    if (this.roleRadio == 0) {
      this.listOfMapData = [];
      return;
    }
    const searchParams = {
      ...this.searchParams,
      applicationId: this.roleRadio
    };


    this.loading = true;
    this.http.get<any>(`${menusUrl}`, searchParams).subscribe(res => {
      this.loading = false;
      if (res.code == 0) {
        this.listOfMapData = this.commonService.formatNodes((res.data || []), 'code', 'name');
        this.listOfMapData.forEach(item => {
          this.mapOfExpandedData[item.code] = this.convertTreeToList(item);
          this.mapOfExpandedData[item.code].forEach((i: any) => {
            if (i.children.length == 0) {
              i.children = false;
            }
            if (i.icon) {
              //记录原来的路径
              i.orginicon = i.icon;
              i.icon = this.commonService.OssSignatureUrl(i.icon);

            }

          });
        });
      } else {
        this.msg.error(res.msg || '加载失败');
      }
    })

  }
  /**
   * 菜单删除
   */
  onDelete(data) {
    let params = {
      delName: data.name,
    }
    this.http.delete(`${menusDeleteUrl}/${data.id}`, params).subscribe(res => {
      if (res.code == 0) {
        this.msg.success(res.msg);
        this.getData();
      }
      this.cdr.detectChanges();
    });
  }

  /**
   * 监听路由变化
   */
  ngAfterViewInit() {

    this.router.events.subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        if (event.url.startsWith('/menu')) {
          //恢复默认选中
          this.roleRadio = 0;
          this.searchParams.condition = '';
          this.getList(this.route.snapshot.params.type);
        }


      }
    });
  }
}
