import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzMessageService, NzModalRef } from 'ng-zorro-antd';
import { menusAddUrl, menusEditUrl, CommonService } from '@core';


@Component({
  selector: 'app-addmenu',
  templateUrl: './addmenu.component.html',
  styleUrls: ['./addmenu.component.less']
})
export class AddmenuComponent implements OnInit {

  i: any = {};
  parmas: any;
  type: any;
  // 上级部门
  PdeptName;
  action;
  isLoading = false;
  deptId = 0;
  q: any = {
    pi: 1,
    ps: 10,
    sorter: '',
    q: '',
    total: 0
  };
  listOfMapData = [];
  disabled = false;
  constructor(public msg: NzMessageService,
    public http: _HttpClient,
    private modal: NzModalRef,
    private mod: ModalHelper, private cdr: ChangeDetectorRef, public commonService: CommonService) { }

  ngOnInit() {
    this.i = { ...this.parmas.idata };
    //如果上级为空, 则禁用
    if (this.parmas.listData.length == 0) {
      this.i.parentCode = 0;
      this.disabled = true;
    } else {
      this.listOfMapData = this.parmas.listData;
      if (this.parmas.type == "add") {
        //默认选中的上级
        //this.i.parentCode = this.listOfMapData[0].code;
        this.i.parentCode = 0;
      } else {
        //编辑时上级菜单不可编辑
        this.disabled = true;
        //将图片转换成可以显示的格式
        console.log(this.i);
        if (this.i.icon) {
          let obj = {
            name: '',
            url: '',
            urlPath: ''
          };
          obj.url = this.commonService.OssSignatureUrl(this.i.icon);
          obj.urlPath = this.i.orginicon;
          let arr = [];
          arr.push(obj);
          this.i.file = arr;

        }
      }

    }
  }
  /**
       * 文件上传
       */
  beforeUpload = (file): boolean => {
    let OSS = this.commonService.OSS;
    let path = this.commonService.path + this.commonService.getFileType(file);
    OSS.put(path, file).then((res) => {
      this.i.file = [
        //...this.i.file,
        {
          status: 'done',
          name: file.name,
          url: this.commonService.OssSignatureUrl(path),
          urlPath: this.commonService.getFilePath(path, file)
        }

      ];
    }).catch(() => {
      this.msg.error('文件上传不成功!');
    });
    return false;
  }

  /*
  *表单保存
  */
  save() {
    //图标取第一张
    if (this.i.file && (this.i.file.length != 0)) {
      // //附件图片
      // this.i.file = this.i.file.filter(file => file.status == 'done').map(file => ({ fileName: file.name, urlPath: file.urlPath }));
      // if (!this.i.file.length) {
      //   this.msg.info('请上传附件!');
      //   return;
      // }
      //取第一张图片url
      this.i.icon = this.i.file[0].urlPath;

    } else {
      this.i.icon = '';
    }
    if (this.i.parentCode == null) {
      this.i.parentCode = 0;
    }
    let params = {
      ...this.i,
      //type: this.parmas.mtype,//菜单类型
      applicationId: this.parmas.roleRadio,//模块id
      application: this.parmas.application
    }
    if (this.parmas.type == "edit") {
      //删除不需要的参数
      delete params['parent'];
      delete params['children'];
      this.http.put(`${menusEditUrl}`, params).subscribe((res: any) => {
        if (res.code == 0) {
          this.msg.success(res.msg);

          let parmas = {
            res: res,
            applicationId: this.parmas.roleRadio
          }
          this.modal.close(parmas);
        }
      });
    } else {
      this.http.post(`${menusAddUrl}`, params).subscribe((res: any) => {
        if (res.code == 0) {
          this.msg.success(res.msg);
          let parmas = {
            res: res,
            applicationId: this.parmas.roleRadio
          }
          this.modal.close(parmas);
        }
      });
    }

  }

  // 关闭表单
  close(res: any = null) {
    this.modal.destroy(res);
  }

}
