import { Component, OnInit, HostBinding, Input, Output, EventEmitter, SimpleChanges, ElementRef, Renderer2, ContentChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { trigger, transition, style, animate } from '@angular/animations';

import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, map, filter } from 'rxjs/operators';

import { IObj, ISendCallback, ISuccessCallback, IDataPath, ITotalPath, IMethod } from '../interface';

@Component({
  selector: 'x-table',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './x-table.component.html',
  styleUrls: ['./x-table.component.scss'],
  animations: [
    trigger('insertRemoveTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.3s', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('0.3s', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class XTableComponent implements OnInit {
  set data(data) {
    this._data = data;

    // 重置需要隐藏的单元格
    this.noneCellMap = {};
    setTimeout(() => {
      // 设置固定列
      this.setStickyColumn();
      // 设置排序激活样式
      this.setHasSortStyle();
      // 计算 body_warp 水平滚动位置
      this.onBodyScrollLeft(true);
      // 重置 body_wrap 垂直滚动条
      this.resetBodyScrollTop();
      // 初始化 checked 和 disabled 状态
      this.initCheckedAndDisabled();

      this.currentPageDataChange.emit(data);
      // if (data.length == 0) {
      //   this.isEmpty = true;
      // }
    }, 0);
  }
  get data() { return this._data; }
  get isTempMode() { return !(Array.isArray(this._columns) && this._columns.length) }
  get _tbodyHeight() { return (this.tbodyHeight == null || (typeof this.tbodyHeight != 'number')) ? this.tbodyHeight : `${this.tbodyHeight}px` }
  // get isFixedHeader() { return !!this._tbodyHeight }




  constructor(private elementRef: ElementRef, private renderer: Renderer2, private http: HttpClient, private cdr: ChangeDetectorRef) {
    // this.cdr.detach();
  }
  _xData = [];
  @Input() xData = [];
  _data = [];
  @Output() currentPageDataChange = new EventEmitter<any[]>();

  _columns = [];
  @Input() columns = [];
  @Input() defaultColumn = { sort: null };
  tbodyColumns = [];
  noneCellMap = {};

  nativeElement: HTMLElement;

  // 移除窗口大小监听事件的句柄函数
  removeWindowResize = null;

  // 初始化完成事件
  @Output() inited = new EventEmitter<any>();

  /**
   * 样式
   */
  // 边框
  @Input() bordered = true;
  // 紧凑的单元格
  @Input() condensed = true;
  // 斑马线
  @Input() striped = true;
  // 水平hover高亮
  @Input() hover = true;
  // 垂直hover高亮
  @Input() verticalHover = true;
  onMousemoveSubject = new Subject<MouseEvent>();
  // 内联显示
  @HostBinding('class.inline') @Input() inline = false;
  // //空值
  // @HostBinding('class.x-table-empty') isEmpty = false;
  // 固定布局
  @Input() fixedLayout = false;
  // 不换行布局(超出容器宽度, 水平滚动)
  @Input() nowrap = true;
  prevBodyWrapScrollLeft = null;  // 上一次的 .x-body_wrap 水平滚动位置, 用于判断垂直滚动还是水平滚动
  // tbody高度(固定表头)
  @Input() tbodyHeight = null;



  /**
   * ajax
   */
  // 请求地址
  // @Input() url = 'http://106.12.201.179:7300/mock/5c98418ce957bb0912050a27/test/getTableData';
  @Input() url = null;
  // 请求方法类型
  @Input() method: IMethod = 'get';
  // 请求前的回调, 用于修改请求参数
  @Input() sendCallback: ISendCallback = null;
  // 是否以 Form键值对 的方式提交参数, 默认 Content-Type: application/json
  @Input() isPostForm = false;
  // 自定义请求头
  @Input() headers: IObj | (() => IObj) = null;
  // 请求时是否携带 cookie
  @Input() withCredentials = false;
  // 请求成功的回调, 用于修改服务器返回的数据
  @Input() successCallback: ISuccessCallback = null;
  // 行数据 字段路径
  @Input() dataPath: string | IDataPath = 'rows';
  // 总行数 字段路径
  @Input() totalPath: string | ITotalPath = 'total';
  // 是否显示遮罩层
  @Input() loading = false;
  @Output() loadingChange = new EventEmitter<Boolean>();


  /**
   * 分页
   */
  // 是否显示分页器
  @Input() showPagination = true;
  // 是否显示每页大小选择控件
  @Input() showPageSizeChange = true;
  @Input() pageSize = 30;
  @Input() pageSizeList = [10, 30, 50, 100, 200];
  // 是否显示分页信息
  @Input() showPaginationInfo = true;
  paginationInfo = '';
  // 是否服务器端分页
  @Input() serverPagination = true;
  @Input() pageIndexField = 'pageIndex';  // 页码字段, 设置为 null 则不发送
  @Input() pageOffsetStart = 'offsetStart'; // 分页起始行的偏移字段, 设置为 null 则不发送
  @Input() pageSizeField = 'pageSize';  // 页面大小字段
  // 分页按钮列表
  paginationBtns = [];
  // 可显示的最大分页按钮数量
  @Input() maxPaginationBtnNumber = 7;
  // 当前页码
  pageIndex = 1;
  // 总记录数
  totalRowNumber = 0;
  // 总页数
  totalPageNumber = 0;

  /**
   * checkbox
   */
  // 是否显示 checkbox
  @Input() showCheckbox = true;
  // 是否高亮被选中的行
  @Input() chkCheckedHighlight = true;
  // checkbox 显示在第几列
  @Input() chkIndex = 0;
  // 是否可多选
  @Input() chkMultiple = true;
  // 选中的行列表
  @Input() checkedRows = [];
  @Output() checkedRowsChange = new EventEmitter<any[]>();
  // 禁用的行列表
  @Input() disabledRows = [];
  @Output() disabledRowsChange = new EventEmitter<any[]>();
  // 默认选中函数
  // @Input() checked: (rowData: IObj) => boolean = (rowData) => rowData.id == 441;
  @Input() checked: (rowData: IObj) => boolean = null;
  // 默认禁用函数
  // @Input() disabled: (rowData: IObj) => boolean = (rowData) => rowData.id == 440;
  @Input() disabled: (rowData: IObj) => boolean = null;
  // 全选状态标识
  isAllChecked = false;
  // checkbox 选择事件
  @Output() checkboxChange = new EventEmitter<{ checked: boolean, rows: any[] }>();


  /**
   * 排序
   */
  // 是否支持多列排序
  @Input() sortMultiple = true;
  // 是否服务器端排序(仅在 url 模式下有效)
  @Input() serverSort = null;  // 默认跟随 serverPagination 设置, 如果不分页, 则为 false
  // 请求时的排序字段名
  @Input() sortField = 'sorts';
  // 用于记录排序顺序列的列表
  sortColumns = [];

  ngOnChanges(changes: SimpleChanges) {
    const { columns, defaultColumn, url, xData } = changes;

    /**
     * 列配置变更
     */
    if (columns || defaultColumn) {
      this.initColumns();

      // 重置需要隐藏的单元格
      this.noneCellMap = {};

      if ((columns || defaultColumn).firstChange) {
        /**
         * 首次变更 => 加载数据源
         */
        this.loadData();
      } else {
        /**
         * 非首次变更 => 更新视图
         */
        setTimeout(() => {
          // 设置固定列
          this.setStickyColumn();
          // 设置排序激活样式
          this.setHasSortStyle();
          // 计算 body_warp 水平滚动位置
          this.onBodyScrollLeft(true);
        }, 0);
      }
    }

    /**
     * 数据源变更
     */
    if (url || xData) {
      // 非首次变更 => 加载数据源
      if (!(url || xData).firstChange) {
        this.pageIndex = 1;
        this.loadData();
      }
    }

    // 布局变更
    if (changes.fixedLayout || changes.nowrap || changes.tbodyHeight) {
      // this.updateView();
    }

    // 选中的行列表 & 禁用的行列表 变更
    if (changes.checkedRows || changes.disabledRows) {
      this.syncIsAllChecked();
    }

    setTimeout(() => {
      // this.detectChanges();
    }, 0);
  }

  ngOnInit() {
    /**
     * 监听浏览器窗口大小改变
     */
    this.removeWindowResize = this.renderer.listen('window', 'resize', () => {
      // 同步单元格宽度
      // if (this.isFixedHeader && !this.fixedLayout) {
      //   // this.syncCellWidth();
      // }

      // // 设置固定列
      // this.setStickyColumn();

      // 计算 body_warp 水平滚动位置
      this.onBodyScrollLeft(true);
    });

    /**
     * 观察鼠标移动事件 => 设置 垂直hover高亮
     */
    this.onMousemoveSubject.pipe(
      // 过滤
      filter(e => this.verticalHover),
      // 转换
      map(e => e.target['cellIndex']),
      // 防抖(ms)
      debounceTime(50),
      // 去重
      distinctUntilChanged()
    ).subscribe(this.setVerticalHover);

    /**
     * 合并默认可供选择的每页大小列表
     */
    if (this.pageSize) {
      this.pageSize = parseInt(this.pageSize.toString())
      if (!this.pageSizeList.includes(this.pageSize)) {
        this.pageSizeList.push(this.pageSize);
        this.pageSizeList = this.pageSizeList.sort((a, b) => a - b);
      }
    } else {
      this.pageSize = this.pageSizeList[0];
    }

    // this.detectChanges();

    // this.initColumns();
    // this.loadData();
  }

  ngOnDestroy() {
    this.removeWindowResize();
  }

  ngAfterViewInit() {
    this.nativeElement = this.elementRef.nativeElement;
    // 同步 table 和 head_table 宽度 => 空行时的水平滚动
    // if (this.isFixedHeader) {
    //   // let table = this.nativeElement.querySelector<HTMLElement>('.x-body_wrap [x-table]');
    //   // let head_table = this.nativeElement.querySelector<HTMLElement>('.x-head_wrap [x-table]');
    //   // table.style.minWidth = getComputedStyle(head_table).width;
    // }

    setTimeout(() => {
      this.inited.emit(this);
    }, 0);
  }

  ngAfterViewChecked() {
    // if (this.isTempMode) {
    //   this.syncCellEWidthAsTh();
    // }

    // if (this.isFixedHeader) {
    //   this.syncScrollWidth();

    //   if (!this.isGetWidthDone) {
    //     // this.clearCellWidth();
    //   }
    //   // this.syncCellWidth();
    // }


    // if (!this.awaitSetStickyColumn) {
    //   let body_wrap = this.nativeElement.querySelector<HTMLElement>('.x-body_wrap');
    //   let currentTableWidth = getComputedStyle(body_wrap).width;

    //   if (currentTableWidth != this.prevTableWidth) {
    //     this.prevTableWidth = currentTableWidth;
    //     this.awaitSetStickyColumn = true;
    //   }
    // }

    // if (this.awaitSetStickyColumn) {
    //   this.awaitSetStickyColumn = false;
    //   this.setStickyColumn();
    // }

  }

  detectChanges() {
    this.cdr.detectChanges();
  }

  /**
   * 初始化列
   */
  initColumns() {
    // 克隆
    this._columns = this.columns.map(trColumns => [...trColumns]);

    /**
     * 整理列
     */
    // 合并默认列配置
    this._columns.forEach(trColumns => {
      trColumns.forEach((col, colIndex) => {
        trColumns[colIndex] = { ...this.defaultColumn, ...col };
      });
    });

    // 根据 rowspan 补全
    this._columns.forEach((trColumns, trIndex) => {
      let colIndex = 0;
      trColumns.forEach(col => {
        if (col.thAttr && col.thAttr.rowspan > 1) {
          for (let _trIndex = trIndex + 1; _trIndex < (trIndex + Number(col.thAttr.rowspan)); _trIndex++) {
            this._columns[_trIndex] && this._columns[_trIndex].splice(colIndex, 0, {});
          }
        }
        colIndex += Number(col.thAttr ? (col.thAttr.colspan || 1) : 1);
      });
    });

    // 根据 colspan 补全
    this._columns.forEach(trColumns => {
      [...trColumns].forEach((col) => {
        if (col.thAttr && col.thAttr.colspan > 1) {
          trColumns.splice(trColumns.indexOf(col) + 1, 0, ...new Array(col.thAttr.colspan - 1).fill({}))
        }
      });
    });

    // 获取 tbodyColumns
    this.tbodyColumns = [];
    this._columns.forEach(trColumns => {
      trColumns.forEach((col, colIndex) => {
        if (col.field && !((col.thAttr || {}).colspan > 1)) {
          this.tbodyColumns[colIndex] = col;
          // this.tbodyColumns[this.tbodyColumns.length] = col;
        }
      });
    });

    // 设置 checkbox 列
    if (this.showCheckbox && !this.isTempMode) {
      this._columns.forEach((trColumns, trColumnsIndex) => {
        if (trColumnsIndex == 0) {
          trColumns.splice(this.chkIndex, 0, {
            title: ' ',
            type: 'chk',
            thClass: {
              'x-chk-cell': true,
              'border-bottom-bold': true
            },
            thAttr: { rowspan: this._columns.length },
            fixed: 'left'
          });
        } else {
          trColumns.splice(this.chkIndex, 0, {});
        }
      });
      this.tbodyColumns.splice(this.chkIndex, 0, {
        type: 'chk',
        tdClass: { 'x-chk-cell': true },
        fixed: 'left'
      });
    }

    /**
     * 排序
     */
    // 是否服务器端排序(默认跟随 serverPagination 设置)
    if (this.url != null && this.serverSort == null) {
      if (this.showPagination) {
        this.serverSort = this.serverPagination;
      } else {
        this.serverSort = false;
      }
    }

    // 设置默认排序
    this.sortColumns = [];
    if (this.sortMultiple) {
      this.sortColumns = this._columns.reduce((prev, current) => {
        return [...prev, ...current.filter(column => (typeof column.field == 'string' || column.sortField) && column.sort)]
      }, []);
    } else {
      this._columns.forEach(trColumns => {
        trColumns.forEach(column => {
          if ((typeof column.field == 'string' || column.sortField) && column.sort) {
            if (this.sortColumns.length) {
              column.sort = '';
            } else {
              this.sortColumns.push(column);
            }
          }
        });
      });
    }

    /**
     * 设置固定表头
     */
    // if (this.isFixedHeader) {
    setTimeout(() => {
      this.setStickyThead();
    }, 0);
    // }
  }

  /**
   * 加载数据
   */
  loadData() {
    if (this.url != null) {
      this.loadUrlData();
    } else {
      this._xData = [...this.xData];
      this.loadNativeData();
    }
  }

  /**
   * 重新加载数据
   * @param resetPage: boolean = true 是否重置分页
   */
  reload(resetPage = false) {
    if (resetPage) {
      this.pageIndex = 1;
    }
    this.loadData();
  }

  /**
   * 组装渲染器数据
   */
  getRenderData(row, column, render, rowIndex, rowFirst, rowLast, rowEven, rowOdd, columnIndex, columnFirst, columnLast, columnEven, columnOdd) {
    return {
      // 行数据
      row,
      // 列配置
      column,
      // 渲染标识
      render,
      // 行迭代元数据
      rowIterator: {
        index: (this.pageIndex - 1) * this.pageSize + (rowIndex),
        isFirst: rowFirst,
        isLast: rowLast,
        isEven: rowEven,
        isOdd: rowOdd
      },
      // 列迭代元数据
      columnIterator: {
        index: columnIndex,
        isFirst: columnFirst,
        isLast: columnLast,
        isEven: columnEven,
        isOdd: columnOdd
      }
    };
  }

  /**
   * 检测一个元素是否有滚动条
   * @param el 
   * @param direction = 'vertical'; //vertical | horizontal
   */
  hasScrolled(el, direction = "vertical"): boolean {
    if (direction === "vertical") {
      return el.scrollHeight > el.clientHeight;
    } else if (direction === "horizontal") {
      return el.scrollWidth > el.clientWidth;
    }
  }

  /**
   * 获取系统滚动条宽度
   */
  getScrollbarWidth(): number {
    let oP = document.createElement('p'), styles = {
      width: '100px',
      height: '100px',
      overflowY: 'scroll',
    }, i, scrollbarWidth;

    for (i in styles) {
      oP.style[i] = styles[i];
    }
    document.body.appendChild(oP);
    scrollbarWidth = oP.offsetWidth - oP.clientWidth;
    oP.remove();

    return scrollbarWidth;
  }

  /**
   * 获取单元格样式
   * @param cellType 
   * @param rowData 
   * @param column 
   */
  getCellStyle(cellType, rowData, column, rowIndex, rowFirst, rowLast, rowEven, rowOdd, columnIndex, columnFirst, columnLast, columnEven, columnOdd): object {
    let style = {};
    if (column.width != null) {
      if (!this.fixedLayout) {
        const width = (typeof column.width == 'number') ? `${column.width}px` : column.width;
        style['width'] = width;
        style['min-width'] = width;
      } else {
        // 固定布局模式下, 由 <colgroup> 来约束宽度
      }

      // 在 nowrap = true 模式下, 如果设置了 width, 则强制换行
      if (this.nowrap) {
        style['white-space'] = 'normal';
      }
    }

    const params = [
      rowData,
      column,
      // rowIterator 行迭代元数据
      {
        rowIndex, rowFirst, rowLast, rowEven, rowOdd
      },
      // columnIterator 列迭代元数据
      {
        columnIndex, columnFirst, columnLast, columnEven, columnOdd
      }
    ];

    if (cellType == 'th') {
      style = {
        ...(typeof column.thStyle == 'function' ? column.thStyle(...params) : column.thStyle),
        ...style,
        'display': column.title == null ? 'none' : null
      };
    } else {
      style = {
        ...(typeof column.tdStyle == 'function' ? column.tdStyle(...params) : column.tdStyle),
        ...style,
        'display': this.tdNone(rowIndex, columnIndex) ? 'none' : null
      };
    }

    return style;
  }

  /**
   * 格式化宽度值
   * @param width 
   */
  formatWidth(width) {
    return (typeof width == 'number') ? `${width}px` : width;
  }

  /**
   * 获取表格样式
   */
  getTableClass() {
    return {
      'x-table': true,
      'x-table-condensed': this.condensed,
      'x-table-striped': this.striped,
      'x-table-hover': this.hover,
      'x-table-nowrap': this.nowrap,
      'x-table-fixed': this.fixedLayout,
      // 'x-table-sticky_thead': this.isFixedHeader
      'x-table-sticky_thead': true
    };
  }

  /**
   * 获取单元格样式类
   * @param cellType 
   * @param rowData 
   * @param column 
   */
  getCellClass(cellType, rowData, column, rowIndex, rowFirst, rowLast, rowEven, rowOdd, columnIndex, columnFirst, columnLast, columnEven, columnOdd): object {
    const classObj = {
      'x-left-sticky': column.fixed == 'left',
      'x-right-sticky': column.fixed == 'right',
    };

    const params = [
      rowData,
      column,
      // rowIterator 行迭代元数据
      {
        rowIndex, rowFirst, rowLast, rowEven, rowOdd
      },
      // columnIterator 列迭代元数据
      {
        columnIndex, columnFirst, columnLast, columnEven, columnOdd
      }
    ];

    if (cellType == 'th') {
      return {
        'border-bottom-bold': this.tbodyColumns.includes(column),
        ...(typeof column.thClass == 'function' ? column.thClass(...params) : column.thClass),
        ...this.getThSortClass(column),
        ...classObj,
      };
    } else {
      return {
        ...(typeof column.tdClass == 'function' ? column.tdClass(...params) : column.tdClass),
        ...classObj,
      };
    }
  }

  /**
   * 获取单元格 HTML 属性
   * @param cellType 
   * @param rowData 
   * @param column 
   */
  getCellAttr(cellType, rowData, column, rowIndex, rowFirst, rowLast, rowEven, rowOdd, columnIndex, columnFirst, columnLast, columnEven, columnOdd): object {
    let attrObj: any = {};

    // 设置明确宽度标识
    if (column.width != null) {
      const width = (typeof column.width == 'number') ? `${column.width}px` : column.width;
      attrObj['data-width'] = width;
    }

    const params = [
      rowData,
      column,
      // rowIterator 行迭代元数据
      {
        rowIndex, rowFirst, rowLast, rowEven, rowOdd
      },
      // columnIterator 列迭代元数据
      {
        columnIndex, columnFirst, columnLast, columnEven, columnOdd
      }
    ];

    if (cellType == 'th') {
      attrObj = { ...(typeof column.thAttr == 'function' ? column.thAttr(...params) : column.thAttr), ...attrObj };
    } else {
      attrObj = { ...(typeof column.tdAttr == 'function' ? column.tdAttr(...params) : column.tdAttr), ...attrObj };

      /**
       * 记录需要隐藏的单元格
       */
      if (attrObj.colspan > 1) {
        for (let colIndex = (columnIndex + 1); colIndex <= (columnIndex + Number(attrObj.colspan) - 1); colIndex++) {
          !this.noneCellMap[rowIndex] && (this.noneCellMap[rowIndex] = {});
          this.noneCellMap[rowIndex][colIndex] = true;
        }
      }

      if (attrObj.rowspan > 1) {
        for (let _rowIndex = (rowIndex + 1); _rowIndex <= (rowIndex + Number(attrObj.rowspan) - 1); _rowIndex++) {
          !this.noneCellMap[_rowIndex] && (this.noneCellMap[_rowIndex] = {});
          this.noneCellMap[_rowIndex][columnIndex] = true;
        }
      }
    }

    return attrObj;
  }

  /**
   * 是否隐藏特定的 td单元格
   */
  tdNone(rowIndex, columnIndex): boolean {
    return this.noneCellMap[rowIndex] && this.noneCellMap[rowIndex][columnIndex];
  }

  /**
   * body_wrap 滚动事件处理器 => 计算水平滚动位置
   * @param isRest 
   */
  onBodyScrollLeft(isRest = false) {
    const body_wrap = this.nativeElement.querySelector<HTMLElement>('.x-body_wrap');

    if (isRest) {
      this.prevBodyWrapScrollLeft = null;
    }

    if (body_wrap.scrollLeft == this.prevBodyWrapScrollLeft) return;
    this.prevBodyWrapScrollLeft = body_wrap.scrollLeft;

    const table = this.nativeElement.querySelector<HTMLElement>('table');
    // let head_wrap = null;

    // //同步 head_wrap 滚动位置
    // if (this.isFixedHeader) {
    //   head_wrap = this.nativeElement.querySelector('.x-head_wrap');
    //   head_wrap.scrollLeft = body_wrap.scrollLeft;
    // }

    // 计算滚动条位置 -> 设置固定列激活样式
    this.removeClass(body_wrap, ['x-table_wrap-scroll-left', 'x-table_wrap-scroll-right', 'x-table_wrap-scroll-middle'])
    // if (this.isFixedHeader) {
    //   this.removeClass(head_wrap, ['x-table_wrap-scroll-left', 'x-table_wrap-scroll-right', 'x-table_wrap-scroll-middle'])
    // }

    if (this.hasScrolled(body_wrap, 'horizontal')) {
      switch (true) {
        // 左
        case body_wrap.scrollLeft == 0: {
          this.addClass(body_wrap, ['x-table_wrap-scroll-left']);
          // this.isFixedHeader && this.addClass(head_wrap, ['x-table_wrap-scroll-left']);
          break;
        }
        // 右
        case body_wrap.getBoundingClientRect().right >= table.getBoundingClientRect().right: {
          this.addClass(body_wrap, ['x-table_wrap-scroll-right']);
          // this.isFixedHeader && this.addClass(head_wrap, ['x-table_wrap-scroll-right']);
          break;
        }
        // 中
        default: {
          this.addClass(body_wrap, ['x-table_wrap-scroll-middle']);
          // this.isFixedHeader && this.addClass(head_wrap, ['x-table_wrap-scroll-middle']);
          break;
        }
      }
    }
  }

  /**
   * 添加样式类 (为了兼容IE classList.add API)
   * @param el 
   * @param classList 
   */
  addClass(el: HTMLElement, classList: string[]) {
    classList.forEach(className => {
      el.classList.add(className);
    });
  }

  /**
   * 移除样式类 (为了兼容IE classList.remove API)
   * @param el 
   * @param classList 
   */
  removeClass(el: HTMLElement, classList: string[]) {
    classList.forEach(className => {
      el.classList.remove(className);
    });
  }

  /**
   * 重置 body_wrap 垂直滚动条
   */
  resetBodyScrollTop() {
    this.nativeElement.querySelector('.x-body_wrap').scrollTop = 0;
  }

  /**
   * 根据设置了明确 width 的 th, 为 td 设置宽度
   */
  syncCellEWidthAsTh() {
    // let ths = this.nativeElement.querySelector('thead').querySelectorAll('tr:last-child > th');
    // let trs = this.nativeElement.querySelector('tbody').querySelectorAll('tr');

    // ths.forEach((th, thIndex) => {
    //   let width = th.getAttribute('data-width');
    //   if (width) {
    //     trs.forEach((tr, trIndex) => {
    //       let tds = tr.childNodes;
    //       let td = tds[thIndex];
    //       if (td && td.nodeName == 'TD') {
    //         td['style']['width'] = width;
    //         td['style']['minWidth'] = width;
    //         td['style']['whiteSpace'] = 'normal';
    //         td['setAttribute']('data-width', width);
    //       }
    //     });
    //   }
    // });
  }

  /**
   * 同步单元格宽度
   */
  syncCellWidth() {
    if (this.data.length == 0) return;
    const bodyWrap = this.nativeElement.querySelector('.x-body_wrap');
    const theadTrs = this.nativeElement.querySelector('thead').children;
    const tbodyTrs = this.nativeElement.querySelector('tbody').children;
    if (!tbodyTrs.length) return;

    Array.from(theadTrs).forEach(tr => {
      Array.from(tr.children).forEach(th => {
        if (!th.getAttribute('data-width')) {
          th['style'].width = null;
          th['style'].minWidth = null;
        }
      });
    });
    Array.from(tbodyTrs).forEach(tr => {
      Array.from(tr.children).forEach(td => {
        if (!td.getAttribute('data-width')) {
          td['style'].width = null;
          td['style'].minWidth = null;
        }
      });
    });

    // 获取列宽
    const thWidths = Array.from(theadTrs[0].children).map(() => null);
    const tdWidths = Array.from(tbodyTrs[0].children).map(() => null);

    const getCellWidths = () => {
      Array.from(theadTrs).forEach(tr => {
        Array.from(tr.children).forEach((th, thIndex) => {
          // 跳过 display: none && (colspan != 1) 的单元格
          const isNone = getComputedStyle(th).display == 'none';
          const hasColSpan = (th.getAttribute('colspan') != null) && (th.getAttribute('colspan') != '1');
          if (!isNone && !hasColSpan) {
            thWidths[thIndex] = parseFloat(getComputedStyle(th).width);
          }
        });
      });

      Array.from(tbodyTrs).forEach(tr => {
        Array.from(tr.children).forEach((td, tdIndex) => {
          // 跳过 display: none && (colspan != 1) 的单元格
          const isNone = getComputedStyle(td).display == 'none';
          const hasColSpan = (td.getAttribute('colspan') != null) && (td.getAttribute('colspan') != '1');
          if (!isNone && !hasColSpan) {
            tdWidths[tdIndex] = parseFloat(getComputedStyle(td).width);
          }
        });
      });
    };

    // 根据 th 设置 td 的宽度 (.x-head_wrap [x-table] 是内联显示的, 其中 th 是最小宽度)
    getCellWidths();
    thWidths.forEach((thWidth, index) => {
      const tdWidth = tdWidths[index];
      Array.from(tbodyTrs).forEach(tr => {
        const td = tr.children[index];
        if (thWidth > tdWidth) {
          td['style'].width = `${thWidth}px`;
          td['style'].minWidth = `${thWidth}px`;
        } else {
          td['style'].minWidth = `${thWidth}px`;
        }
      });
    });

    // 根据 td 设置 th 的宽度
    getCellWidths();
    thWidths.forEach((thWidth, index) => {
      const tdWidth = tdWidths[index];
      Array.from(theadTrs).forEach(tr => {
        const th = tr.children[index];
        if (thWidth < tdWidth) {
          th['style'].width = `${tdWidth}px`;
          th['style'].minWidth = `${tdWidth}px`;
        } else {
          th['style'].minWidth = `${tdWidth}px`;
        }
      });
    });

    // 修正thead最后一个单元格宽度
    if (this.hasScrolled(bodyWrap)) {
      Array.from(theadTrs).forEach(tr => {
        const ths = tr.children;
        const lastTh = ths[ths.length - 1];
        if (Number(lastTh.getAttribute('colspan')) < 2) {
          const width = parseFloat(getComputedStyle(lastTh).width) + this.getScrollbarWidth();
          lastTh['style'].width = `${width}px`;
          lastTh['style'].minWidth = `${width}px`;
        }
      })
    }
  }

  /**
   * 设置固定列
   */
  setStickyColumn() {
    const trs = [
      ...Array.from(this.nativeElement.querySelector('thead').children),
      ...Array.from(this.nativeElement.querySelector('tbody').children)
    ];

    trs.forEach(tr => {
      const cells = Array.from(tr.children);
      cells.forEach((cell: HTMLElement, cellIndex) => {
        switch (true) {
          case cell.classList.contains('x-left-sticky'): {
            // 重置
            cell.style.left = null;
            // cell.classList.remove('x-left-sticky');
            // 计算距离
            cell.style.left = (cell.getBoundingClientRect().left - cell.parentElement.getBoundingClientRect().left) + 'px';
            // cell.classList.add('x-left-sticky');

            // 设置层级, 用于 box-shadow 样式
            // cell.style.zIndex = String(cellIndex + 1);
            break;
          }
          case cell.classList.contains('x-right-sticky'): {
            cell.style.right = null;
            // cell.classList.remove('x-right-sticky');
            cell.style.right = (cell.parentElement.getBoundingClientRect().right - cell.getBoundingClientRect().right) + 'px';
            // cell.classList.add('x-right-sticky');

            // cell.style.zIndex = String(cells.length - cellIndex);
            break;
          }
        }
      });

      // //标识最后一个 x-left-sticky 用于设置边框
      // for (let cell of Array.from(cells).reverse()) {
      //   if (cell.classList.contains('x-left-sticky')) {
      //     this.addClass(<HTMLElement>cell, ['x-left-sticky-last']);
      //     break;
      //   }
      // }
    });
  }

  /**
   * 设置固定表头
   */
  setStickyThead() {
    const theadTrs = Array.from(this.nativeElement.querySelector('thead').children);
    const table = this.nativeElement.querySelector('table');

    theadTrs.forEach(tr => {
      const cells = Array.from(tr.children);
      cells.forEach((cell: HTMLElement, cellIndex) => {
        cell.style.top = null;
        cell.style.top = (cell.getBoundingClientRect().top - table.getBoundingClientRect().top) + 'px';
        // cell.style.zIndex = String(this.tbodyColumns.length + 1);
      });
    });
  }


  /**
   * 同步 body_wrap head_wrap 滚动条宽度
   */
  syncScrollWidth() {
    // let body_wrap = this.nativeElement.querySelector('.x-body_wrap');
    // let head_wrap = this.nativeElement.querySelector('.x-head_wrap');
    // if (this.hasScrolled(body_wrap)) {
    //   head_wrap['style'].paddingRight = this.getScrollbarWidth() + 'px';
    // } else {
    //   head_wrap['style'].paddingRight = null;
    // }
  }

  /**
   * 加载 服务器端 数据
   */
  loadUrlData() {
    let params = this.getParams();

    let request = null;
    let headers = {};
    const withCredentials = this.withCredentials;

    if (typeof this.headers == 'function') {
      headers = (this.headers as Function)();
    } else {
      headers = this.headers || {};
    }

    if (this.method == 'get') {
      // 格式化参数
      Object.entries(params).forEach(([key, value]) => {
        params[key] = String(value);
      });
      request = this.http.get<any>(this.url, { params, headers, withCredentials });
    } else {
      // post form 提交
      if (this.isPostForm) {
        headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
        params = Object.entries(params).map(([key, value]) => `${key}=${String(value)}`).join('&');
      }
      request = this.http.post<any>(this.url, params, { headers, withCredentials });
    }

    this.loading = true;
    setTimeout(() => {
      this.loadingChange.emit(this.loading);
    }, 0);
    request.subscribe(res => {
      this.loading = false;
      setTimeout(() => {
        this.loadingChange.emit(this.loading);
      }, 0);

      // 请求成功的回调
      if (this.successCallback) {
        res = this.successCallback(res);
      }

      // 获取数行数据
      if (typeof this.dataPath == 'string') {
        // 对象层次描述
        this.xData = res;
        this.dataPath.split('.').forEach(path => {
          this.xData = this.xData[path];
        });
        this.xData = this.xData || [];
      } else if (typeof this.dataPath == 'function') {
        this.xData = this.dataPath(res);
      }

      // 本地排序
      if (!this.serverSort) {
        this._xData = [...this.xData];
        this.sort();
      }

      // 分页
      if (this.showPagination) {
        // 服务器端分页?
        if (this.serverPagination) {
          this.data = this.xData;

          // 获取总记录数
          if (typeof this.totalPath == 'string') {
            // 对象层次描述
            this.totalRowNumber = res;
            this.totalPath.split('.').forEach(path => {
              this.totalRowNumber = this.totalRowNumber[path];
            });
            this.totalRowNumber = this.totalRowNumber || 0;
          } else if (typeof this.totalPath == 'function') {
            this.totalRowNumber = this.totalPath(res);
          }

        } else {
          // 本地分页
          this.data = this.getPagingData(this.xData, this.pageIndex, this.pageSize);
          this.totalRowNumber = this.xData.length || 0;
        }

        this.totalPageNumber = this.getTotalPageNumber(this.totalRowNumber, this.pageSize);
        this.paginationBtns = this.getPaginationBtns(this.totalPageNumber, this.pageIndex, this.maxPaginationBtnNumber);
        this.paginationInfo = this.getPaginationInfo(this.pageIndex, this.pageSize, this.totalRowNumber);
      } else {
        // 不分页
        this.data = [...this.xData];
      }
    });
  }

  /**
   * 获取请求参数
   */
  getParams() {
    let params: any = {};

    // 分页参数
    if (this.showPagination && this.serverPagination) {
      if (this.pageIndexField) {
        params[this.pageIndexField] = this.pageIndex;
      }
      if (this.pageOffsetStart) {
        params[this.pageOffsetStart] = (this.pageIndex - 1) * Number(this.pageSize)
      }
      if (this.pageSizeField) {
        params[this.pageSizeField] = Number(this.pageSize);
      }
    }

    // 排序参数
    if (this.serverSort && this.sortColumns.length) {
      params[this.sortField] = this.sortColumns.map(column => ({ field: column.sortField || column.field, sort: column.sort }));
    }

    // 执行请求发送前的回调
    if (this.sendCallback) {
      params = this.sendCallback(params);
    }

    // 过滤掉 null 和 ''
    Object.entries(params).forEach(([key, value]) => {
      if (value == null || value === '') {
        delete params[key];
      }
    })

    return params;
  }

  /**
   * 加载本地数据
   */
  loadNativeData() {
    // 本地排序
    this.sort();

    if (this.showPagination) {
      this.data = this.getPagingData(this.xData, this.pageIndex, this.pageSize);
      this.totalRowNumber = this.xData.length || 0;

      this.totalPageNumber = this.getTotalPageNumber(this.totalRowNumber, this.pageSize);
      this.paginationBtns = this.getPaginationBtns(this.totalPageNumber, this.pageIndex, this.maxPaginationBtnNumber);
      this.paginationInfo = this.getPaginationInfo(this.pageIndex, this.pageSize, this.totalRowNumber);
    } else {
      this.data = [...this.xData];
    }
  }

  /**
   * 本地分页, 获取当前页数据
   * @param data 
   * @param pageIndex 
   * @param pageSize 
   */
  getPagingData(data: any[], pageIndex: number, pageSize: number): any[] {
    const startIndex = (pageIndex - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return data.slice(startIndex, endIndex);
  }

  /**
   * 获取分页按钮数组
   * @param totalPageNumber 总页数
   * @param pageIndex 当前页码
   * @param maxPaginationBtnNumber 可显示的最大按钮数
   * @return Array [{ type: '类型', pageIndex: 页码, text: 文本 }];
   */
  getPaginationBtns(totalPageNumber, currentPageIndex, maxPaginationBtnNumber) {
    maxPaginationBtnNumber = Math.min(maxPaginationBtnNumber, totalPageNumber); // 如果总页面数小于最大页面按钮数, 则最大页面按钮数为总页面数
    const flankPages = Math.floor(maxPaginationBtnNumber / 2); // 左右两边按钮数(下舍)
    let start = 0; // 要显示的第一个页面
    let end = 0; // 要显示的最后一个页面
    const pages = [];

    if (totalPageNumber == 0) return pages; // 如果总页面为0, 返回空数组

    // 左右不饱和状态:
    // 在可视分页为偶数的情况下, 左边比右边页面数少1  ((currentPageIndex - flankPages) + 1)
    // 在可视页面为奇数的情况下, 减去(maxPaginationBtnNumber % 2), 用来保持左右对称
    start = currentPageIndex - flankPages + 1 - (maxPaginationBtnNumber % 2);
    end = currentPageIndex + flankPages;

    // 右边不饱和状态:
    if (start <= 0) {
      start = 1;
      end = maxPaginationBtnNumber;
    }
    // 左边不饱和状态:
    if (end > totalPageNumber) {
      start = totalPageNumber - maxPaginationBtnNumber + 1;
      end = totalPageNumber;
    }

    for (let i = start; i <= end; i++) {
      pages.push(i);
    }

    // 省略页面[...]控制
    if (true) {
      // 要显示省略页面, 则可视页面必须>=7个 且 总页面数大于可视页面数
      if (maxPaginationBtnNumber >= 7 && totalPageNumber > maxPaginationBtnNumber) {
        if (pages[0] == 1) {
          pages[pages.length - 1] = totalPageNumber;
          pages[pages.length - 2] = "...";
        } else if (pages[pages.length - 1] == totalPageNumber) {
          pages[0] = 1;
          pages[1] = "...";
        } else {
          pages[0] = 1;
          pages[1] = "...";
          pages[pages.length - 1] = totalPageNumber;
          pages[pages.length - 2] = "...";
        }
      }
    }

    // 格式化
    const pageIndex = pages.indexOf(currentPageIndex);
    pages.forEach(function (v, i) {
      if (v == "...") {
        if (i < pageIndex) {
          pages[i] = {
            type: "...",
            pageIndex: pages[i + 1] - 1,
            text: v
          };
        } else {
          pages[i] = {
            type: "...",
            pageIndex: pages[i - 1].pageIndex + 1,
            text: v
          };
        }
      } else {
        pages[i] = {
          type: v == currentPageIndex ? "active" : "page",
          pageIndex: v,
          text: v
        };
      }
    });

    return pages;
  }

  /**
   * 获取分页信息
   * @param pageIndex 
   * @param pageSize 
   * @param totalRowNumber 
   */
  getPaginationInfo(pageIndex, pageSize, totalRowNumber): string {
    const startIndex = (pageIndex - 1) * pageSize + 1;
    let endIndex = startIndex + pageSize - 1;
    endIndex = Math.min(endIndex, totalRowNumber);
    return `显示第 ${totalRowNumber && startIndex} 至 ${endIndex} 条记录, 共 ${totalRowNumber} 条.`;
  }

  /**
   * 获取总页数
   * @param totalRowNumber 
   * @param pageSize 
   */
  getTotalPageNumber(totalRowNumber: number, pageSize: number): number {
    return Math.ceil(totalRowNumber / pageSize);
  }

  /**
   * 分页按钮点击事件
   * @param pageIndex 
   * @param isPageSizeChange
   */
  onPaginationClick(pageIndex, isPageSizeChange = false) {
    if (!isPageSizeChange) {
      if (pageIndex == this.pageIndex) return;
      if (pageIndex <= 0) return;
      if (pageIndex > this.totalPageNumber) return;
    }

    this.pageIndex = pageIndex;

    if (this.url != null && this.serverPagination) {
      this.loadUrlData();
    } else {
      this.data = this.getPagingData(this.xData, this.pageIndex, this.pageSize);
      this.paginationInfo = this.getPaginationInfo(this.pageIndex, this.pageSize, this.totalRowNumber);
    }

    // this.detectChanges();
  }

  /**
   * 分页大小改变事件
   */
  onPageSizeChange() {
    this.pageIndex = 1;

    // 本地重新分页
    if (!(this.url != null && this.serverPagination)) {
      this.totalPageNumber = this.getTotalPageNumber(this.totalRowNumber, this.pageSize);
      this.paginationBtns = this.getPaginationBtns(this.totalPageNumber, this.pageIndex, this.maxPaginationBtnNumber);
    }
    this.onPaginationClick(this.pageIndex, true);
  }

  /**
   * 行点击事件 => 切换 checked
   * @param row 
   */
  onToggleChk(row: IObj) {
    if (this.disabledRows.includes(row)) return;

    const index = this.checkedRows.indexOf(row);
    const checked = index == -1;
    if (!checked) {
      //取消选中
      this.checkedRows.splice(index, 1);
    } else {
      //选中
      if (!this.chkMultiple && this.checkedRows.length) {
        //单选, 先清空
        this.checkboxChange.emit({ checked: false, rows: this.checkedRows });
        this.checkedRows = [];
      }
      this.checkedRows.push(row);
    }

    this.syncIsAllChecked();

    // this.detectChanges();
    this.checkboxChange.emit({ checked, rows: [row] });
    this.checkedRowsChange.emit(this.checkedRows);
  }

  /**
   * th > checkbox 全选事件
   */
  onToggleAllChk() {
    if (!this.chkMultiple) return;

    // if (this.isAllChecked) {
    //   this.checkedRows = [];
    // } else {
    //   this.checkedRows = this.data.filter(row => !this.disabledRows.includes(row));
    // }

    // this.isAllChecked = !this.isAllChecked;


    this.isAllChecked = !this.isAllChecked;
    //非禁用的行
    let enableRows = this.data.filter(row => !this.disabledRows.includes(row));
    enableRows.forEach(row => {
      if (this.isAllChecked) {
        !this.checkedRows.includes(row) && this.checkedRows.push(row);
      } else {
        this.checkedRows.splice(this.checkedRows.indexOf(row), 1);
      }
    });

    // this.detectChanges();
    this.checkedRowsChange.emit(this.checkedRows);
    this.checkboxChange.emit({ checked: this.isAllChecked, rows: enableRows });
  }

  /**
   * 获取行 checked disabled 状态样式类
   * @param row
   * @param cellType 单元格类型
   */
  getTrChkAndDisClass(row: IObj, cellType: string) {
    if (cellType == 'td') {
      return {
        'x-is_checked': this.checkedRows.includes(row),
        'x-is_checked-highlight': this.checkedRows.includes(row) && this.chkCheckedHighlight,
        'x-is_disabled': this.disabledRows.includes(row),
      };
    } else if (cellType == 'th') {
      return {
        'x-is_checked': this.isAllChecked,
        'x-is_disabled': !this.chkMultiple,
      };
    }

  }

  /**
   * 初始化 checked 和 disabled 状态
   */
  initCheckedAndDisabled() {
    this.checkedRows = [];
    this.disabledRows = [];

    if (typeof this.checked == 'function') {
      this.checkedRows = this.data.filter(this.checked);
    }

    if (typeof this.disabled == 'function') {
      this.disabledRows = this.data.filter(this.disabled);
    }

    this.syncIsAllChecked();

    this.checkedRowsChange.emit(this.checkedRows);
    this.disabledRowsChange.emit(this.disabledRows);
  }

  /**
   * 同步 isAllChecked 勾选状态
   */
  syncIsAllChecked() {
    this.isAllChecked = (this.checkedRows.length && this.checkedRows.length == this.data.filter(row => !this.disabledRows.includes(row)).length);
  }

  /**
   * 获取 th 排序样式类
   * @prams column
   */
  getThSortClass(column) {
    const obj = {};
    if (column.type != 'chk' && (typeof column.field == 'string' || column.sortField) && column.sort !== null) {
      obj['x-sort-cell'] = true;
      obj['x-desc'] = column.sort == 'desc';
      obj['x-asc'] = column.sort == 'asc';
    }
    return obj;
  }

  /**
   * 排序表头点击事件
   * @param column 
   */
  onThSortClick(column) {
    if (!(column.type != 'chk' && (typeof column.field == 'string' || column.sortField) && column.sort !== null)) return false;

    switch (true) {
      case column.sort == '' || column.sort == undefined: { column.sort = 'desc'; break; }
      case column.sort == 'desc': { column.sort = 'asc'; break; }
      case column.sort == 'asc': { column.sort = ''; break; }
    }

    if (this.sortMultiple) {
      const index = this.sortColumns.indexOf(column);
      if (index != -1) {
        // 最后被操作的列, 最后排序
        this.sortColumns.splice(index, 1);
        column.sort != '' && this.sortColumns.push(column);
      } else {
        this.sortColumns.push(column);
      }
    } else {
      this.sortColumns = [];
      this._columns.forEach(trColumns => {
        trColumns.forEach(col => col != column && col.sort && (col.sort = ''));
      });
      column.sort && this.sortColumns.push(column);
    }

    if (this.url != null) {
      if (this.serverSort) {
        this.pageIndex = 1;
        this.loadUrlData();
      } else {
        !this.serverPagination && (this.pageIndex = 1); // (非服务器排序 && 服务器分页) 则 不重置分页
        this.sort();
        this.data = this.getPagingData(this.xData, this.pageIndex, this.pageSize);
        this.paginationInfo = this.getPaginationInfo(this.pageIndex, this.pageSize, this.totalRowNumber);
      }
    } else {
      this.pageIndex = 1;
      this.sort();
      this.data = this.getPagingData(this.xData, this.pageIndex, this.pageSize);
      this.paginationInfo = this.getPaginationInfo(this.pageIndex, this.pageSize, this.totalRowNumber);
    }

    // this.detectChanges();
  }

  /**
   * 本地排序
   */
  sort() {
    this.xData = [...this._xData];

    this.sortColumns.forEach(column => {
      if (column.sortCallback) {
        this.xData.sort(column.sortCallback);
      } else {
        const field = column.sortField || column.field;
        this.xData.sort((prev, next) => {
          switch (true) {
            case next[field] > prev[field]: {
              return column.sort == 'desc' ? 1 : -1;
            }
            case next[field] < prev[field]: {
              return column.sort == 'desc' ? -1 : 1;
            }
            case next[field] == prev[field]: {
              return 0;
            }
          }
        });
      }
    });
  }

  /**
   * 设置排序激活样式
   */
  setHasSortStyle() {
    const theadTrs = this.nativeElement.querySelector('thead').children;
    const tbodyTrs = this.nativeElement.querySelector('tbody').children;
    const trs = [...Array.from(theadTrs), ...Array.from(tbodyTrs)];

    // 重置
    trs.forEach(tr => {
      Array.from(tr.children).forEach(cell => cell.classList.remove('x-has-sort'));
    });

    Array.from(theadTrs).forEach((tr, trIndex) => {
      const ths = Array.from(tr.children);
      ths.forEach((th, thIndex) => {
        if (th.classList.contains('x-desc') || th.classList.contains('x-asc')) {
          th.classList.add('x-has-sort');

          // 考虑在合并的单元格上做出的排序
          const thColspan = Number((th.getAttribute('colspan') || 1));

          Array.from(theadTrs).slice(trIndex + 1).forEach(nextTr => {
            const nextThs = nextTr.children;
            Array.from(nextThs).slice(thIndex, (thIndex + thColspan)).forEach(nextTh => nextTh.classList.add('x-has-sort'));
          });

          Array.from(tbodyTrs).forEach(tr => {
            const tds = tr.children;
            Array.from(tds).slice(thIndex, (thIndex + thColspan)).forEach(td => td.classList.add('x-has-sort'));
          });
        }
      })
    });
  }


  /**
   * 设置垂直 hover 高亮
   */
  setVerticalHover = (cellIndex: number) => {
    if (cellIndex == null) return;
    const tbodyTrs = this.nativeElement.querySelector('tbody').children;
    Array.from(tbodyTrs).forEach(tr => {
      Array.from(tr.children).forEach((td, tdIndex) => {
        if (tdIndex == cellIndex) {
          td.classList.add('x-has-vhover');
        } else {
          td.classList.remove('x-has-vhover');
        }
      })
    })
  }

  /**
   * 清除 垂直 hover 高亮
   */
  onMouseleave(event) {
    if (this.verticalHover) {
      const tbodyTrs = this.nativeElement.querySelector('tbody').children;
      Array.from(tbodyTrs).forEach(tr => {
        Array.from(tr.children).forEach((td, tdIndex) => {
          td.classList.remove('x-has-vhover');
        })
      });
      this.onMousemoveSubject.next(event);
    }
  }

  test() {
    // if(!this.verticalHover) return;
  }








}
