import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { HttpClient } from '@angular/common/http'

import { _HttpClient } from '@delon/theme';
import { baseUrl as _baseUrl } from '@env/environment';
import { CommonService, baseUrl } from '@core';
import * as moment from 'moment';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.less']
})
export class ActivationComponent implements OnInit {
  baseUrl = baseUrl;
  checkedIds: any = [];
  // 所属运营中心
  opList: any = [];

  /**
   * table
   */
  table = {
    searchParams: {} as any,
    loading: false,
    columns: [[
      { title: '序号', field: (row, column, rowIterator) => rowIterator.index + 1 },
      { title: '激活码', field: 'activationCode' },
      {
        title: '激活状态',
        field: (row) => ` ${row.status == '1' ? '激活' : '未激活'}`
      },
      { title: '激活企业', field: 'companyName' },
      // { title: '企业账号', field: 'companyId' },
      { title: '激活时间', field: 'activationTime' },
      // { title: '操作', field: null, fixed: 'right' },
    ]],
    sendCallback: (params) => ({ ...params, ...this.table.searchParams })
    // sendCallback: (params) => {
    //   params = {
    //     ...params,
    //     ...this.table.searchParams
    //   };



    //   return params;
    // }
  };
  @ViewChild('XTable', { static: false }) XTable = null;
  @ViewChild('btnTemp', { static: true }) btnTemp = null;



  constructor(private modalService: NzModalService, private fb: FormBuilder, private message: NzMessageService, private http: HttpClient, public commonService: CommonService) {

  }

  ngOnInit() { }





  change() {
    this.checkedIds = this.XTable.checkedRows.map(v => v.companyId);
  }

}


