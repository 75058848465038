import { Injectable, Injector } from '@angular/core';
import { CacheService } from '@delon/cache';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CodeManageService {

  constructor(protected injector: Injector, private srv: CacheService) {
  }

  private getQuickDeployApplications(){
    const applications = this.srv.getNone<string[]>('applications');
    if(applications == null){
      return [];
    }
  }
  
}
/**
 * 所属运营中心
 */
export interface CodeManageData {
  code:string|number;
  data:CodeManageDataList[];
}
/**
 * 所属运营中心data
 */
export interface CodeManageDataList {
  entAccountNum: number;
  children:[];
  entUserNum:number;
  id:number;
  isLeaf:boolean;
  key:string;
  name:string;
  parent:number | null | string;
  parentCode: null | string;
  principal:string;
  principalMobile:string;
  title:string;
  type:string;
  usedCompanyNum:number;
  usedUserNum:number;
}

/**
 * 账号
 */
export interface packageData {
  code:string | number;
  data:packageDataList[];
}

/**
 * 数据
 */
export interface packageDataList {
  createTime:string;
  createUserId:number;
  id:number;
  memo:number | null | string;
  name:string;
  setMealDescribe:number | null | string;
  status:string;
}

export interface saveData {
  code:string | number;
  msg:string;
  message:string;
  data:[];
}
/**
 *账号下套餐
 */
export interface setMealData {
  code:string|number;
  data:setMealDataList;
}

/**
 *账号下套餐列表
 */
export interface setMealDataList {
  remainingCompanyNum:number;
  remainingUserNum:number;
  setMeals:any;
}
