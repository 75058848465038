import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzFormatEmitEvent, NzTreeComponent, NzTreeNodeOptions, NzMessageService } from 'ng-zorro-antd';
import { tap, map } from 'rxjs/operators';
import { STComponent, STColumn, STData, STChange, STPage, STColumnBadge } from '@delon/abc';
import { CommonService, baseUrl, uploadUrl } from '@core';
import { format } from 'date-fns';
import { TrialEditComponent } from './edit/edit.component';

@Component({
  selector: 'app-trial',
  templateUrl: './trial.component.html',
  styleUrls: ['./trial.component.less']
})
export class TrialComponent implements OnInit {


  uploading = false;
  searchParams: any = {
  };
  loading = false;
  data = [];
  checklist = ['安全风险分级管控系统', '隐患排查治理系统', '危险作业管理系统', '事故（事件）管理', '应急管理', '重大危险源管理系统', '环境管理系统'
    , '职业健康管理', '预警预测系统', '安全绩效考核系统', '台账管理系统', '相关方管理系统', '教育培训系统'
  ];
  // 对话框
  editDialog = {
    display: false,
    loading: false,
    data: <any>{},
    fileList: [],
  };
  q: any = {
    pi: 1,
    ps: 10,
    sorter: '',
    q: '',
    total: 0
  };
  //右侧页面数据
  @ViewChild('st', { static: false })
  st: STComponent;

  BADGE: STColumnBadge = {
    1: { text: '已处理', color: 'success' },
    0: { text: '待处理', color: 'warning' },
  };
  BADGES: STColumnBadge = {
    1: { text: '已联系', color: 'success' },
    2: { text: '已开通试用账号', color: 'success' },
    3: { text: '已购买', color: 'success' },
    '': { text: '', },
  };
  columns: STColumn[] = [
    { title: '序号', type: 'no', width: '50px' },
    { title: '试用产品', index: 'productName', className: 'nohh', width: '150px' },
    {
      title: '企业名称',
      index: 'companyName',
      width: '150px',
      className: 'nohh'
    },
    { title: '联系电话', index: 'companyMoble', width: '150px', className: 'nohh' },
    { title: '申请时间', index: 'applyTime', width: '150px', className: 'nohh' },
    { title: '处理状态', index: 'status', width: '100px', className: 'nohh', type: 'badge', badge: this.BADGE },
    { title: '处理方式', index: 'handleMode', width: '150px', className: 'nohh', type: 'badge', badge: this.BADGES },
    { title: '处理人', index: 'handlerName', width: '100px', className: 'nohh', },
    { title: '处理时间', index: 'handleTime', width: '150px', className: 'nohh', },
    { title: '备注', index: 'remark', width: '300px', className: 'nohh', },
    {
      title: '操作',
      fixed: 'right',
      width: '150px',
      buttons: [
        {
          text: '处理',
          type: 'modal',
          modal: {
            component: TrialEditComponent,
            paramsName: 'idata',
            size: 600,
            modalOptions: {
              nzMaskClosable: false,
            },
          },
          click: () => this.getData(),
          iif: (p: STData) => p.status == '0',
        },
        {
          text: '删除',
          type: 'del',
          click: (modal: any) => {
            this.delete(modal.id)
          }
        },

      ],
    },
  ];

  constructor(

    public msg: NzMessageService,
    private http: _HttpClient,
    private cdr: ChangeDetectorRef, private modal: ModalHelper, public commonService: CommonService) {
  }


  ngOnInit() {
    this.getData();
  }



  /**
   * 
   * 获取问题数据
   */
  getData(reset: boolean = false) {
    if (reset) {
      this.q.pi = 1;
    }


    let searchParams = {
      ...this.searchParams,
      current: this.q.pi,
      size: this.q.ps,
    };

    this.loading = true;
    this.http.get<any>(`${baseUrl}/probations`, searchParams).subscribe(res => {
      this.loading = false;
      if (res.code == 0) {
        this.data = res.data.records || [];
        this.q.total = res.data.total || 0;
      } else {
        this.msg.error(res.msg || '加载失败');
      }

    });
  }



  /**
   * 删除
   */

  delete(value: any) {
    this.http.delete<any>(`${baseUrl}/probations/` + value).subscribe(res => {
      if (res.code == 0) {
        this.msg.success('操作成功');
        this.getData();
      } else {
        this.msg.error('操作失败');
      }
    });

  }



  pages: STPage = {
    total: false,//分页显示多少条数据，字符串型
    show: true,//显示分页
    front: false, //关闭前端分页，true是前端分页，false后端控制分页,
    showSize: true,

  };
  change(e: STChange) {
    if (e.type === 'pi' || e.type === 'ps') {
      this.q.pi = e.pi;
      this.q.ps = e.ps;
      this.getData();
    }
  }


}
