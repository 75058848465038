import { Component, OnInit } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { HttpClient } from '@angular/common/http';
import { NzMessageService, NzModalRef } from 'ng-zorro-antd';
import { CommonService, baseUrl, domain } from '@core';
import { setHours, format } from 'date-fns';
export interface getFileData {
  code: number;
  data: getFileDataList[];
  msg: string;
}
export interface getFileDataList {
  fileName: string;
  fileUrl: string;
}

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less'],
})
export class EditComponent implements OnInit {
  // 临时表单数据
  i: any = {};
  // 类型/  新发布,编辑,查看
  type: any = '';
  // 行的原始数据
  idata: any = {};
  value: string[] = ['0-0-0'];
  dept_nodes: any = [];
  // 当前选中的节点
  selectNode: any = {};

  action;
  isShow = true;
  // 编辑器初始化
  viewInitComplete = false;
  config = {
    // topOffset: 5
    autoHeightEnabled: false,
    initialFrameHeight: 600,
  };
  method = 'post';
  ue = null;

  insertimageLoading = false;
  inserthtmlLoading = false;

  // 时间限制变量
  today = new Date();
  constructor(
    private modal: NzModalRef,
    public msg: NzMessageService,
    public http: _HttpClient,
    public ngHttp: HttpClient,
    private commonService: CommonService,
  ) {}

  ngOnInit() {
    /**将行的原始数据合并到临时表单数据 */

    // this.i = { ...this.idata };

    // 判断是新增还是编辑

    if (JSON.stringify(this.i) == '{}') {
      this.i.screenShow = '1';
      this.isShow = false;
      this.i.text = '请输入内容';
      this.action = '/notice';
      this.method = 'post';
    } else {
      // 单选必须传字符串类型
      this.i.status = `${this.i.status}`;
      this.action = '/notice';
      this.method = 'put';

      this.getData();
    }
  }

  getData() {
    this.http.get<any>(`${baseUrl}/notice/${this.i.id}`).subscribe(res => {
      if (res.code == 0) {
        this.i = res.data;
        // string 类型才能回显到radio 控件
        this.i.publishMethod = this.i.publishMethod + '';
      }
    });
  }

  /**
   * 限制选取时间
   **/
  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.today) {
      return false;
    }
    return endValue.getTime() <= this.today.getTime() - 24 * 60 * 60 * 1000;
  };

  /**
   * 编辑器初始化
   **/
  ngAfterViewInit() {
    setTimeout(() => {
      this.viewInitComplete = true;
    }, 0);
  }
  ready(ue) {
    console.log('UE 初始化完成!', ue);
    this.ue = ue;
  }
  htmlChange() {
    console.log(this.i.text);
  }

  /**
   * 保存
   */
  save(status: any) {
    if (!this.i.text) {
      this.msg.error('请输入正文!');
      return;
    }

    // this.i.validityTime = format(this.i.validityTime, 'YYYY-MM-DD HH:mm:ss');

    if (this.i.createTime) {
      this.i.createTime = format(this.i.createTime, 'YYYY-MM-DD HH:mm:ss');
    }
    if (this.i.publishTime) {
      this.i.publishTime = format(this.i.publishTime, 'YYYY-MM-DD HH:mm:ss');
    }
    const formdata = {
      ...this.i,
      status, // status 0 保存（未发布）  1 发布
      text: `<div style="padding: 24px;">${this.i.text}</div>`,
    };

    const action = this.action;
    if (this.method == 'post') {
      this.http.post<any>(`${baseUrl}${action}`, formdata).subscribe(res => {
        if (res.code == 0) {
          this.msg.success(res.msg);
          this.modal.destroy(res);
        }
      });
    } else {
      this.http.put<any>(`${baseUrl}${action}`, formdata).subscribe(res => {
        if (res.code == 0) {
          this.msg.success(res.msg);
          this.modal.destroy(res);
        }
      });
    }
  }

  close() {
    this.modal.destroy();
  }

  /**
   * 文件上传
   */
  getBeforeUpload(type) {
    return (file): boolean => {
      const formData = new FormData();
      formData.append('file', file);
      const authToken = JSON.parse(window.localStorage.getItem('UserInfo') || '{}').token;
      const headers = {
        token: authToken,
        Authorization: 'Bearer ' + authToken,
      };
      this[`${type}Loading`] = true;
      this.ngHttp.post<getFileData>(`${domain}/platform/v1/file`, formData, { headers }).subscribe(res => {
        this[`${type}Loading`] = false;
        if (res.code == 0) {
          console.log(res, 11);
          const fileUrl = res.data[0].fileUrl;
          if (type == 'insertimage') {
            // 插入图片
            const notice_ueditor = document.getElementById('notice_ueditor');
            const notice_ueditor_width = notice_ueditor.getBoundingClientRect().width;

            // 检测图像大小
            const img = document.createElement('img');
            img.src = fileUrl;
            img.style.opacity = '0';
            img.style.zIndex = '-1';
            img.style.position = 'fixed';
            img.style.top = '0';
            img.style.left = '0';
            document.body.appendChild(img);
            img.onload = () => {
              const width = img.getBoundingClientRect().width;
              const height = img.getBoundingClientRect().height;
              let imgHtml = '';
              if (width >= notice_ueditor_width) {
                imgHtml = `<img style=" max-width: 100%; " src="${fileUrl}" width="${notice_ueditor_width - 50}" />`;
              } else {
                imgHtml = `<img style=" max-width: 100%; " src="${fileUrl}" />`;
              }
              this.ue.execCommand('inserthtml', imgHtml);
              document.body.removeChild(img);
            };
          } else {
            // 插入html
            this.ue.execCommand(
              'inserthtml',
              `<span>附件: <a href="${fileUrl}" onclick="return false;" target="_blank">${file.name}</a></span>`,
            );
          }
        } else {
          this.msg.error(res.msg || '文件上传不成功!');
        }
      });
      return false;
    };
  }
}
