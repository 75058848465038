import { Injectable, Inject, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { zip } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MenuService, SettingsService, TitleService, ALAIN_I18N_TOKEN } from '@delon/theme';
import { ACLService } from '@delon/acl';
import { TranslateService } from '@ngx-translate/core';
import { I18NService } from '../i18n/i18n.service';

import { NzIconService } from 'ng-zorro-antd';
import { ICONS_AUTO } from '../../../style-icons-auto';
import { ICONS } from '../../../style-icons';
import { Router } from '@angular/router';
import { baseUrl, domain } from '../api';
import { CommonService } from '../common.service';
import { ReuseTabService } from '@delon/abc/reuse-tab';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { environment } from '../../../environments/environment';
/**
 * 用于应用启动时
 * 一般用来获取应用所需要的基础数据等
 */
@Injectable()
export class StartupService {
  constructor(
    iconSrv: NzIconService,
    private menuService: MenuService,
    private translate: TranslateService,
    @Inject(ALAIN_I18N_TOKEN) private i18n: I18NService,
    private settingService: SettingsService,
    private aclService: ACLService,
    private titleService: TitleService,
    private httpClient: HttpClient,
    //  private router: Router,
    private injector: Injector,
    private commonService: CommonService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    @Inject(ReuseTabService) private reuseTabService: ReuseTabService,
  ) {
    iconSrv.addIcon(...ICONS_AUTO, ...ICONS);
  }
  private getUrlparam(param) {
    const reg = new RegExp('(^|&)' + param + '=([^&]*)(&|$)');
    const r =
      window.location.search.substr(1).match(reg) ||
      window.location.hash.substring(window.location.hash.search(/\?/) + 1).match(reg);
    if (r != null) {
      return decodeURIComponent(r[2]);
    }
  }
  load() {
    if (environment.production && window.location.protocol === 'http:') {
      let url = window.location.href;
      url = url.replace('http', 'https');
      window.location.replace(url);
    }
    const authToken = {
      // access_token: this.tokenService.get().token,
      access_token: JSON.parse(window.localStorage.getItem('UserInfo') || '{}').token,
      status: 1, // 正常流程登录
    };

    const __sid = this.getUrlparam('__sid');
    const toType = this.getUrlparam('toType');
    const headers = new HttpHeaders().set('Authorization', 'Basic eXVrb246eXVrb24').set('token', '');
    if (__sid) {
      return this.httpClient
        .post(`${domain}/platform-auth/mobile/token/sms?_allow_anonymous=true&code=${__sid}&type=sid`, {}, { headers })
        .toPromise()
        .then((res: any) => {
          // 设置用户Token信息
          res.token = res.access_token;
          window.localStorage.setItem('UserInfo', JSON.stringify(res || {}));
          return this.checkToken(res).then(() => {
            if (toType == 'customer') {
              this.injector.get(Router).navigate(['/data-lsd-jg-v2']);
            } else {
              this.injector.get(Router).navigate(['/']);
            }

            return Promise.resolve(null);
          });
        });
    } else {
      return this.checkToken(authToken).then(() => {
        return Promise.resolve(null);
      });
    }
  }
  checkToken(res): Promise<any> {
    return new Promise(resolve => {
      zip(
        this.httpClient.get(`assets/tmp/i18n/${this.i18n.defaultLang}.json`),
        this.httpClient.get('assets/tmp/app-data.json'),
        // this.httpClient.get('assets/tmp/menus.json'),
        this.httpClient.get(`${baseUrl}/users/findPermissions`),
        this.injector.get(CommonService).getOSSParams(),
      )
        .pipe(
          // 接收其他拦截器后产生的异常消息
          catchError(([langData, appData]) => {
            resolve(null);
            return [langData, appData];
          }),
        )
        .subscribe(
          ([langData, appData, findPermissions]) => {
            if (res.status !== 1) {
              // 清空路由复用信息
              this.reuseTabService.clear();
              this.tokenService.clear();
              // 设置用户Token信息
              res.token = res.access_token;
              // this.tokenService.set(res);
              window.localStorage.setItem('UserInfo', JSON.stringify(res || {}));
            }

            this.translate.setTranslation(this.i18n.defaultLang, langData);
            this.translate.setDefaultLang(this.i18n.defaultLang);

            // application data
            const resdata: any = appData;

            console.log(resdata);
            // 应用信息：包括站点名、描述、年份
            this.settingService.setApp(resdata.app);
            // 用户信息：包括姓名、头像、邮箱地址
            const user = {
              name: res.realName,
            };
            this.settingService.setUser(user);
            if (0) {
              // ACL：设置权限为全量
              this.aclService.setFull(true);
              // 初始化菜单
              this.menuService.add(resdata.menu);
            } else {
              if (findPermissions.code == 0) {

                this.aclService.setRole(this.getAuths(findPermissions.data));
                this.menuService.add(this.getMenus(findPermissions.data));
              }
            }
            // 设置页面标题的后缀
            this.titleService.default = '';
            this.titleService.suffix = '';
          },
          () => { },
          () => {
            resolve(null);
          },
        );
    });
  }

  /**
   * 获取菜单
   * @param findPermissionsRes
   */
  getMenus(findPermissionsRes = []) {
    let menus = [];
    const recursion = children => {
      const menus = [];
      children.forEach(node => {
        if (node.type != 1) {
          if(node.name =='保险产品' || node.name =='保险类别' ||node.name =='险种管理'){
            // nothing to do
          }else{
            delete node.icon;
            menus.push(node);
            node.text = node.name || node.applicationName;
            node.link = node.path;
            // node.icon = node.icon;
            node.children = recursion(node.menuVos || node.children || []);
          }
 
        }
      });
      return menus;
    };
    menus = recursion(findPermissionsRes);

    return [
      {
        text: '',
        group: true,
        hideInBreadcrumb: true,
        children: menus,
      },
    ] as any;
  }

  /**
   * 获取权限code列表
   * @param findPermissionsRes
   */
  getAuths(findPermissionsRes = []) {
    let authCodes = [];
    findPermissionsRes.forEach(v => (v.children = v.menuVos || []));
    authCodes = this.commonService
      .tree2Array(findPermissionsRes)
      .filter(v => v.type == 1 && v.path)
      .map(v => v.path);
    return authCodes;
  }
}
