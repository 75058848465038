import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzMessageService } from 'ng-zorro-antd';
import { STComponent, STColumn, STData, STChange, STPage } from '@delon/abc';
import { CommonService, baseUrl } from '@core';

@Component({
  selector: 'app-ahj-referrer',
  templateUrl: './ahj-referrer.component.html',
  styleUrls: ['./ahj-referrer.component.less']
})
export class AhjReferrerComponent implements OnInit {

  constructor(
    public msg: NzMessageService,
    private http: _HttpClient,
    private modal: ModalHelper,
    public commonService: CommonService,
  ) { }


  data: any = {};
  rows: any = [];
  loading = false;
  q: any = {
    pi: 1,
    ps: 10,
    sorter: '',
    q: '',
    total: 0,
  };
  // 右侧页面数据
  @ViewChild('st', { static: false })
  st: STComponent;

  id: any = null;
  columns: STColumn[] = [
    { title: '序号', type: 'no', width: '80px' },
    { title: '姓名', index: 'name', className: 'nohh' },
    { title: '手机号', index: 'mobile', className: 'nohh' },
    { title: '所属组织', index: 'operationName', className: 'nohh' },
    { title: '用户所在地', index: 'location', className: 'nohh' },
    { title: '注册时间', index: 'createTime', className: 'nohh' },
  ];
  pages: STPage = {
    total: false, // 分页显示多少条数据，字符串型
    show: true, // 显示分页
    front: false, // 关闭前端分页，true是前端分页，false后端控制分页,
    showSize: true,
  };

  ngOnInit() {
    this.getData();
  }
  getData(reset: boolean = false) {
    if (reset) {
      this.q.pi = 1;
    }
    const searchParams = {
      current: this.q.pi,
      size: this.q.ps,
      memberId: this.data.memberId,
      type: 2
    };
    this.loading = true;
    this.http.get<any>(`${baseUrl}/ahj/member/find/bymemberid`, searchParams).subscribe(res => {
      console.log(res, 666)
      this.loading = false;
      if (res.code === 0) {
        this.rows = res.data.records || [];
        this.q.total = res.data.total || 0;
      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });
  }
  change(e: STChange) {
    if (e.type === 'pi' || e.type === 'ps') {
      this.q.pi = e.pi;
      this.q.ps = e.ps;
      this.getData();
    }
  }
}
