import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '@env/environment';

import { TokenValidService } from '@core';

// layout
import { LayoutDefaultComponent } from '../layout/default/default.component';
import { LayoutPassportComponent } from '../layout/passport/passport.component';
// passport pages
import { UserLoginComponent } from './passport/login/login.component';
import { UserRegisterComponent } from './passport/register/register.component';
import { ResetPasswordComponent } from './passport/reset-password/reset-password.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { OperationComponent } from './operation/operation.component';
import { RoleComponent } from './role/role.component';
import { UserComponent } from './user/user.component';
import { ComboComponent } from './combo/combo.component';
import { MenuComponent } from './menu/menu.component';
import { ActivationComponent } from './activation/activation.component';

import { NoticeComponent } from './notice/notice.component';
import { EditComponent } from './notice/edit/edit.component';

import { PersoninfoComponent } from './personinfo/personinfo.component';
import { TrialComponent } from './product/trial/trial.component';
import { PartnersComponent } from './product/partners/partners.component';
import { CodemanageComponent } from './codemanage/codemanage.component';
import { DologComponent } from './dolog/dolog.component';

import { DataLsdJgV2Component } from './data-lsd-jg-v2/data-lsd-jg.component';
import { DataLsdV2Component } from './data-lsd-v2/data-lsd-v2.component';
import { StandardPaperComponent } from './standard-paper/standard-paper.component';
import { IndustrysetComponent } from './standard-paper/industryset/industryset.component';
import { StandardManageComponent } from './standard-paper/standard-manage/standard-manage.component';
import { BusinessremindComponent } from './businessremind/businessremind.component';
import { BusinesscontractComponent } from './businesscontract/businesscontract.component';
import { ApplicationComponent } from './application/application.component';
import { GPositionComponent } from './application/g-position/g-position.component';
import { PosterComponent } from './poster/poster.component';
import { AhjuserComponent } from './ahjuser/ahjuser.component';
import { SupplierjoinComponent } from './supplierjoin/supplierjoin.component';

/**
 * 后端: 鑫安利-潘一维
 */

const routes: Routes = [
  {
    path: '',
    component: LayoutDefaultComponent,
    canActivate: [TokenValidService],
    canActivateChild: [TokenValidService],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      // 首页
      { path: 'dashboard', component: DashboardComponent },
      // 企业管理
      {
        path: 'enterprise',
        loadChildren: () => import('./enterprise/enterprise.module').then(m => m.EnterpriseModule),
      },
      // 运营中心管理
      // { path: 'operation', component: OperationComponent },
      { path: 'operation', loadChildren: () => import('./operation/operation.module').then(m => m.OperationModule) },
      // 客户管理
      { path: 'customer', loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule) },
      // 角色管理
      { path: 'role', component: RoleComponent },
      // 用户管理
      { path: 'user', component: UserComponent },
      // 套餐管理
      { path: 'combo', component: ComboComponent },
      // 菜单管理
      { path: 'menu/:type', component: MenuComponent },
      // 激活码管理
      { path: 'activation', component: ActivationComponent },
      // 监管大屏管理
      {
        path: 'controlscreen',
        loadChildren: () => import('./controlscreen/controlscreen.module').then(m => m.ControlscreenModule),
      },
      // 帮助中心
      { path: 'help', loadChildren: () => import('./help/help.module').then(m => m.HelpModule) },
      // 风险数据库管理
      {
        path: 'riskdatabase',
        loadChildren: () => import('./riskdatabase/riskdatabase.module').then(m => m.RiskdatabaseModule),
      },
      // 系统公告
      { path: 'notice', component: NoticeComponent },
      // 操作日志
      { path: 'dolog', component: DologComponent },
      // 测试模块
      { path: 'test', loadChildren: () => import('./test/test.module').then(m => m.TestModule) },
      // 教育培训模块
      { path: 'train', loadChildren: () => import('./train/train.module').then(m => m.TrainModule) },
      {
        path: 'personinfo',
        component: PersoninfoComponent,
      },
      // 幻灯片管理
      { path: 'slide', loadChildren: () => import('./slide/slide.module').then(m => m.SlideModule) },
      // 产品试用申请
      {
        path: 'trial',
        component: TrialComponent,
      },
      // 合作伙伴申请
      {
        path: 'partners',
        component: PartnersComponent,
      },
      // 直播
      { path: 'live', loadChildren: () => import('./live/live.module').then(m => m.LiveModule) },
      // 直播视频管理
      { path: 'livevideo', loadChildren: () => import('./livevideo/livevideo.module').then(m => m.LivevideoModule) },
      // 咨询管理
      {
        path: 'consult',
        loadChildren: () => import('./consult/consult.module').then(m => m.ConsultModule),
      },
      // 用户反馈管理
      {
        path: 'feedback',
        loadChildren: () => import('./feedback/feedback.module').then(m => m.FeedbackModule),
      },
      // 激活码管理改进版
      {
        path: 'codemanage',
        component: CodemanageComponent,
      },
      // 收款管理
      {
        path: 'collection',
        loadChildren: () => import('./collection/collection.module').then(m => m.CollectionModule),
      },
      // 保险
      { path: 'insurance', loadChildren: () => import('./insurance/insurance.module').then(m => m.InsuranceModule) },
      // 安全标准化试卷管理
      { path: 'standard-paper', component: StandardPaperComponent },
      //安全标准化行业设置
      { path: 'industryset', component: IndustrysetComponent },
      //安全标准化项目管理
      { path: 'standard-manage', component: StandardManageComponent },
      //业务提醒设置
      { path: 'business-remind', component: BusinessremindComponent },
      //业务合同查询
      { path: 'business-contract', component: BusinesscontractComponent },
      //账号申请
      { path: 'application', component: ApplicationComponent },
      //地理位置信息
      { path: 'gposition', component: GPositionComponent },
      //海报管理
      { path: 'poster', component: PosterComponent },
      //供应商管理
      { path: 'supplier', loadChildren: () => import('./supplier/supplier.module').then(m => m.SupplierModule) },
      //安环家会员管理
      { path: 'ahjuser', component: AhjuserComponent },
      //供应商加盟
      { path: 'supplierjoin', component: SupplierjoinComponent },
    ],
  },
  {
    path: 'passport',
    component: LayoutPassportComponent,
    children: [
      {
        path: 'login',
        component: UserLoginComponent,
        data: { title: '登录', titleI18n: 'app.login.login' },
      },
      // {
      //   path: 'register',
      //   component: UserRegisterComponent,
      //   data: { title: '注册', titleI18n: 'app.register.register' }
      // },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
        data: { title: '重置密码', titleI18n: 'app.login.reset-password' },
      },
    ],
  },
  { path: 'data-lsd-jg-v2', component: DataLsdJgV2Component, data: { title: '客户管理中心V2' } },
  { path: 'data-lsd-v2', component: DataLsdV2Component, data: { title: '客户管理中心' } },
  { path: '**', redirectTo: 'exception/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
      // NOTICE: If you use `reuse-tab` component and turn on keepingScroll you can set to `disabled`
      // Pls refer to https://ng-alain.com/components/reuse-tab
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class RouteRoutingModule { }
