
import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { CommonService, baseUrl } from '@core';
import { format } from 'date-fns';
import { NzMessageService } from 'ng-zorro-antd';

import { XayReferrerComponent } from './xay-referrer/xay-referrer.component';
import { AhjReferrerComponent } from './ahj-referrer/ahj-referrer.component';
import { BaseCheckableTable } from '../tablebase/base.checkable.table.component';

@Component({
  selector: 'app-ahjuser',
  templateUrl: './ahjuser.component.html',
  styleUrls: ['./ahjuser.component.less'],
})
export class AhjuserComponent extends BaseCheckableTable implements OnInit {
  baseUrl = baseUrl;
  opList: any = [];
  constructor(
    protected injector: Injector,
    public commonService: CommonService,
    private http: _HttpClient,
    private router: ActivatedRoute,
    private modal: ModalHelper,
    private msg: NzMessageService,
  ) {
    super(injector);
  }

  async ngOnInit() {
    await this.loadOpList();
    this.search();
  }
  /**
  * 加载 所属组织结构
  */
  loadOpList() {
    return this.http.get<any>(`${baseUrl}/operationCenter/list`).toPromise().then(res => {
      if (res.code === 0) {
        this.opList = this.commonService.formatNodes(res.data || [], 'id');
        // this.searchParams.operationId = this.opList[0].id
      }
    });
  }

  /**
   * 地区企业
   * @param reset
   */
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    const searchParams = {
      ...this.searchParams,
      current: this.pageIndex,
      size: this.pageSize,
      search: 1,

    };
    if (this.searchParams.accTime && (this.searchParams.accTime.length != 0)) {
      searchParams.startRegisterDay = format(this.searchParams.accTime[0], 'YYYY-MM-DD');
      searchParams.endRegisterDay = format(this.searchParams.accTime[1], 'YYYY-MM-DD');

    } else {
      searchParams.startRegisterDay = '';
      searchParams.endRegisterDay = '';
    }
    if (this.searchParams.accLoginTime && (this.searchParams.accLoginTime.length != 0)) {
      searchParams.startLoginDay = format(this.searchParams.accLoginTime[0], 'YYYY-MM-DD');
      searchParams.endLoginDay = format(this.searchParams.accLoginTime[1], 'YYYY-MM-DD');

    } else {
      searchParams.startLoginDay = '';
      searchParams.endLoginDay = '';
    }
    if (searchParams.operationId == null) {
      searchParams.operationId = '';
    }

    delete searchParams.accTime;
    delete searchParams.accLoginTime;
    console.log(searchParams);
    this.loading = true;
    this.http.get<any>(`${baseUrl}/ahj/member`, searchParams).subscribe(res => {
      this.loading = false;
      if (res.code === 0) {
        this.rows = res.data.records || [];
        this.total = res.data.total || 0;
      }

    });
  }
  openUserList(data: any = {}) {
    this.modal
      .create(
        AhjReferrerComponent,
        { data },
        {
          size: 1000,
          modalOptions: {
            nzMaskClosable: false,
          },
        }).subscribe(res => {
          if (res) {
          }
        });
  }
  openXayList(data: any = {}) {
    console.log(data)
    this.modal
      .create(
        XayReferrerComponent,
        { data },
        {
          size: 1000,
          modalOptions: {
            nzMaskClosable: false,
          },
        },
      )
      .subscribe(res => {
        if (res) {
        }
      });
  }
  resetpwd(id: number | number[]) {
    const isBatch = Array.isArray(id);
    const ids = isBatch ? id : [id];
    this.loading = true;
    this.http.put<any>(`${baseUrl}/ahj/member/pw`, ids).subscribe(res => {
      this.loading = false;
      if (res.code === 0) {
        this.msg.success(res.msg);
        this.search(true);
      }

    });
  }
  onExport() {
    if (this.checkedIds.length > 0) {
      const params = {
        exportType: 1,
        ids: this.checkedIds,
      };

      this.http.get<any>(`${baseUrl}/ahj/member/export2`, params, {
        responseType: 'blob',
        observe: 'response',
      }).subscribe((res: any) => {
        // if (res.code === 0) {
        //   const link = document.createElement('a');
        //   const url = window.URL.createObjectURL(res);
        //   link.href = url;
        //   link.download = `终端用户${format(new Date(), 'yyyy-MM-dd')}.xls`;
        //   link.click();
        //   window.URL.revokeObjectURL(url);
        // }
        if (res.body.code == 1) {
          this.loading = false;
          this.msg.error('导出不成功!');
          return;
        }

        try {
          const link = document.createElement('a');
          const blob = new Blob([<any>res.body]);
          let fileNmae = `终端用户${format(new Date(), 'yyyy-MM-dd')}.xls`;
          link.setAttribute('href', window.URL.createObjectURL(blob));
          link.setAttribute('download', fileNmae);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          this.msg.error(error.toString());
        }

      });
    } else {
      this.msg.error('请选中要导出的用户')
    }
  }
  reset() {
    this.searchParams = {
      key: '',
      accTime: null,
      operationId: '',
      accLoginTime: null,
    }
    this.search();
  }
}

