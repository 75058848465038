import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { STPage } from '@delon/abc';
import { HttpClient } from '@angular/common/http';

import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ACLService } from '@delon/acl';

import { environment, baseUrl as _baseUrl } from '../../environments/environment';
import { moduleUrl, domain } from '@core';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private http: HttpClient, private acl: ACLService) {}

  // 登录后需要打开的页面
  loginBeforeUrl: string = null;

  get pageHeight() {
    return document.body.clientHeight;
  }
  get OSS() {
    // if (environment.production) {
    //   //生产环境
    //   return new window['OSS']({
    //     region: 'oss-cn-beijing',
    //     accessKeyId: 'LTAIVBm1yiqtxFnl',
    //     accessKeySecret: 'Uw4dMdZ1LvZZircPbeaHRQc8TDqiHL',
    //     bucket: 'prdxaloss',
    //     secure: true
    //   });
    // } else {
    //   //测试环境
    //   return new window['OSS']({
    //     region: 'oss-cn-beijing',
    //     accessKeyId: 'LTAIzpfbqAanWh30',
    //     accessKeySecret: 'w91asl9vXFUC0Xsf9cDI2MJ0DvXM41',
    //     bucket: 'testxaloss',
    //     secure: true
    //   });
    // }

    if (environment.production) {
      // 生产环境
      return new (window as any).OSS({
        region: 'oss-cn-beijing',
        accessKeyId: this.OSS_Params.accessKeyId,
        accessKeySecret: this.OSS_Params.accessKeySecret,
        stsToken: this.OSS_Params.securityToken,
        bucket: 'prdxaloss',
        secure: true,
      });
    } else {
      // 测试环境
      return new (window as any).OSS({
        region: 'oss-cn-beijing',
        accessKeyId: this.OSS_Params.accessKeyId,
        accessKeySecret: this.OSS_Params.accessKeySecret,
        stsToken: this.OSS_Params.securityToken,
        bucket: 'testxaloss',
        secure: true,
      });
    }
  }
  get path() {
    // 公司id
    const user_id = JSON.parse(window.localStorage.getItem('UserInfo') || '{}').user_id;
    return `${user_id}/${+new Date()}_${this.selectRandom()}`;
  }

  get operationCode() {
    return JSON.parse(window.localStorage.getItem('UserInfo') || '{}').operationCenterCode;
  }
  get publicPath() {
    // 公司id
    return `public/${+new Date()}_${this.selectRandom()}`;
  }
  get expirationTime() {
    // 20年(秒)
    // return 630720000;
    return 30 * 60;
  }

  // 渠道
  channelList: any = [
    { label: '地推', value: '地推' },
    { label: '网站', value: '网站' },
    { label: '电销', value: '电销' },
    { label: '会议', value: '会议' },
    { label: '社区', value: '社区' },
    { label: '公众号', value: '公众号' },
    { label: '转介绍', value: '转介绍' },
    { label: '激活码开通', value: '激活码开通' },
    { label: '注册', value: '注册' },
    { label: '其他', value: '其他' },
    { label: '免费申请', value: '免费申请' },
    { label: '保险渠道', value: '保险渠道' },
  ];

  /**
   * 隐患颜色
   */
  hiddencolor = {
    // 重大
    zd: '#FF0000',

    // 一般
    yb: '#1890ff',
  };

  // 账户状态
  statusList: any = [
    { label: '启用', value: '启用' },
    { label: '停用', value: '停用' },
  ];

  //试卷状态
  statusIndustry: any = [
    { label: '启用', value: '1' },
    { label: '停用', value: '2' },
  ];

  //管理状态
  standardStatus: any = [
    { label: '已完成', value: '1' },
    { label: '未完成', value: '0' },
  ];

  // 账户类型
  accountTypeList: any = [
    { label: '试用', value: '试用' },
    { label: '正式', value: '正式' },
    { label: '演示', value: '演示' },
  ];
  // 账户类型
  accountType: any = [
    { label: '试用', value: '试用', isCheck: false },
    { label: '正式', value: '正式', isCheck: false },
    { label: '演示', value: '演示', isCheck: false },
  ];
  accountStar: any = [
    { label: '一星', value: '1' },
    { label: '二星', value: '2' },
    { label: '三星', value: '3' },
    { label: '四星', value: '4' },
    { label: '五星', value: '5' },
  ];
  accountScope: any = [
    { label: '大型', value: 'large' },
    { label: '中型', value: 'medium' },
    { label: '小型', value: 'small' },
  ];
  // 行业
  hyly = [
    '农业',
    '林业',
    '畜牧业',
    '渔业',
    '农、林、牧、渔服务业',
    '煤炭开采和洗选业',
    '石油和天然气开采业',
    '黑色金属矿采选业',
    '有色金属矿采选业',
    '非金属矿采选业',
    '开采辅助活动',
    '其他采矿业',
    '农副食品加工业',
    '食品制造业',
    '酒、饮料和精制茶制造业',
    '烟草制品业',
    '纺织业',
    '纺织服装、服饰业',
    '皮革、毛皮、羽毛及其制品和制鞋业',
    '木材加工和木、竹、藤、棕、草制品业',
    '家具制造业',
    '造纸和纸制品业',
    '印刷和记录媒介复制业',
    '文教、工美、体育和娱乐用品制造业',
    '石油加工、炼焦和核燃料加工业',
    '化学原料和化学制品制造业',
    '医药制造业',
    '化学纤维制造业',
    '橡胶和塑料制品业',
    '非金属矿物制品业',
    '黑色金属冶炼和压延加工业',
    '有色金属冶炼和压延加工业',
    '金属制品业',
    '通用设备制造业',
    '专用设备制造业',
    '汽车制造业',
    '铁路、船舶、航空航天和其他运输设备制造业',
    '电气机械和器材制造业',
    '计算机、通信和其他电子设备制造业',
    '仪器仪表制造业',
    '其他制造业',
    '废弃资源综合利用业',
    '金属制品、机械和设备修理业',
    '电力、热力生产和供应业',
    '燃气生产和供应业',
    '水的生产和供应业',
    '房屋建筑业',
    '土木工程建筑业',
    '建筑安装业',
    '建筑装饰和其他建筑业',
    '批发业',
    '零售业',
    '铁路运输业',
    '道路运输业',
    '水上运输业',
    '航空运输业',
    '管道运输业',
    '装卸搬运和运输代理业',
    '仓储业',
    '邮政业',
    '住宿业',
    '餐饮业',
    '电信、广播电视和卫星传输服务',
    '互联网和相关服务',
    '软件和信息技术服务业',
    '货币金融服务',
    '资本市场服务',
    '保险业',
    '其他金融业',
    '房地产业',
    '租赁业',
    '商务服务业',
    '研究和试验发展',
    '专业技术服务业',
    '科技推广和应用服务业',
    '水利管理业',
    '生态保护和环境治理业',
    '公共设施管理业',
    '居民服务业',
    '机动车、电子产品和日用产品修理业',
    '其他服务业',
    '教育',
    '卫生',
    '社会工作',
    '新闻和出版业',
    '广播、电视、电影和影视录音制作业',
    '文化艺术业',
    '体育',
    '娱乐业',
    '中国共产党机关',
    '国家机构',
    '人民政协、民主党派',
    '社会保障',
    '群众团体、社会团体和其他成员组织',
    '基层群众自治组织',
    '国际组织',
  ];

  // 到期日期
  endDateList: any = [
    { label: '近一周', value: 0 },
    { label: '近半个月', value: 1 },
    { label: '近一个月', value: 2 },
    { label: '近三个月', value: 3 },
    { label: '近半年', value: 4 },
  ];

  // 审核状态
  checkStatusList: any = [
    { label: '未审核', value: '0', color: '#000' },
    { label: '审核通过', value: '1', color: 'green' },
    { label: '审核未通过', value: '2', color: 'red' },
  ];

  // 套餐状态
  comboStatusList: any = [
    { label: '启用', value: '1' },
    { label: '停用', value: '0' },
  ];
  // 菜单跳转方式
  menuJumpType: any = [
    { label: '跳转新窗口', value: '_blank' },
    { label: '原窗口打开', value: '_parent' },
  ];
  // 菜单类型
  menuType: any = [
    { label: '菜单类型', value: '0' },
    { label: '按钮类型', value: '1' },
  ];
  /**
   * 风险颜色
   */
  color = {
    // 重大
    zd: '#FF0000',
    // 较大
    jd: '#FF8800',
    // 一般
    yb: '#FFFF00',
    // 低风险
    di: '#5abaff',
  };
  /**
   *
   * st后端分页
   */
  pages: STPage = {
    total: false, // 分页显示多少条数据，字符串型
    show: true, // 显示分页
    front: false, // 关闭前端分页，true是前端分页，false后端控制分页,
    showSize: true,
  };
  /**
   * OSS
   */
  OSS_Params = {
    loading: false,
    accessKeyId: '',
    accessKeySecret: '',
    securityToken: '',
    expiration: '2018-11-04T09:58:12Z',
  };

  /**
   * 权限检查
   * @param code 权限代码
   */
  can(code) {
    return this.acl.can(code);
  }

  /**
   * 获取随机整数
   * @param lowValue
   * @param highValue
   */
  selectRandom(lowValue = 0, highValue = 1000) {
    const choice = highValue - lowValue + 1;
    return Math.floor(Math.random() * choice + lowValue);
  }

  /**
   * 是否是手机号
   * @param phone
   */
  isPhone(phone) {
    // return !!(phone && (phone.length == 11) && !isNaN(phone));
    // return /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/.test(phone);
    return /^1\d{10}$/.test(phone);
  }

  /**
   * 触发表单验证
   * @param form
   */
  formValid(form: FormGroup) {
    Object.values(form.controls).forEach(formControl => {
      formControl.markAsDirty();
      formControl.updateValueAndValidity();
    });
  }

  /**
   * 刷新表格的 checked 状态
   */
  refreshCheckedStatus(table) {
    table.isAllChecked = table.currentPageRows
      .filter(item => !item.disabled)
      .every(item => table.mapOfCheckedId[item.id]);
    table.isIndeterminate =
      table.currentPageRows.filter(item => !item.disabled).some(item => table.mapOfCheckedId[item.id]) &&
      !table.isAllChecked;
    table.checkedIds = Object.entries(table.mapOfCheckedId)
      .filter(([id, checked]) => checked)
      .map(([id]) => id);
  }

  /**
   * 全选 checkbox nzCheckedChange事件
   */
  checkAll(table, isAllChecked) {
    table.currentPageRows
      .filter(item => !item.disabled)
      .forEach(item => (table.mapOfCheckedId[item.id] = isAllChecked));
    this.refreshCheckedStatus(table);
  }

  /**
   * 格式化为 nz-tree 要求的数据结构
   * @param nodes
   * @param keyField
   * @param nameField
   */
  formatNodes(nodes, keyField = 'id', titleField = 'name') {
    const recursion = (arr, lv, parentNode) => {
      arr.forEach(node => {
        node.key = node[keyField];
        node.title = node[titleField];
        node.parent = parentNode;

        if (Array.isArray(node.children) && node.children.length) {
          recursion(node.children, ++lv, node);
        } else {
          node.isLeaf = true;
        }
      });
    };
    recursion(nodes, 1, null);

    return nodes;
  }

  /**
   * 通过key获取node
   * @param treeData tree 数据源
   * @param key
   */
  getNodeByKey(treeData: any[], key: string) {
    let node: any = {};
    const recursion = arr => {
      for (const item of arr) {
        if (item.key == key) {
          node = item;
          break;
        }
        if (Array.isArray(item.children)) {
          recursion(item.children);
        }
      }
    };
    recursion(treeData);
    return node;
  }

  /**
   * 获取所有叶子节点
   * @param nodes
   */
  getLeafNodes(nodes = []) {
    const list = [];
    const recursion = arr => {
      arr.forEach(node => {
        if (Array.isArray(node.children) && node.children.length) {
          recursion(node.children);
        } else {
          list.push(node);
        }
      });
    };
    recursion(nodes);

    return list;
  }

  /**
   * 树结构转数组
   */
  tree2Array(treeNodes = []) {
    const arr = [];
    treeNodes.forEach(node => {
      arr.push(node);
      if (Array.isArray(node.children) && node.children.length) {
        arr.push(...this.tree2Array(node.children));
      }
    });
    return arr;
  }

  /**
   * 过滤掉半选状态的节点
   * @param nodes
   * @param checkedKeys
   */
  filterIsHalfChecked(nodes: any[], checkedKeys: string[]) {
    // 要移除的半选 key 列表
    const keyList = [];

    // 是否存在未选中的子节点
    const hasUnCheckedChild = (nodes, checkedKey) => {
      for (const node of nodes) {
        if (node.key == checkedKey) {
          if (Array.isArray(node.children) && node.children.length) {
            const has = this.tree2Array(node.children).some(item => !checkedKeys.includes(item.key));
            if (has) {
              keyList.push(node.key);
            }
          }
          break;
        }
        if (Array.isArray(node.children) && node.children.length) {
          hasUnCheckedChild(node.children, checkedKey);
        }
      }
    };

    checkedKeys.forEach(checkedKey => hasUnCheckedChild(nodes, checkedKey));
    return checkedKeys.filter(key => !keyList.includes(key));
  }

  /**
   * 获取所有选中节点的列表
   * @param treeComponent nzTree组件
   * @param includeHalfChecked 是否包含半选状态
   */
  getCheckedNodeList(treeComponent, includeHalfChecked = false) {
    const nodes = treeComponent.getTreeNodes();
    const arr = this.tree2Array(nodes);
    const checkedNodeList = arr.filter(node => node.isChecked || (includeHalfChecked && node.isHalfChecked));
    return checkedNodeList;
  }
  /**
   * 获取地址列表
   * @param type
   * @param parentCode
   */
  getAddress(type = 'province', parentCode = '0') {
    if (parentCode == null) {
      return of([]);
    }

    const params = {
      name: type,
      parentCode,
    };
    return this.http
      .get<any>(`${_baseUrl}/basics/v1/districts/data`, { params, headers: { token: '' } })
      .pipe(
        map(res => {
          if (res.code == 0) {
            return res.data || [];
          } else {
            return [];
          }
        }),
      );
  }

  /**
   * 从列表获取地址对象
   * @param list
   * @param field
   * @param value
   */
  getAddressByField(list, field, value) {
    return list.find(v => v[field] == value) || {};
  }
  /**
   * 获取模块列表
   * @param type //1前端2后端
   * @param parentCode
   */
  getMoudleList(type = '1') {
    const params = {
      type,
    };
    return this.http
      .get<any>(`${moduleUrl}`, { params })
      .pipe(
        map(res => {
          if (res.code == 0) {
            return res.data || [];
          } else {
            return [];
          }
        }),
      );
  }
  // 更新OSS参数
  getOSSParams() {
    this.OSS_Params.loading = true;
    // let token = JSON.parse(window.localStorage.getItem('_token') || '{}').token || '';
    const token = JSON.parse(window.localStorage.getItem('UserInfo') || '{}').token;

    if (!token) return Promise.resolve(true);
    return this.http
      .get<any>(`${domain}/platform/v1/file/sts?access_token=${token}`)
      .toPromise()
      .then(
        // return this.http.get<any>(`/sts`).toPromise().then(
        res => {
          this.OSS_Params.loading = false;
          if (res.code == 0) {
            this.OSS_Params = res.data || {};
          }
        },
        () => {
          this.OSS_Params.loading = false;
        },
      );
  }
  // 定时更新OSS参数, 仅在 \src\app\layout\default\default.component.ts 中调用
  setOSSInterval() {
    (window as any).__zone_symbol__setInterval(() => {
      // 提前20分钟更新(1分钟检查一次)
      if (!this.OSS_Params.loading && +new Date(this.OSS_Params.expiration) - +new Date() < 20 * 60 * 1000) {
        this.getOSSParams();
      }
    }, 1 * 60 * 1000);
  }
  // 获取临时的 OSS URL 签名
  // OssSignatureUrl(path = '') {
  //   if (this.isUrl(path)) return path;

  //   // if (path.includes('.')) {
  //   //   path = path.slice(0, path.indexOf('.'));
  //   // }
  //   return this.OSS.signatureUrl(path, { expires: this.expirationTime })
  // }
  OssSignatureUrl(path = '', expirationTime = null) {
    if (this.isUrl(path)) return path;
    return this.OSS.signatureUrl(path, { expires: expirationTime || this.expirationTime });
  }
  OssNoSignatureUrl(path = '', expirationTime = null) {
    if (this.isUrl(path)) return path;
    return this.OSS.signatureUrl(path, { expires: expirationTime || this.expirationTime }).split('?')[0];
  }
  // 获取带文件后缀名的OSS路径
  getFilePath(path, file) {
    // if (path.includes('.')) return path;
    // let name = file.name || '';
    // return path + name.slice(name.lastIndexOf('.'));
    return path;
  }
  getFileType(file) {
    const name = file.name || '';
    return name.slice(name.lastIndexOf('.'));
  }

  /**
   * 是否是一个网址
   * @param url
   */
  isUrl(url: string = '') {
    return !!(url.startsWith('http://') || url.startsWith('https://'));
  }
  //网格员类型
  accountTypeGrid: any = [
    { label:'保险' , value:1},
    { label:'网格员', value:0},
    // { label:'政府监管', value:2}
  ]
  // 是否是视频 
  isVidio(data: string){
    const videolist = ['mp4', 'm2v', 'mkv', 'rmvb', 'wmv', 'avi', 'flv', 'mov', 'm4v'];
    if (videolist.includes(data)) {
      return true;
    }else{
      return false;
    }
}
}
