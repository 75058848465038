import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// delon
import { AlainThemeModule } from '@delon/theme';
import { DelonABCModule } from '@delon/abc';
import { DelonChartModule } from '@delon/chart';
import { DelonACLModule } from '@delon/acl';
import { DelonFormModule } from '@delon/form';
// i18n
import { TranslateModule } from '@ngx-translate/core';

// #region third libs
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { CountdownModule } from 'ngx-countdown';
import { UEditorModule } from 'ngx-ueditor';
import { NgxTinymceModule } from 'ngx-tinymce';
import { XTableModule } from './x-table/x-table.module';

import { UserSelectDialogComponent } from './user-select-dialog/user-select-dialog.component';
import { EchartsComponent } from './echarts/echarts.component';
import { UserSelectFromRoleTypeComponent } from './user-select-from-role-type/user-select-from-role-type.component';
import { DownloadExcelComponent } from './download-excel/download-excel.component';
import { SelectDownloadExcelComponent } from './select-download-excel/select-download-excel.component';
import { XlsUploadComponent } from './xls-upload/xls-upload.component';
import { UniqueAlterEgoValidatorDirective } from './alter-ego.directive';
// ueditor
import { BdUeditorComponent } from './bd-ueditor/bd-ueditor.component';

import { AmapShowComponent } from './components/amap-show/amap-show.component';

const THIRDMODULES = [NgZorroAntdModule, CountdownModule, UEditorModule, NgxTinymceModule, XTableModule];

// #region your componets & directives
const entryComponents = [
  // 用户选择对话框(在运营中心中选择用户)
  UserSelectDialogComponent,
  // 用户选择对话框(根据角色类型选择用户)
  UserSelectFromRoleTypeComponent,
];
const COMPONENTS = [
  ...entryComponents,
  EchartsComponent,
  DownloadExcelComponent, // Excel 导出
  XlsUploadComponent,
  UniqueAlterEgoValidatorDirective,
  BdUeditorComponent,
  SelectDownloadExcelComponent,
  AmapShowComponent,
];
const DIRECTIVES = [];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    AlainThemeModule.forChild(),
    DelonABCModule,
    DelonChartModule,
    DelonACLModule,
    DelonFormModule,
    // third libs
    ...THIRDMODULES,
  ],
  declarations: [
    // your components
    ...COMPONENTS,
    ...DIRECTIVES,
    DownloadExcelComponent,
    XlsUploadComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AlainThemeModule,
    DelonABCModule,
    DelonChartModule,
    DelonACLModule,
    DelonFormModule,
    // i18n
    TranslateModule,
    // third libs
    ...THIRDMODULES,
    // your components
    ...COMPONENTS,
    ...DIRECTIVES,
  ],
  entryComponents,
})
export class SharedModule {}
