import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzMessageService } from 'ng-zorro-antd';
import { CommonService, baseUrl } from '@core';
import { BaseCheckableTable } from '../tablebase/base.checkable.table.component';
import { format } from 'date-fns';
import { ContractEditComponent } from './edit/edit.component';

@Component({
  selector: 'app-businesscontract',
  templateUrl: './businesscontract.component.html',
  styleUrls: ['./businesscontract.component.less'],
})
export class BusinesscontractComponent extends BaseCheckableTable implements OnInit {
  activeTabIndex = 0;
  pageSize = 50;
  bussinessTypes = [
    {
      title: '半个月',
    },
    {
      title: '1个月',
    },
    {
      title: '2个月',
    },
    {
      title: '3个月',
    },
    {
      title: '半年',
    },
    {
      title: '1年',
    },
  ];
  bsTypes = [];
  projectTypes = [];
  allDisabledBussiness = false;
  // 是否显示已禁用业务和项目类型
  isBan: any = 0;
  bussinessPeriod = [
    {
      value: 0,
      title: '半年',
    },
    {
      value: 1,
      title: '1年',
    },
    {
      value: 2,
      title: '2年',
    },
    {
      value: 3,
      title: '3年',
    }
  ];
  get bussinessPeriodMap() {
    let obj = {};
    this.bussinessPeriod.forEach(item => obj[item.value] = item.title);
    return obj;
  }
  constructor(
    protected injector: Injector,
    public commonService: CommonService,
    private http: _HttpClient,
    private router: ActivatedRoute,
    private modal: ModalHelper,
  ) {
    super(injector);
  }

  async ngOnInit() {
    await this.getbsTypes();
    this.search();
  }
  getbsTypes() {
    const searchParams = {
      isBan: this.isBan,
    };
    this.http.get<any>(`${baseUrl}/property-detail/business-list`, searchParams).subscribe(res => {
      if (res.code === 0) {
        this.bsTypes = res.data || [];
        // this.searchParams.serviceTypeCode = this.bsTypes[0].code;
        // if (this.bsTypes.length > 0) {
        //   this.getProjectTypes(this.bsTypes[0].code);
        // }
      }
    });
  }
  getProjectTypes(code) {
    this.searchParams.projectTypeCode = null;
    let params = {
      parentCode: code,
      isBan: this.isBan,
    };
    this.http.get<any>(`${baseUrl}/property-detail/project-list`, params).subscribe(res => {
      if (res.code === 0) {
        this.projectTypes = res.data.data || [];
      }
      // this.searchParams.projectTypeCode = this.projectTypes[0].code;
    });
  }
  changeType(value) {
    // console.log(this.bsTypes.find(item => item.code == value).parentCode);

    this.getProjectTypes(value);
  }
  changeTabIndex(value) {
    this.pageIndex = 1;
    // this.searchParams = {};
    this.activeTabIndex = value.index;
    this.search();
  }
  /**
   * 地区企业
   * @param reset
   */
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    const searchParams = {
      ...this.searchParams,
      current: this.pageIndex,
      size: this.pageSize,
      beforeRemindDate: this.activeTabIndex,
      isBan: this.isBan,
    };
    if (this.searchParams.accTime && (this.searchParams.accTime.length != 0)) {
      searchParams.startEndDate = format(this.searchParams.accTime[0], 'YYYY-MM-DD');
      searchParams.endEndDate = format(this.searchParams.accTime[1], 'YYYY-MM-DD');

    } else {
      searchParams.startEndDate = '';
      searchParams.endEndDate = '';
    }
    if (searchParams.serviceTypeCode == null) {
      searchParams.serviceTypeCode = '';
    }
    if (searchParams.projectTypeCode == null) {
      searchParams.projectTypeCode = '';
    }
    delete searchParams.accTime;
    this.loading = true;

    this.http.get<any>(`${baseUrl}/project-info`, searchParams).subscribe(res => {
      if (res.code === 0) {
        this.rows = res.data.records || [];
        this.total = res.data.total || 0;
      }
      this.loading = false;
    });
  }
  /**
   * 显示已禁用业务与项目类型 切换事件
   */
  allDisabledBussinessChange(checked) {
    if (checked) {
      this.isBan = '';
    } else {
      this.isBan = 0;
    }
    console.log(this.searchParams);
    this.getbsTypes();
    this.search(true);
  }
  /**
   * 处理
   */
  openHandle(id:string){

    this.modal.create(ContractEditComponent,{ id },
        {
          size:500,
          modalOptions: {
            nzMaskClosable: false,
          },
        },
      )
      .subscribe(() => {
        this.search(true);
      });
  }
 
}
