import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, ViewChild, ElementRef, NgZone } from '@angular/core';

// import echarts from 'echarts';
const echarts = window['echarts'];

@Component({
  selector: 'app-echarts',
  templateUrl: './echarts.component.html',
  styleUrls: ['./echarts.component.css']
})
export class EchartsComponent implements OnInit, OnChanges {
  @ViewChild('mydiv', { static: true }) mydiv: ElementRef;
  @Input() option = null;
  @Input() notMerge = true; //默认, 不合并
  @Input() height = '400px';
  @Input() loading = false;
  @Output() initEchart = new EventEmitter<any>();

  myChart = null;

  constructor(private ngZone: NgZone) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.option) {
      if (changes.option.firstChange) {
        //首次变更 => 等待到下一个节拍 mychart 初始化完成
        setTimeout(() => {
          this.setOption();
        }, 0);
      } else {
        this.setOption();
      }
    }

    if (changes.loading) {
      if (changes.loading.firstChange) {
        setTimeout(() => {
          this.setLoading();
        }, 0);
      } else {
        this.setLoading();
      }
    }
  }

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      this.myChart = echarts.init(this.mydiv.nativeElement);
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
      this.initEchart.emit(this.myChart);
    });
  }

  setOption() {
    this.option && this.myChart.setOption(this.option, this.notMerge);
    this.myChart.resize();
  }

  setLoading() {
    if (this.loading) {
      this.myChart.showLoading('default', {
        text: '加载中',
        zlevel: 0
      });
    } else {
      this.myChart.hideLoading();
    }
  }



}
