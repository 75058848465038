import { AfterViewInit, Component, OnInit, ViewChild, Input } from '@angular/core';
import { NzFormatEmitEvent, NzTreeComponent, NzTreeNodeOptions, NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme'
import { uploadUrl, moduleAddUrl, moduleEditUrl, moduleByIDUrl, CommonService } from '@core';
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class ModuleeditComponent implements OnInit {

  //表单临时数据
  i: any = {
    name: '',
    logogram: '',
    file: []
  };
  //模块类型
  mtype: any;
  roleRadio: any;
  type: any;
  action: any;
  // 文件上传地址
  uploadAction = uploadUrl;
  fileList = [];

  constructor(
    private ref: NzModalRef,
    public msg: NzMessageService,
    public http: _HttpClient,
    private mod: ModalHelper, public commonService: CommonService) { }

  ngOnInit(): void {

    /**编辑时获取模块信息 */
    if (this.type == 'edit') {
      this.getModuleData();
    }
  }
  /**
   * 根据模块Id获取信息
   */
  getModuleData() {
    this.http.get<any>(`${moduleByIDUrl}/${this.roleRadio}`).subscribe(res => {
      console.log(res);
      if (res.code == 0) {
        this.i = { ...res.data || {} };
        let obj = {
          name: '',
          url: '',
          urlPath: ''
        };
        obj.url = this.commonService.OssSignatureUrl(this.i.icon);
        obj.urlPath = this.i.icon;
        let arr = [];
        arr.push(obj);
        this.i.file = arr
      }

    })
  }
  close(res = null) {
    this.ref.destroy(res);
  }
  /**
     * 文件上传
     */
  beforeUpload = (file): boolean => {
    let OSS = this.commonService.OSS;
    let path = this.commonService.path + this.commonService.getFileType(file);
    OSS.put(path, file).then((res) => {
      this.i.file = [
        ...this.i.file,
        {
          uid: this.i.file.length,
          status: 'done',
          name: file.name,
          url: this.commonService.OssSignatureUrl(path),
          urlPath: this.commonService.getFilePath(path, file)
        }

      ];
    }).catch(() => {
      this.msg.error('文件上传不成功!');
    });
    return false;
  }
  /**
   * 模块保存
   */
  save() {

    // if (JSON.stringify(this.i.file) != "[]" && (JSON.stringify(this.i.file) != 'undefined')) {
    //附件图片
    if (this.i.file && (this.i.file.length != 0)) {
      // this.i.file = this.i.file.filter(file => file.status == 'done').map(file => ({ fileName: file.name, urlPath: file.urlPath }));
      // if (!this.i.file.length) {
      //   this.msg.info('请上传附件!');
      //   return;
      // }
      //取第一张图片url
      this.i.icon = this.i.file[0].urlPath;
    }
    let params = {
      ...this.i,
      type: this.mtype,
    }
    if (this.type == "edit") {
      let eparams = {
        ...params,
        id: this.roleRadio
      };
      this.http.put<any>(`${moduleEditUrl}`, eparams).subscribe(res => {
        if (res.code == 0) {
          this.msg.success(res.msg || '编辑成功');
          this.close(res);
        } else {
          this.msg.error(res.msg || '加载失败');
        }
      });
    } else {
      let eparams = {
        ...params,
        id: 0
      };
      this.http.post<any>(`${moduleAddUrl}`, eparams).subscribe(res => {
        if (res.code == 0) {
          this.msg.success(res.msg || '保存成功');
          this.close(res);
        } else {
          this.msg.error(res.msg || '加载失败');
        }
      });
    }

  }



}
