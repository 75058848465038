import { Component, OnInit } from '@angular/core';
import { CommonService, baseUrl } from '@core';
import { format } from 'date-fns';
import { _HttpClient } from '@delon/theme';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';


@Component({
  selector: 'app-supplierjoin',
  templateUrl: './supplierjoin.component.html',
  styleUrls: ['./supplierjoin.component.less']
})
export class SupplierjoinComponent implements OnInit {
  baseUrl = baseUrl;
  searchParams: any = {};
  loading = false;
  rows = [];
  editDialog = {
    display: false,
    loading: false,
    data: {} as any,
    form: null as FormGroup,
  }

  // 省市区
  provinceList = [];
  cityList = [];
  districtList = [];

  pageIndex = 1;
  pageSize = 20;
  total;

  constructor(public commonService: CommonService, private http: _HttpClient, private fb: FormBuilder, private message: NzMessageService) {
    this.commonService.getAddress('province', '0').subscribe(list => (this.provinceList = list));
  }

  ngOnInit() {
    this.search();
    this.initEditDialogForm();
  }
  initEditDialogForm() {
    this.editDialog.form = this.fb.group({
      remark: [null],
      status: [null]
    });
  }

  /**
  * 地址选择
  * @param next = { dataField: 数据源, type: 类型 }
  * @param code
  */
  addressChange(next, code, type = false) {
    this.commonService.getAddress(next.type, code).subscribe(list => (this[next.dataField] = list));
  }

  search() {
    let searchParams = {
      ...this.searchParams,
      current: this.pageIndex,
      size: this.pageSize,
    }
    if (this.searchParams.accTime && (this.searchParams.accTime.length != 0)) {
      searchParams.startDay = format(this.searchParams.accTime[0], 'YYYY-MM-DD');
      searchParams.endDay = format(this.searchParams.accTime[1], 'YYYY-MM-DD');

    }
    delete searchParams.accTime;

    if (this.searchParams.status == null) {
      searchParams.status = ''
    }
    if (this.searchParams.key == null) {
      searchParams.key = ''
    }
    if (this.searchParams.provinceCode == null) {
      searchParams.provinceCode = ''
    }
    if (this.searchParams.cityCode == null) {
      searchParams.cityCode = ''
    }
    if (this.searchParams.districtCode == null) {
      searchParams.districtCode = ''
    }

    console.log(searchParams)
    this.loading = true;
    this.http.get<any>(`${baseUrl}/supplier-join/page`, searchParams).subscribe(res => {
      this.loading = false;
      console.log(res)
      if (res.code === 0) {
        this.rows = res.data.records || [];
        this.total = res.data.total;
      }

    });
  }

  /**
   * 打开管理对话框
   */
  onMangent(data = null) {
    this.editDialog.display = true;
    this.editDialog.data = data;
    this.editDialog.form.reset();
    this.editDialog.form.enable();
    console.log(data)
    if (data) {
      this.editDialog.form.patchValue(data);
    }
  }

  /**
   * 对话框提交
   */
  onSubmitEditDialog() {
    this.commonService.formValid(this.editDialog.form);
    if (!this.editDialog.form.valid) {
      return;
    };
    let data = {
      ...this.editDialog.form.value,
      id: this.editDialog.data.id
    };
    this.loading = true;
    this.http.get<any>(`${baseUrl}/supplier-join/deal`, data).subscribe(res => {
      this.loading = false;
      console.log(res)
      if (res.code === 0) {
        this.message.success(res.msg || '修改成功');
        this.editDialog.display = false;
        this.search();
      }
    });
  }
  /**
 * 删除
 */
  remove(id: any) {
    this.http.delete<any>(`${baseUrl}/supplier-join/${id}`).subscribe(res => {
      this.loading = false;
      if (res.code === 0) {
        this.message.success(res.msg || '删除成功');
        this.search();
      }

    });
  }
}
