
import { Component, ChangeDetectorRef, OnInit, } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { _HttpClient } from '@delon/theme';
import { NzMessageService } from 'ng-zorro-antd';
import { baseUrl } from '@core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-personinfo',
  templateUrl: './personinfo.component.html',
  styleUrls: ['./personinfo.component.less']
})
export class PersoninfoComponent implements OnInit {



  constructor(public msg: NzMessageService,
    private http: _HttpClient,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef, private router: Router) { }

  ngOnInit() {
    this.validateForm = this.fb.group({
      newPwd: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(15)]],
      oldPwd: [null, [Validators.required]],
      checkPassword: [null, [Validators.required, this.confirmationValidator]],
    });
  }
  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm.controls.newPwd.value) {
      return { confirm: true, error: true };
    }
    return {};
  };
  get passWord() {
    return this.validateForm.controls.newPwd;
  }

  validateForm: FormGroup;

  /**
   * 提交
   */
  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }


    let data = {
      ...this.validateForm.value
    };
    //验证旧密码
    console.log(data);
    let nData = {
      newPassword: data.newPwd
    };
    let vData = {
      oldPassword: data.oldPwd
    };
    this.http.get(`${baseUrl}/users/verifyPassword`, vData).subscribe((res: any) => {
      if (res.code == 0) {
        if (res.data == null) {
          this.http.get(`${baseUrl}/users/updatePassword`, nData).subscribe((nres: any) => {
            if (nres.code == 0) {
              this.msg.success(nres.msg || '密码修改成功');
              setTimeout(() => {
                let $that = this;
                $that.router.navigateByUrl(`/dashboard`);
              }, 1500)
            }
          })
        }

      }
    });

  }
  /**
   * 取消重置密码
   */
  cancel() {
    this.router.navigateByUrl(`/dashboard`);
  }
}

