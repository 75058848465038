import { Directive, ElementRef, Input } from '@angular/core';

/**
 * 为元素设置html属性
 */

@Directive({
  selector: '[attr]'
})
export class AttrDirective {
  @Input() attr = null;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    if(this.attr) {
      Object.entries(this.attr).forEach(([key, value]) => {
        this.elementRef.nativeElement.setAttribute(key, value);
      });
    }
  }

}
