import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';

import { _HttpClient } from '@delon/theme';
import { CommonService, baseUrl } from '@core';

/**
 * 在组织机构中选择用户
 */

@Component({
  selector: 'app-user-select-dialog',
  templateUrl: './user-select-dialog.component.html',
  styleUrls: ['./user-select-dialog.component.less'],
})
export class UserSelectDialogComponent implements OnInit {
  baseUrl = baseUrl;

  //选中的行
  @Input() checkedRows = [];
  //多选?
  @Input() chkMultiple = true;

  /**
   * tree
   */
  opLoading = false;
  opNodes = [];
  //当前选中的节点
  selectNode: any = {};
  searchValue = '';

  /**
   * table
   */
  table = {
    searchParams: {} as any,
    loading: false,
    columns: [
      [
        { title: '序号', field: (row, column, rowIterator) => rowIterator.index + 1 },
        { title: '用户名', field: 'userName' },
        { title: '姓名', field: 'name' },
        { title: '所属组织', field: 'organizationName' },
        { title: '所属组织机构', field: 'opName' },
      ],
    ],
    // sendCallback: (params) => ({ ...params, ...this.table.searchParams, opId: this.selectNode.key }),
    sendCallback: params => ({ ...params, ...this.table.searchParams }),
    checked: row => !!this.checkedRows.find(v => v.id == row.id),
  };
  @ViewChild('XTable', { static: false }) XTable = null;

  constructor(
    private modalService: NzModalService,
    private fb: FormBuilder,
    private message: NzMessageService,
    private http: _HttpClient,
    public commonService: CommonService,
  ) {}

  ngOnInit() {
    this.loadOpNodes();
  }

  /**
   * 加载 组织机构
   */
  loadOpNodes() {
    this.opLoading = true;
    this.http.get(`${baseUrl}/operationCenter/list`).subscribe(res => {
      this.opLoading = false;
      if (res.code == 0) {
        this.opNodes = this.commonService.formatNodes(res.data || [], 'id');
        //默认选中第一个节点
        if (this.opNodes.length) {
          this.selectNode = this.opNodes[0];
        }
      }
    });
  }

  /**
   * 节点选中事件 => 加载对应的表格数据
   */
  onSelectNode(node) {
    if (node.key != this.selectNode.key) {
      this.selectNode = node;
      this.XTable.reload(true);
    }
    this.selectNode = {};
    setTimeout(() => {
      this.selectNode = node;
    }, 0);
  }

  /**
   * table checkbox 选择事件
   */
  checkboxChange({ checked, rows }) {
    if (checked) {
      this.checkedRows.push(...rows.filter(v => !this.checkedRows.find(checkedRow => checkedRow.id == v.id)));
    } else {
      rows.forEach(v =>
        this.checkedRows.splice(
          this.checkedRows.findIndex(checkedRow => checkedRow.id == v.id),
          1,
        ),
      );
    }
  }
}
