import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { baseUrl } from '@core';
import { NzTreeComponent, NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less'],
})
export class BussinessEditComponent implements OnInit {
  //数据记录
  i: any = {
    remindPeople: [],
    projectPeriod: '',
    startSms: false,
    startMsg: false,
  };
  roleRadio: any;
  checkedIds = [];
  loading = false;
  searchParams: any = {};

  type: any;

  action: any;
  checkOptionsOne = [
    { label: '企业负责人', value: '0', checked: false },
    { label: '商务提醒人', value: '1', checked: false },
    { label: '合同签订人', value: '2', checked: false },
  ];
  constructor(
    private ref: NzModalRef,
    public msg: NzMessageService,
    public http: _HttpClient,
    private mod: ModalHelper,
  ) { }

  ngOnInit(): void {

  }

  log(list): void {
    this.i.remindPeople = (list || []).filter(item => item.checked).map(v => v.value).join(',');
  }
  save() {
    if (this.i.remindPeople.length < 1) {
      this.msg.error('提醒人不能为空！');
      return;
    }
    const params = {
      ...this.i,
      serviceTypeCode: this.roleRadio, // 选择的业务类型
      projectTypeCodes: this.checkedIds, // 选择的项目projectTypeCodes
    };
    params.startSms = Number(params.startSms);
    params.startMsg = Number(params.startMsg);
    this.loading = true;

    this.http.put<any>(`${baseUrl}/business-remind-setting/batch`, params).subscribe(res => {
      this.loading = false;
      if (res.code == 0) {
        this.msg.success(res.msg || '保存成功');
        this.close(res);
      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });
  }

  close(res = null) {
    this.ref.destroy(res);
  }
}
