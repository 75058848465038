import { SettingsService, _HttpClient } from '@delon/theme';
import { Component, OnDestroy, Inject, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  ValidationErrors,
  AbstractControl,
  AsyncValidatorFn,
} from '@angular/forms';
import { catchError, map } from 'rxjs/operators';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { SocialService, SocialOpenType, ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { ReuseTabService } from '@delon/abc';
import { environment, baseUrl } from '@env/environment';
import { StartupService } from '@core';
import { LazyService } from '@delon/util';
import { Observable, Observer } from 'rxjs';

import { UniqueAlterEgoValidator } from '../../../shared/alter-ego.directive';
import { CommonService } from '@core';

declare var CryptoJS: any;

@Component({
  selector: 'passport-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
  providers: [SocialService],
})
export class UserLoginComponent {
  loading = false;
  bgUrl = '';
  errorMsg = '';
  rightName = '';

  obj: any = {};
  constructor(
    fb: FormBuilder,
    modalSrv: NzModalService,
    private router: Router,
    private settingsService: SettingsService,
    private socialService: SocialService,
    @Optional()
    @Inject(ReuseTabService)
    private reuseTabService: ReuseTabService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private startupSrv: StartupService,
    public http: HttpClient,
    public msg: NzMessageService,
    private lazy: LazyService,
    private alterEgoValidator: UniqueAlterEgoValidator,
    private commonService: CommonService,
  ) {
    this.lazy.loadScript('assets/js/crypto-js.js');

    this.form = fb.group({
      // userName: ['', [Validators.required, this.confirmationValidator]],
      // userName: new FormControl('', Validators.compose([Validators.required]), Validators.composeAsync([this.checkUser()])),
      userName: new FormControl('', {
        asyncValidators: [this.alterEgoValidator.validate.bind(this.alterEgoValidator)],
        updateOn: 'blur',
      }),

      password: ['', Validators.required],
    });
    modalSrv.closeAll();
    // this.getBackgroundImage();
    // this.getBackgroundImageFromPixabay();
  }

  form: FormGroup;

  submit() {
    this.errorMsg = '';
    Object.values(this.form.controls).forEach(formControl => {
      formControl.markAsDirty();
      formControl.updateValueAndValidity();
      if (formControl.invalid) return;
    });
    const formData = this.form.value;
    const headers = {
      Authorization: 'Basic eXVrb246eXVrb24',
      token: '', // 标识该请求不需要Token,
      autoShowErrorMsg: '', // 不弹出错误提示
    };

    const user = this.encryption({
      data: this.form.value,
      key: 'youkon0123456789',
      param: ['password'],
    });

    const enCodePwd = encodeURIComponent(user.password);

    this.loading = true;

    this.http
      .get<any>(
        `${baseUrl}/platform-auth/oauth/token?password=${formData.password}&grant_type=password&username=${formData.userName}`,
        { headers },
      )
      .subscribe((res: any) => {
        // this.http.get<any>(`${baseUrl}/platform-auth/oauth/token?password=${enCodePwd}&grant_type=password&username=${user.userName}`, { headers }).subscribe((res: any) => {
        this.loading = false;
        if (res.code == 0) {
          // 存储 Token
          res.token = res.access_token;
          window.localStorage.setItem('UserInfo', JSON.stringify(res || {}));

          this.startupSrv.checkToken(res).then(() => {
            const loginBeforeUrl = this.commonService.loginBeforeUrl || '/';
            this.router.navigateByUrl(loginBeforeUrl).then(() => {
              this.commonService.loginBeforeUrl = null;
            });
          });
        } else {
          this.errorMsg = res.msg;
          return;
        }
      });
  }

  /**
   * 从 pexels 获取图像
   */
  getBackgroundImage() {
    this.http
      .get<any>(`https://api.pexels.com/v1/curated?per_page=1&page=${this.selectRandom()}`, {
        headers: {
          Authorization: '563492ad6f91700001000001ec3462b728124a68a490c439ea8f59f0',
          token: '', // 标识该接口不需要token
          autoShowErrorMsg: '',
        },
      })
      .subscribe(res => {
        try {
          this.bgUrl = `${res.photos[0].src.original}?auto=compress&cs=tinysrgb&fit=crop&h=1080&w=1920`;
        } catch (error) {}
      });
  }

  /**
   * 从 pixabay 获取图像
   */
  getBackgroundImageFromPixabay() {
    const params = {
      key: '13677975-9446aee2a3641b05da65434b3',
      // 页码
      page: this.selectRandom(1, 150).toString(),
      // 每页最大小
      per_page: '3',
      // 分类: fashion, nature, backgrounds, science, education, people, feelings, religion, health, places, animals, industry, food, computer, sports, transportation, travel, buildings, business, music
      category: 'computer,science,business',
      // 获奖作品
      editors_choice: 'true',
      // 热门
      order: 'popular',
      // 横屏
      orientation: 'horizontal',
      min_width: '1920',
      min_height: '1080',
    };
    this.http
      .get<any>(`https://pixabay.com/api`, { params, headers: { token: '', autoShowErrorMsg: '' } })
      .subscribe(res => {
        if (res.code == 100) {
          this.bgUrl = `./assets/images/bg${this.selectRandom(1, 3)}.png`;
        } else {
          this.bgUrl = res.hits[this.selectRandom(0, 2)].largeImageURL;
        }
      });
  }

  /**
   * 获取随机整数
   * @param lowValue
   * @param highValue
   */
  selectRandom(lowValue = 0, highValue = 1000) {
    const choice = highValue - lowValue + 1;
    return Math.floor(Math.random() * choice + lowValue);
  }

  encryption(params) {
    let { data, type, param, key } = params;
    const result = JSON.parse(JSON.stringify(data));
    if (type === 'Base64') {
      param.forEach(ele => {
        result[ele] = btoa(result[ele]);
      });
    } else {
      param.forEach(ele => {
        const data = result[ele];
        key = CryptoJS.enc.Latin1.parse(key);
        const iv = key;
        // 加密
        const encrypted = CryptoJS.AES.encrypt(data, key, {
          iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.ZeroPadding,
        });
        result[ele] = encrypted.toString();
      });
    }
    return result;
  }
}
