import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';

import { _HttpClient } from '@delon/theme';
import { CommonService, baseUrl } from '@core';

@Component({
  selector: 'app-combo',
  templateUrl: './combo.component.html',
  styleUrls: ['./combo.component.less']
})
export class ComboComponent implements OnInit {
  baseUrl = baseUrl;

  table = {
    searchParams: {} as any,
    loading: false,
    columns: [[
      { title: '序号', field: (row, column, rowIterator) => rowIterator.index + 1 },
      { title: '套餐名称', field: 'name' },
      {
        title: '状态',
        field: (row) => `<span style="color: ${row.status == 1 ? 'green' : 'red'};">${(this.commonService.comboStatusList.find(v => v.value == row.status) || {}).label}</span>`
      },
      // { title: '套餐权限', width: 350, field: null },
      { title: '描述', field: 'setMealDescribe' },
      { title: '备注', field: 'memo' },
      { title: '操作', field: null, fixed: 'right' },
    ]],
    sendCallback: (params) => ({ ...params, ...this.table.searchParams })
  };
  @ViewChild('XTable', { static: false }) XTable = null;
  @ViewChild('btnTemp', { static: true }) btnTemp = null;
  // @ViewChild('appTemp', { static: true }) appTemp = null;

  /**
   * 编辑对话框
   */
  editDialog = {
    display: false,
    loading: false,
    data: <any>{},
    form: <FormGroup>null,
  };

  /**
   * 套餐权限 编辑对话框
   */
  roleEditDialog = {
    display: false,
    loading: false,
    data: <any>{},
    //应用列表
    appList: []
  };

  constructor(private modalService: NzModalService, private fb: FormBuilder, private message: NzMessageService, private http: _HttpClient, public commonService: CommonService) {
    this.initEditDialogForm();
    this.loadAppList();
  }

  ngOnInit() {
    // this.table.columns[0][3].field = this.appTemp;
    this.table.columns[0][this.table.columns[0].length - 1].field = this.btnTemp;
    this.table.columns = [...this.table.columns];
  }

  /**
   * 加载 App 列表
   */
  loadAppList() {
    this.http.get<any>(`${baseUrl}/application/list`, { type: '1' }).subscribe(res => {
      if (res.code == 0) {
        this.roleEditDialog.appList = (res.data || []).map(v => ({ label: v.name, value: v.id }));
      }
    });
  }

  initEditDialogForm() {
    this.editDialog.form = this.fb.group({
      id: [null],
      name: [null, [Validators.required]],
      status: [null, [Validators.required]],
      setMealDescribe: [null],
      memo: [null],
    });
  }

  /**
   * 打开 编辑 对话框
   */
  onOpenEditDialog(data = null, isView = false) {
    this.editDialog.display = true;
    this.editDialog.data = data;
    this.editDialog.form.reset();

    if (data) {
      let _data = {
        ...data,
      };
      this.editDialog.form.patchValue(_data);
    } else {
      this.editDialog.form.patchValue({
        status: 1,
      });
    }
  }

  /**
   * 对话框 提交
   */
  onSubmitEditDialog() {
    this.commonService.formValid(this.editDialog.form);

    if (!this.editDialog.form.valid) {
      return;
    }

    let data = {
      ...this.editDialog.form.value
    };

    if (this.editDialog.data) {
      this.editDialog.loading = true;
      this.http.put<any>(`${baseUrl}/setMeal/updateById`, data).subscribe(res => {
        this.editDialog.loading = false;
        if (res.code == 0) {
          this.editDialog.display = false;
          this.message.success('操作成功');
          this.XTable.reload();
        }
      });
    } else {
      this.editDialog.loading = true;
      this.http.post<any>(`${baseUrl}/setMeal/save`, data).subscribe(res => {
        this.editDialog.loading = false;
        if (res.code == 0) {
          this.editDialog.display = false;
          this.message.success('操作成功');
          this.XTable.reload();
        }
      });
    }
  }

  /**
   * 打开 权限编辑 对话框
   */
  onOpenRoleEditDialog(data = null) {
    this.roleEditDialog.display = true;
    this.roleEditDialog.data = data;
    //加载这个套餐的权限
    this.roleEditDialog.loading = true;
    this.http.get(`${baseUrl}/setMealApplocation/list`, { setMealId: data.id }).subscribe(res => {
      this.roleEditDialog.loading = false;
      if (res.code == 0) {
        this.roleEditDialog.appList.forEach(item => {
          item.checked = !!(res.data || []).find(v => v.applicationId == item.value);
        });
      }
    });
  }

  /**
   * 权限编辑 对话框 提交
   */
  onSubmitRoleEditDialog() {
    let data = {
      "applicationIds": this.roleEditDialog.appList.filter(v => v.checked).map(v => v.value),
      "setMealId": this.roleEditDialog.data.id
    };

    this.roleEditDialog.loading = true;
    this.http.post<any>(`${baseUrl}/setMealApplocation/save`, data).subscribe(res => {
      this.roleEditDialog.loading = false;
      if (res.code == 0) {
        this.roleEditDialog.display = false;
        this.message.success('操作成功');
        this.XTable.reload();
      }
    });
  }

  /**
   * 删除
   */
  onDelete(data) {
    let params = {
      delName: data.name,
    }
    this.http.delete(`${baseUrl}/setMeal/${data.id}`, params).subscribe(res => {
      if (res.code == 0) {
        this.message.success('操作成功');
        this.XTable.reload();
      }
    });
  }

}


