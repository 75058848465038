import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  Input,
  NgZone,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { LazyService } from '@delon/util';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzMessageService } from 'ng-zorro-antd';

import { domain } from '@core';

import * as moment from 'moment';

declare var AMap: any; // 高德地图
declare var $: any;

@Component({
  selector: 'app-amap-show',
  templateUrl: './amap-show.component.html',
  styleUrls: ['./amap-show.component.less'],
})
export class AmapShowComponent implements OnInit {
  loading = false;

  @Input() x: number;
  @Input() y: number;
  @Input() isenlarge: string;
  @Input() height: any;
  @Input() issmall: any;
  @Input() companyId: string;

  // 弹窗上传点击确定 上传参数是 ： pickedRec.getPath()  和 图片 和 楼层标记 floor
  map: any = {};
  amapParams: any = {
    paths: '',
    bounds: {},
    loadPolygon: {},
    imageLayer: {},
    pickedRec: {},
    factoryMenu: {},
    floorId: '',
    belongFloor: '',
    textlocation: '',
    textname: '',
    districtId: '',
    currentPlogy: '',
    exdata: {
      id: '',
      districtId: '',
    },
    factory: null,
    mouseTool: {},
    floores: [{ value: 'F1' }, { value: 'F2' }, { value: 'F3' }],
    hasFactory: null,
    infoWindow: null,
  };

  auto: any = {};

  entInfo: any = {};

  outHeight = '0px';

  floorValue = 'Z1';
  selectCheck: any = null;

  floorList: any = [];

  // 逾期未整改隐患列表  对话框
  yqDialog = {
    display: false,
    rows: [],
  };

  // tenant_id: any = JSON.parse(window.localStorage.getItem('_token') || '{}').tenant_id;

  oldAreaCode: any = new Set();

  constructor(
    private lazy: LazyService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private http: _HttpClient,
    private modal: ModalHelper,
    public msg: NzMessageService,
  ) {}

  enlarge() {
    this.router.navigateByUrl('/amapfull');
  }

  ensmall() {
    window.history.go(-1);
  }

  ngOnInit() {
    this.getFloor();

    this.lazy.loadStyle('https://a.amap.com/jsapi_demos/static/demo-center/css/demo-center.css');

    this.lazy
      .loadScript(
        'https://webapi.amap.com/maps?v=1.4.14&key=9b6aa8c00d6ef8ea8fd14d082df78eb4&plugin=AMap.MouseTool,AMap.ControlBar,AMap.RectangleEditor,AMap.Autocomplete',
      )
      .then(() => {
        this.init(this.floorValue);
      });
  }

  init(floorValue) {
    const $that = this;

    $that.map = new AMap.Map('container', {
      center: [116.33719, 39.942384],
      zoom: 10,
      expandZoomRange: true,
      zooms: [3, 20],
      mapStyle: 'amap://styles/macaron',
      resizeEnable: true,
    });

    $that.amapParams.mouseTool = new AMap.MouseTool(this.map);

    AMap.plugin(['AMap.Autocomplete', 'AMap.PlaceSearch'], function() {
      const autoOptions = {
        city: '北京', // 城市，默认全国
        input: 'keyword', // 使用联想输入的input的id
      };
      const autocomplete = new AMap.Autocomplete(autoOptions);
      const placeSearch = new AMap.PlaceSearch({
        city: '北京',
        map: $that.map,
      });
      AMap.event.addListener(autocomplete, 'select', function(e) {
        placeSearch.setCity(e.poi.adcode);
        placeSearch.search(e.poi.name);
      });
    });

    $that.map.on('zoomchange', () => {
      const zoomLevel = $that.map.getZoom();
      if (zoomLevel >= 15) {
        this.map.getAllOverlays('text').forEach(marker => {
          marker.show();
        });
        $that.maprange(floorValue);
      } else {
        this.map.getAllOverlays('text').forEach(marker => {
          marker.hide();
        });
      }
    });
    $that.map.on('dragend', function() {
      const zoomLevel = $that.map.getZoom();
      if (zoomLevel >= 15) {
        $that.maprange(floorValue);
      }
    });

    $that.amapParams.belongFloor = floorValue;

    // 菜单
    const contextMenu = new AMap.ContextMenu();
    contextMenu.addItem(
      '下载四色图',
      () => {
        // console.log(window['currentImageLayer']);
        this.download();
      },
      0,
    );

    // 总览数据获取
    $that.getZonglan();

    this.loading = true;
    $that.http
      .get(`${domain}/basics/v1/floors?floorName=${floorValue}`, { companyId: this.companyId })
      .subscribe((response: any) => {
        this.loading = false;
        const floorBase = response.data;
        $that.amapParams.hasFactory = response.data;
        if (typeof floorBase == 'undefined' || floorBase == null) {
          // 加载地图右键绘制厂区功能
          // $that.paintFactory();
        } else {
          for (let i = 0; i < floorBase.length; i++) {
            $that.amapParams.floorId = floorBase[i].id;

            $that.amapParams.paths = JSON.parse(floorBase[i].bounds);
            const lng = [];
            const lat = [];

            for (let p = 0, len = $that.amapParams.paths.length; p < len; p++) {
              lng.push($that.amapParams.paths[p][0]);
              lat.push($that.amapParams.paths[p][1]);
            }

            lng.sort(function(a, b) {
              return a - b;
            });
            lat.sort(function(a, b) {
              return a - b;
            });

            $that.amapParams.bounds = new AMap.Bounds([lng[0], lat[0]], [lng[lng.length - 1], lat[lat.length - 1]]);
            $that.map.setCenter($that.amapParams.bounds.getCenter());
            $that.amapParams.loadPolygon = new AMap.Polygon({
              strokeColor: 'rgba(0,0,0,0)',
              strokeOpacity: 0.2,
              strokeWeight: 0.8,
              strokeDasharray: [30, 10],
              // strokeStyle还支持 solid
              strokeStyle: 'dashed',
              fillOpacity: 0,
              path: $that.amapParams.paths,
              map: $that.map,
            });

            // 图片图层
            $that.amapParams.imageLayer = new AMap.ImageLayer({
              url: floorBase[i].imgUrl,
              zIndex: 100,
              bounds: $that.amapParams.bounds,
              map: $that.map,
              zooms: [3, 20],
              visible: true,
            });
            $that.amapParams.imageLayer.districtName = floorBase[i].districtName;

            // 图片图层覆盖物(大小相等, 用于响应右键菜单事件)
            const rectangleBg = new AMap.Rectangle({
              bounds: $that.amapParams.bounds,
              strokeOpacity: 0,
              fillOpacity: 0,
              extData: $that.amapParams.imageLayer,
              bubble: true,
            });
            $that.map.add(rectangleBg);

            // 在 图片图层覆盖物 打开右键菜单
            rectangleBg.on('rightclick', function(e) {
              // 保存 当前图片图层
              (window as any).currentImageLayer = e.target.getExtData();
              contextMenu.open($that.map, e.lnglat);
            });

            const marker = new AMap.Marker({
              size: new AMap.Size(25, 34),
              position: $that.amapParams.paths[0],
              direction: 'center',
              offset: new AMap.Pixel(-13, -30),
              imageSize: new AMap.Size(25, 34),
            });
            marker.on('click', function(e) {
              $that.map.setZoomAndCenter(16, e.lnglat);
            });
            marker.setIcon('./assets/poi-marker-red.png');
            marker.setTitle(floorBase[i].districtName);
            marker.setMap($that.map);
            const floorObj = { img: $that.amapParams.imageLayer, id: floorBase[i].id };
            $that.amapParams.loadPolygon.setExtData(floorObj);
          }
          $that.map.setFitView();
        }
      });

    $('.ent-situation').on('mouseover', function(e) {
      const pos = $that.mousePosition(e);
      $('#ent-info').css({
        display: 'block',
      });
      $('.content-window-card').css({
        top: pos.y + 20,
        left: pos.x - 480 + 10,
      });
    });

    $('.ent-situation').on('mouseout', function(e) {
      $('#ent-info').css({
        display: 'none',
      });
    });
  }

  maprange(floorValue) {
    const $that = this;
    const lnglat = $that.map.getCenter();
    $that.http
      .get(`${domain}/basics/v1/floors/code`, {
        companyId: this.companyId,
        floor: floorValue,
        longitude: lnglat.lng,
        latitude: lnglat.lat,
      })
      .subscribe((res: any) => {
        $that.getArea(res.data);
      });
  }

  getArea(codes) {
    const $that = this;
    const areaCodes = new Set(codes);
    areaCodes.forEach(x => {
      if ($that.oldAreaCode.has(x)) {
        areaCodes.delete(x);
      } else {
        $that.oldAreaCode.add(x);
      }
    });
    // codes 返回厂区 code  []  一个code代表一个厂区
    codes = Array.from(areaCodes);
    if (codes.length > 0) {
      $that.http
        .get(`${domain}/basics/v1/district/details/overlays?floorName=${$that.amapParams.belongFloor}`, {
          companyId: this.companyId,
        })
        .subscribe((response: any) => {
          const on = response.data;
          if (on.count <= 0) {
            // 无覆盖物信息
          } else {
            // 加载覆盖物信息 同时渲染颜色--添加右键删除功能
            const overlayList = on.overlayList;

            codes.forEach(code => {
              $that.reqOss(overlayList, $that, code);
            });
          }
        });
    }
  }
  // oss请求 area color
  reqOss(overlayList, $that, code) {
    $that.http
      .get(`${domain}/basics/v1/file/url`, {
        // fileName: `${$that.tenant_id}/${$that.floorValue}/${code}/color-map.json`,
        fileName: `${this.companyId}/${$that.floorValue}/${code}/color-map.json`,
        companyId: this.companyId,
      })
      .subscribe((res: any) => {
        if (res.code == 0) {
          $that.getAreaColor(res, overlayList, $that, code);
        }
      });
  }
  // 服务端请求 area color
  reqServer(overlayList, $that, code) {
    $that.http
      .post(`${domain}/hazard/v1/regulatoryscreens/floor/color`, {
        floorId: code,
        floorName: $that.floorValue,
        company: this.companyId,
      })
      .subscribe((res: any) => {
        this.loading = false;
        if (res.code == 0) {
          $that.renderColor(res, overlayList, $that);
        }
      });
  }

  // oss 返回 fileUrl 地址，请求area color
  getAreaColor(res, overlayList, $that, code) {
    $that.http.get(`${res.data.fileUrl}`).subscribe((res: any) => {
      this.loading = false;
      if (res.code == 100) {
        $that.reqServer(overlayList, $that, code);
        return;
      }
      const ossColor = {
        data: res,
      };
      $that.renderColor(ossColor, overlayList, $that);
    });
  }
  // 渲染 area color
  renderColor(res, overlayList, $that) {
    console.log(res);
    delete res.data[0];
    console.log(res);
    Object.keys(res.data).forEach(function(key) {
      for (let i = 0; i < overlayList.length; i++) {
        const lay = overlayList[i];
        let floorType = lay.code;
        if ($that.floorValue !== 'Z1') {
          floorType = lay.districtId;
        }
        if ((lay.type == 'polygon' || lay.type == 'point') && key == floorType) {
          $that.loadOverlay(
            lay.overlay,
            lay.id,
            lay.districtId,
            $that.getColor(res.data[key].color),
            lay.name,
            res.data[key],
            key,
          );
        } else if (lay.type == 'circle' && key == floorType) {
          $that.loadOverlayCircle(
            lay.overlay,
            lay.id,
            lay.districtId,
            $that.getColor(res.data[key].color),
            res.data[key],
            lay.name,
          );
        } else {
          // console.info("不支持的覆盖物类型！" + lay.type);
        }
      }
    });
  }

  getColor(num) {
    const colors = ['#FF0000', '#FF8800', '#FFFF00', '#5abaff'];
    return colors[num - 1];
  }

  getZonglan() {
    this.http
      .get(`${domain}/hazard/v1/regulatoryscreens/company/overview`, { companyId: this.companyId })
      .subscribe((res: any) => {
        if (res.code == 0) {
          this.entInfo = res.data;
        }
      });
  }

  /**
   * 添加覆盖物 标签
   * @param LngLat
   * @param name
   */
  addOverlayText(lngLat, name) {
    const text = new AMap.Text({
      position: lngLat,
      text: name,
      style: {
        'font-size': '12px',
        background: '#000000bf',
        color: '#fff',
        border: '0',
        'line-height': '1',
        padding: '3px',
      },
      visible: false,
    });
    this.map.add(text);
  }

  addMarker(over, name, data, code) {
    const $that = this;

    const bigDangerNum = data.greatCount;
    const dangerNum = data.generalCount;
    const notClosedGreatPotentialCount = data.notClosedGreatPotentialCount;

    // 未闭环的隐患数量 == 0 , 则不显示
    if (data.notClosedPotentialCount == 0) {
      return;
    }

    const marker = new AMap.Marker({
      size: new AMap.Size(40, 50),
      position: over.getPosition(),
      direction: 'center',
      offset: new AMap.Pixel(-13, -30),
      imageSize: new AMap.Size(40, 50),
      zIndex: 101,
    });

    marker.on('click', e => {
      // $that.map.setZoomAndCenter(16, e.lnglat);

      // 逾期未整改隐患列表
      if (data.yearUnreformCount > 0) {
        this.loading = true;
        this.http
          .get<any>(`${domain}/hazard/v1/regulatoryscreens/overdue/notReCheck`, {
            potentialLocationCode: code,
            page: 1,
            limit: 10000,
            companyId: this.companyId,
          })
          .subscribe(res => {
            this.loading = false;
            if (res.code == 0) {
              this.yqDialog.rows = res.data.content || [];
              if (this.yqDialog.rows.length) {
                this.yqDialog.display = true;
              } else {
                this.msg.info('逾期未整改隐患列表 为空');
              }
            }
          });
      }
    });

    const markerContent = document.createElement('div');
    markerContent.className = 'markerContentClass';

    markerContent.style.position = 'relative';
    markerContent.style.fontWeight = '400';
    markerContent.style.color = '#000';
    markerContent.style.width = '25px';
    markerContent.style.height = '34px';

    const markerImg = document.createElement('img');
    markerImg.className = 'markerlnglat';
    markerImg.src = '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png';

    markerImg.style.width = '25px';
    markerImg.style.height = '34px';
    markerImg.style.position = 'absolute';
    markerImg.style.zIndex = '-1';
    markerImg.style.top = '0';
    markerImg.style.left = '0';

    // 请求重大隐患 、一般隐患 隐患点的数量

    const dangerTotal = bigDangerNum + dangerNum;

    if (notClosedGreatPotentialCount > 0) {
      // 橙色
      markerImg.src = './assets/poi-marker-default.png';
    }
    if (dangerTotal > 0) {
      const markerImgspan = document.createElement('div');
      // markerImgspan.innerHTML = dangerTotal;
      markerImgspan.innerHTML = data.notClosedPotentialCount;
      markerImgspan.className = 'markerImgspan';
      markerImgspan.style.textAlign = 'center';
      markerContent.appendChild(markerImgspan);
      markerContent.appendChild(markerImg);
      // markerSpan.setAttribute("display","block");
    }

    // // 点标记中的文本
    // markerSpan.innerHTML = name;
    // markerContent.appendChild(markerSpan);
    marker.setContent(markerContent); // 更新点标记内容

    $that.map.add(marker);

    // 闪烁
    if (data.yearUnreformCount > 0) {
      marker.on('mouseover', () => (marker._hasOver = true));
      marker.on('mouseout', () => (marker._hasOver = false));

      setInterval(() => {
        if (!marker._hasOver) {
          if (marker._isVisible) {
            marker.hide();
          } else {
            !this.loading && marker.show();
          }
          marker._isVisible = !marker._isVisible;
        }
      }, 500);
    }
  }

  // 打开信息窗体
  openInfoWindow(e, res, name) {
    const info = [];
    info.push(
      "<div class='input-card content-window-card' style='display:block; width: 480px; height:230px; top:" +
        parseInt(e.pixel.y + this.y) +
        'px; left:' +
        parseInt(e.pixel.x + this.x) +
        "px;'><div style=' '><h4 style='color:blue '>" +
        name +
        '总体概况</h4></div> ',
    );
    info.push(
      '<div style="padding:7px 0px 0px 0px; width:33.3%;float:left;"><h5>风险总数：' + res.yearRiskTotalCount + '</h5>',
    );
    info.push("<p class='input-item'>重大风险：" + res.yearGreatRiskCount + '</p>');
    info.push("<p class='input-item'>较大风险：" + res.yearRelativeRiskCount + '</p>');
    info.push("<p class='input-item'>一般风险：" + res.yearGeneralRiskCount + '</p>');
    info.push("<p class='input-item'>低风险：" + res.yearLowRiskCount + '</p></div>');

    info.push(
      '<div style="padding:7px 0px 0px 0px;width:33.3%;float:left;"><h5>风险点总数：' + res.riskPointCount + '</h5>',
    );
    info.push("<p class='input-item'>红色风险点：" + res.redRiskPointCount + '</p>');
    info.push("<p class='input-item'>橙色风险点：" + res.orangeRiskPointCount + '</p>');
    info.push("<p class='input-item'>黄色风险点：" + res.yellowRiskPointCount + '</p>');
    info.push("<p class='input-item'>蓝色风险点：" + res.blueRiskPointCount + '</p></div>');

    info.push(
      '<div style="padding:7px 0px 0px 0px;width:33.3%;float:left;"><h5>本年隐患总数：' +
        res.yearPotentialTotalCount +
        '</h5>',
    );
    info.push("<p class='input-item'>本年重大隐患：" + res.yearGreatCount + '</p>');
    info.push("<p class='input-item'>当前未闭环隐患：" + res.notClosedPotentialCount + '</p>');
    info.push("<p class='input-item'>隐患整改率：" + res.yearReformCountPercent + '%</p>');
    info.push("<p class='input-item'>本月新增隐患：" + res.monthAddCount + '</p></div>');
    this.amapParams.infoWindow = new AMap.InfoWindow({
      isCustom: true, // 使用自定义窗体
      content: info.join(''),
      autoMove: true,
      anchor: 'middle-left',
    });
    this.amapParams.infoWindow.open(this.map, e.lnglat);
  }

  mousePosition(ev) {
    if (ev.pageX || ev.pageY) {
      return { x: ev.pageX, y: ev.pageY };
    }
    return {
      x: ev.clientX + document.body.scrollLeft - document.body.clientLeft,
      y: ev.clientY + document.body.scrollTop - document.body.clientTop,
    };
  }
  loadOverlayCircle(overlayString, id, districtId, color, res, name) {
    const $that = this;
    const over = JSON.parse(overlayString);
    const circle = new AMap.Circle({
      center: over.center,
      radius: over.radius, // 半径
      strokeColor: 'black',
      strokeOpacity: 0,
      strokeWeight: 0,
      strokeStyle: 'dashed',
      strokeDasharray: [10, 10],
      // 线样式还支持 'dashed'
      fillColor: color,
      zIndex: 50,
    });

    circle.on('mouseover', function(e) {
      $that.openInfoWindow(e, res, name);
    });
    circle.on('mouseout', function(e) {
      $that.map.clearInfoWindow();
    });
    circle.setExtData({ id, districtId });
    circle.setMap(this.map);

    this.addOverlayText(circle.getCenter(), name);
  }
  loadOverlay(overlayString, id, districtId, color, name, res, code) {
    const $that = this; // this指针
    const gge = new AMap.GeoJSON();
    const ovobj = JSON.parse(overlayString);
    gge.importData(ovobj);
    const over = gge.getOverlays()[0].getOverlays()[0];
    over.setExtData({ id, districtId });
    if (over.CLASS_NAME == 'AMap.Polygon' || over.CLASS_NAME == 'AMap.Rectangle') {
      over.setOptions({
        fillColor: color,
        bubble: true,
        strokeColor: 'transparent',
        strokeWeight: 0,
      });

      // 鼠标滑过加载信息窗体事件
      over.on('mouseover', function(e) {
        $that.openInfoWindow(e, res, name);
      });
      over.on('mouseout', function(e) {
        // mouseoutTime = new Date();
        $that.map.clearInfoWindow();
      });

      this.map.add(over);

      this.addOverlayText(over.getBounds().getCenter(), name);
    } else if (over.CLASS_NAME == 'AMap.Marker') {
      $that.addMarker(over, name, res, code);
    }
  }

  // 切换楼层

  changeFloor() {
    this.init(this.floorValue);
    this.oldAreaCode = new Set();
  }

  getFloor() {
    this.http.get(`${domain}/basics/v1/companys/floor`, { companyId: this.companyId }).subscribe((res: any) => {
      if (res.code == 0) {
        this.floorList = res.data || this.floorList;
      } else {
        this.msg.error(res.msg);
      }
    });
  }

  // 下载 4色图
  download() {
    this.loading = true;

    try {
      // 隐藏 标注
      this.map.getAllOverlays('marker').forEach(marker => {
        marker.hide();
      });
      // //显示 区域名称
      // this.map.getAllOverlays('text').forEach(marker => {
      //   marker.show();
      // });

      const imageLayerElement = (window as any).currentImageLayer.getElement();
      const imageLayerRectData = imageLayerElement.getBoundingClientRect();
      const containerElement = document.getElementById('container');
      const containerRectData = containerElement.getBoundingClientRect();

      (window as any).domtoimage.toPng(containerElement, { cacheBust: true }).then(dataUrl => {
        // //隐藏 区域名称
        // this.map.getAllOverlays('text').forEach(marker => {
        //   marker.hide();
        // });
        // 显示 标注
        this.map.getAllOverlays('marker').forEach(marker => {
          marker.show();
        });

        const containerImg = new Image();
        containerImg.src = dataUrl;
        containerImg.onload = () => {
          // 地图容器 画布
          const containerCanvas = document.createElement('canvas');
          containerCanvas.width = containerRectData.width;
          containerCanvas.height = containerRectData.height;
          const containerCtx = containerCanvas.getContext('2d');
          containerCtx.drawImage(containerImg, 0, 0);
          // document.body.appendChild(containerCanvas);

          // 剪裁出 图片图层 数据
          const x = imageLayerRectData.left - containerRectData.left;
          const y = imageLayerRectData.top - containerRectData.top;
          const imageLayerImgData = containerCtx.getImageData(
            x,
            y,
            imageLayerRectData.width,
            imageLayerRectData.height,
          );

          // 图片图层 画布
          const imageLayerCanvas = document.createElement('canvas');
          imageLayerCanvas.width = imageLayerRectData.width;
          imageLayerCanvas.height = imageLayerRectData.height;
          const imageLayerCtx = imageLayerCanvas.getContext('2d');
          imageLayerCtx.putImageData(imageLayerImgData, 0, 0);
          // document.body.appendChild(imageLayerCanvas);

          // 下载
          const link = document.createElement('a');
          link.setAttribute('href', imageLayerCanvas.toDataURL());
          link.setAttribute(
            'download',
            `${(window as any).currentImageLayer.districtName} ${moment().format('YYYYMMDDHHmmssSSS')}.png`,
          );
          link.click();

          setTimeout(() => {
            this.loading = false;
          }, 0);
        };
        // document.body.appendChild(containerImg);
      });
    } catch (error) {
      this.loading = false;
      this.msg.error('导出发生异常');
    }
  }
}
