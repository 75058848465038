import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { HttpClient } from '@angular/common/http'
import { _HttpClient } from '@delon/theme';
import { baseUrl as _baseUrl } from '@env/environment';
import { CommonService, baseUrl } from '@core';

@Component({
  selector: 'app-standard-paper',
  templateUrl: './standard-paper.component.html',
  styleUrls: ['./standard-paper.component.less']
})

export class StandardPaperComponent implements OnInit {
  baseUrl = baseUrl;
  checkedIds: any = [];
  checkedCompanyList = [];
  industryList = [];
  preViewList = [];
  industryData;

  downloadUrl = '';
  templateUrl = "https://prdxaloss.oss-cn-beijing.aliyuncs.com/public/template/%E5%AE%89%E5%85%A8%E6%A0%87%E5%87%86%E5%8C%96%E8%AF%95%E5%8D%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx";
  loading = false;
  @ViewChild('XTable', { static: true }) XTable = null;
  @ViewChild('btnTemp', { static: true }) btnTemp = null;
  constructor(private modalService: NzModalService,
    private fb: FormBuilder,
    private message: NzMessageService,
    private http: HttpClient,
    public commonService: CommonService,
  ) { }
  editDialog = {
    display: false,
    loading: false,
    data: {} as any,
    form: null as FormGroup,
    // 是否查看
    isView: false,
  };
  preViews = {
    display: false,
    loading: false,
  };

  fileList = [];
  showUploadList = {
    showPreviewIcon: true,
    showRemoveIcon: true,
    hidePreviewIconInNonImage: true,
  };
  filePath;

  /**
   * table
   */
  table = {
    searchParams: {} as any,
    loading: false,
    columns: [[
      { title: '序号', field: (row, column, rowIterator) => rowIterator.index + 1 },
      { title: '试卷名称', field: 'paperName' },
      { title: '创建者', field: 'addUserName' },
      { title: '最后编辑时间', field: 'addTime' },
      { title: '适用行业', field: 'industryName' },
      {
        title: '状态',
        field: (row) => `<span style="color: ${row.status == '1' ? 'green' : 'red'};">${row.status == '1' ? '启用' : '停用'}</span>`
      },
      { title: '操作', field: null, fixed: 'right' },
    ]],
    sendCallback: (params) => {
      params = {
        ...params,
        ...this.table.searchParams
      };
      return params;
    }
  };

  ngOnInit() {
    this.table.columns[0][this.table.columns[0].length - 1].field = this.btnTemp;
    this.table.columns = [...this.table.columns];
    this.initEditDialogForm()
    this.getIndusryset();
  }
  initEditDialogForm() {
    this.editDialog.form = this.fb.group({
      paperName: [null],
      industryName: [null],
      industryId: [null],
    });
  }

  //获取行业列表数据
  getIndusryset() {
    this.http.get<any>(`${baseUrl}/bzh/industry`,).subscribe(res => {
      if (res.code == 0) {
        console.log(res, 'cccccccc')
        this.industryList = res.data;
      }
    });
  }



  change() {
    this.checkedIds = this.XTable.checkedRows.map(v => v.companyId);
    this.checkedCompanyList = [];
    this.XTable.checkedRows.forEach(row => {
      const company = { id: row.companyId, operateCenterCode: row.operationCenterCode };
      this.checkedCompanyList.push(company);
    });
  }

  /**
     * 删除
     */
  onBatchDelete(status) {
    if (!this.XTable.checkedRows.length) {
      this.message.error('请勾选要删除的试卷!');
      return;
    }

    const id = this.XTable.checkedRows.map(v =>
      v.id,
    );
    this.http.put<any>(`${baseUrl}/bzh/paper?ids=${id.join(',')}&status=${status}`, {}).subscribe(res => {
      if (res.code == 0) {
        this.message.success('操作成功!');
        this.XTable.reload();
      }
    });
  }

  /**
     * 停用 / 启用
     */
  onStatusChange(status, data) {
    if (!this.XTable.checkedRows.length) {
      this.message.error('请勾选要操作的试卷!');
      return;
    }

    const id = this.XTable.checkedRows.map(v =>
      v.id,
    );

    this.http.put<any>(`${baseUrl}/bzh/paper?ids=${id.join(',')}&status=${status}`, {}).subscribe(res => {
      if (res.code == 0) {
        this.message.success('操作成功!');
        this.XTable.reload();
      }
    });
  }
  /**
   * 打开 编辑 对话框
   */
  onOpenEditDialog(data = null, isView = false) {
    this.editDialog.display = true;
    this.editDialog.data = data;
    this.editDialog.isView = isView;
    this.editDialog.form.reset();
    this.editDialog.form.enable();
    if (isView) {
      this.editDialog.form.disable();
    }
    if (data) {
      let _data = {
        ...data,
      };
      this.editDialog.form.patchValue(_data);
    }
  }

  //文件上传
  beforeUpload = (file): boolean => {
    const OSS = this.commonService.OSS;
    const path = this.commonService.path + this.commonService.getFileType(file);
    this.filePath = this.commonService.getFilePath(path, file);
    OSS.put(path, file)
      .then(res => {
        this.fileList = [
          ...this.fileList,
          {
            uid: this.fileList.length,
            status: 'done',
            name: file.name,
            url: this.commonService.OssSignatureUrl(path),
            filePath: this.commonService.getFilePath(path, file),
          },
        ];
      })
      .catch(() => {
        this.message.error('文件上传不成功!');
      });
    return false;
  };
  /**
   * 对话框提交
   */
  onSubmitEditDialog() {
    this.commonService.formValid(this.editDialog.form);
    if (!this.editDialog.form.valid) {
      return;
    };
    if (!this.editDialog.form.value.paperName) {
      this.message.info('请输入试卷名称!');
      return;
    }
    let data = {
      ...this.editDialog.form.value,
    };
    if (this.editDialog.data) {
      data = {
        ...this.editDialog.form.value,
        id: this.editDialog.data.id
      }
    }

    if (this.filePath) {
      data.filePath = this.filePath;
    }
    delete data.industryName;
    if (this.editDialog.data) {
      this.editDialog.loading = true;
      this.http.get<any>(`${baseUrl}/bzh/paper/import?filePath=${data.filePath}&industryId=${data.industryId}&paperName=${data.paperName}&id=${data.id}`).subscribe(res => {
        this.editDialog.loading = false;
        if (res.code == 0) {
          this.editDialog.display = false;
          this.message.success('操作成功');
          this.fileList = [];
          this.XTable.reload();
        }
      });
    } else {
      this.editDialog.loading = true;
      this.http.get<any>(`${baseUrl}/bzh/paper/import?filePath=${data.filePath}&industryId=${data.industryId}&paperName=${data.paperName}`).subscribe(res => {
        this.editDialog.loading = false;
        console.log(res, 'reererere')
        if (res.code == 0) {
          this.editDialog.display = false;
          this.message.success('操作成功');
          this.fileList = [];
          this.XTable.reload();
        }
      });
    }
  }

  /**
   * 下载
   */
  download(data) {
    this.downloadUrl = '';
    let path = this.commonService.OssSignatureUrl(data.filePath);
    if (data.filePath) {
      this.downloadUrl = path;
      this.message.success('操作成功');
    } else {
      this.downloadUrl = null;
      this.message.error('文件错误');
    }
  }

  /**
   * 预览
   */
  preView(data) {
    this.preViews.display = true;
    this.preViews.loading = true;
    this.preViewList = data.paperInfo.firstElements;
    console.log(this.preViewList)
  }

  /**
   * 关闭添加窗口
   */
  handleCancel() {
    this.editDialog.display = false;
    this.preViews.display = false;
    this.fileList = [];
  }
  handleOk() {
    this.preViews.display = false;
  }
}
