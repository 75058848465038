import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'th[x-th]',
  templateUrl: './x-th.component.html',
  styleUrls: ['./x-th.component.scss']
})
export class XThComponent implements OnInit {
  @HostBinding('style.width') _width: string;
  @HostBinding('style.min-width') _minWidth: string;
  @HostBinding('style.white-space') _whiteSpace: string;
  @HostBinding('attr.data-width') _dataWidth: string;

  @Input() set xWidth(xWidth) {
    const width = isNaN(xWidth) ? xWidth : `${xWidth}px`;
    this._width = width;
    this._minWidth = width;
    this._dataWidth = width;
    // 如果设置了宽度, 则强制换行
    if(xWidth != null) {
      this._whiteSpace = 'normal';
    }
  }

  constructor() { }

  ngOnInit() {
  }

}
