// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  useHash: true,
  hmr: false,
  crmUrl: 'http://localhost:4200',
  YHbaseUrl: 'http://esc.ahjtest.top',
  BZbaseUrl: 'http://aq.ahjtest.top',
  goToUrl: 'https://wwww.test01.xinanyun.cn',
};

// export const baseUrl = `http://ecology.asuscomm.com:9998`;
// export const baseUrl = `http://192.168.50.3:9999`;
export const baseUrl = `./api`;

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
