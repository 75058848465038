import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { NzMessageService, NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'xls-upload',
  templateUrl: './xls-upload.component.html',
  styleUrls: ['./xls-upload.component.less']
})
export class XlsUploadComponent implements OnInit {
  //导入地址
  @Input() url = '';
  //上传参数
  @Input() uploadParams: any = {};
  //模板下载地址
  @Input() templateUrl = '';
  //导入成功的事件
  @Output() uploadSuccess = new EventEmitter<any>();
  //权限代码
  @Input() aclcode = true;
  loading = false;

  constructor(private message: NzMessageService, private notification: NzNotificationService) { }

  ngOnInit() {
  }

  beforeUpload = (file: File) => {
    if (file.name.slice(-4) == '.xls' || file.name.slice(-5) == '.xlsx') {
      this.loading = true;
      return true;
    }
    this.message.error('请选择Excel文件!');
    return false;
  };

  /**
   * 文件上传状态改变
   * @param UploadChangeParam 
   */
  fileChange(event) {
    console.log(event);
    if (event.file.status == 'done') {
      if (event.file.response.code == 0) {
        this.message.success(event.file.response.msg || '导入成功');
        this.uploadSuccess.emit();
        this.loading = false;
      } else {
        // event.file.response.data = ['错误消息1', '错误消息2'];
        let title = event.file.response.msg || '导入错误';
        let errorList = event.file.response.data || [];
        this.notification.error(title, errorList.join('<br />'), { nzDuration: 5000 });
        this.loading = false;
      }
      return;
    }
    if (event.file.status == 'error') {
      this.message.error('文件上传失败!');
      this.loading = false;
      return;
    }
  }

}
