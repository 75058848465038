import { Component, OnInit,  Input } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme'
import { CommonService, baseUrl } from '@core';
import { HttpParams } from '@angular/common/http';
import { CodeManageService,saveData} from '@core/codemanage.service';

@Component({
  selector: 'app-codeadd',
  templateUrl: './codeadd.component.html',
  styleUrls: ['./codeadd.component.less']
})
export class CodeaddComponent implements OnInit {

  @Input() idata: any = null;
  constructor(private ref: NzModalRef,
    public msg: NzMessageService,
    public http: _HttpClient,
    public commonService: CommonService,
    private codeManageService: CodeManageService
   ) {
  }

  ngOnInit() {
    this.idata = { ...this.idata };
  }
  /**
   * 保存
   */
  save() {
    if (this.idata.receiverPhone && !this.commonService.isPhone(this.idata.receiverPhone)) {
      this.msg.error('请正确输入手机号');
      return;
    }
    const datas = new HttpParams({
      fromObject: this.idata
    });
    this.http.put<saveData>(`${baseUrl}/activation-codes`,datas ).subscribe(res => {
      if (res.code == 0) {
        this.msg.success('操作成功!');
        this.close(res);
      } else {
        this.msg.error(res.msg || '操作失败');
      }
    });
  }

  close(res = null) {
    this.ref.destroy(res);
  }

}
