import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService, NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { HttpClient } from '@angular/common/http'

import { _HttpClient, ModalHelper } from '@delon/theme';
import { baseUrl as _baseUrl } from '@env/environment';
import { CommonService, baseUrl } from '@core';

@Component({
  selector: 'app-industryset',
  templateUrl: './industryset.component.html',
  styleUrls: ['./industryset.component.less']
})
export class IndustrysetComponent implements OnInit {
  baseUrl = baseUrl;
  constructor(private fb: FormBuilder, private message: NzMessageService, private http: HttpClient,
    public commonService: CommonService,) { }

  editDialog = {
    display: false,
    loading: false,
    data: {} as any,
    form: null as FormGroup,
    // 是否查看
    isView: false,
  };
  checkedIds: any = [];
  checkedCompanyList = [];

  industryList = [];

  ngOnInit() {
    this.initEditDialogForm()
    this.getIndusryset()
  }

  //获取行业列表数据
  getIndusryset() {
    this.http.get<any>(`${baseUrl}/bzh/industry`,).subscribe(res => {
      if (res.code == 0) {
        this.industryList = res.data;
      }
    });
  }

  // 提交表单数据
  onSubmitEditDialog() {
    let _data = {
      ...this.editDialog.form.value,
    };
    if (this.editDialog.data) {
      _data = {
        ...this.editDialog.form.value,
        id: this.editDialog.data.id
      }
    }
    if (!_data.industryName) {
      this.message.info('请输入行业名称!');
      return;
    }

    this.editDialog.loading = true;
    this.http.post<any>(`${baseUrl}/bzh/industry`, _data).subscribe(res => {
      this.editDialog.loading = false;
      if (res.code == 0) {
        this.editDialog.display = false;
        this.message.success('操作成功');
        this.getIndusryset()
      }
    });
  }
  // 初始化 表单
  initEditDialogForm() {
    this.editDialog.form = this.fb.group({
      description: [null],
      industryName: [null],
    });
  }
  // 打开 添加、编辑 窗口
  onOpenEditDialog(data = null, isView = false) {
    this.editDialog.display = true;
    this.editDialog.data = data;
    this.editDialog.isView = isView;
    this.editDialog.form.reset();
    this.editDialog.form.enable();
    if (data) {
      let _data = {
        ...data,
      };
      this.editDialog.form.patchValue(_data);
    }
  }
  /**
     * 删除
     */
  onDelete(data) {
    this.http.delete<any>(`${baseUrl}/bzh/industry/${data.id}`).subscribe(res => {
      if (res.code == 0) {
        this.message.success('操作成功');
        this.getIndusryset();
      }
    });
  }
}


